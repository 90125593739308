<template>
  <base-error :show="!!errorShrtUpd" title="An error occurred!" @close="handleErrorShrtUpd">
    <p>{{ errorShrtUpd }}</p> </base-error
  ><base-error :show="!!error" title="An error occurred!" @close="handleError">
    <p>{{ error }}</p>
  </base-error>
  <!-- <base-dialog :show="!!error" title="An error occurred!" @close="handleError">
    <p>{{ error }}</p>
  </base-dialog> -->
  <div id="jrnl-list" class="jrnl-list">
    <!-- <div class="tstHlight">
      <button @click="scroll">Scroll</button>
    </div> -->
    <div v-if="isUlDataLoading" class="spinner">
      <base-spinner></base-spinner>
    </div>

    <div class="list" v-else-if="journalList && journalList.length">
      <jrnl-card v-for="(journal, index) in journalList" :key="journal.jnlId" :idx="index" :id="'jnl_' + journal.jnlId" :ref="'jnl_' + journal.jnlId" class="jnrlCntr" :jnl-id="journal.jnlId" :jnl-key="journal.jnlId" :jnl-no="journal.jnlNo" :jnl-excl="journal.jnlExcl" :jnl-date="journal.jnlDate" :jnl-legs="journal.legs" :jnl-being="journal.jnlBeing" :jnl-notes="journal.jnlDescr"></jrnl-card>
    </div>
    <h3 v-else>No journals found.</h3>
  </div>
</template>

<script>
import JrnlCard from "@/components/2_Journals/JrnlCard.vue";
export default {
  components: {
    JrnlCard,
  },
  data() {
    return {
      el: "#app",
    };
  },
  computed: {
    journalList() {
      console.log("journalList", this.$store.getters.getCurrLedJrnlList);
      return this.$store.getters.getCurrLedJrnlList;
    },
    isUlDataLoading() {
      return this.$store.getters.getUlDataLoading;
    },

    error() {
      return this.$store.getters.getError;
    },
    hasJournals() {
      return this.$store.getters.hasJournals;
    },
    errorShrtUpd() {
      return this.$store.getters.getErrorShrtUpd;
    },
  },
  methods: {
    handleError() {
      this.$store.commit("setError", null);
    },
    handleErrorShrtUpd() {
      this.$store.commit("setErrorShrtUpd", null);
    },
    scroll() {
      let jrnl = document.querySelector("#jnl_60");
      console.log("jrnl selector", jrnl);
      console.dir("jrnl selector dir", jrnl);
      // jrnl.scrollTop = jrnl.scrollHeight;
      jrnl.scrollIntoView();
    },
  },
};
</script>

<style scoped>
.jrnl-list {
  background-color: rgb(235, 241, 247);
  /* overflow: scroll; */
  /* height: 100rem; */
  width: auto;
  /* margin-left: 2rem; */
  margin-top: 8rem;
  padding-top: 2rem;
  padding-bottom: 10rem;
  height: calc(100vh - 22rem);
  overflow: scroll;
  /* overflow-x: hidden; */
}

.list {
  padding-bottom: 15rem;
}

.hlighted {
  background-color: lightyellow;
}

.active {
  background-color: lightyellow;
}
/* .spinner {
  margin-top: 10rem;
} */
</style>
