<template>
  <section>
    <h1>This is the Pricing / Sign-up page</h1>
    <ul>
      <li>12 Ledgers</li>
      <li>50 Journals Per Ledger</li>
      <li>Download Ledger to PDF</li>
      <li>USD 39.99 per year</li>
    </ul>
    <button @click="showSignForm">Select</button>
  </section>
  <signup-form v-if="showSignupForm" :show="showSignupForm" @close="showSignForm" @showEmailSent="shwEmailSent"></signup-form>
  <base-dialog title="An email has been sent!" :backdropTwo="false" :show="showEmailSentDialog" @close="confirmClose">
    <template #default>
      <p>An email has been sent.</p>
      <p>Please remember to check your Spam folder if necessary.</p>
      <p>Please follow the link contained within the email to complete signup.</p>
    </template>
    <template v-slot:actions>
      <base-button @click="confirmClose">Okay</base-button>
    </template>
  </base-dialog>
</template>
<script>
import SignupForm from "../components/0_Auth/SignupForm.vue";
export default {
  components: { SignupForm },
  data() {
    return {
      showSignupForm: false,
      showEmailSentDialog: false,
    };
  },
  methods: {
    showSignForm() {
      this.showSignupForm = !this.showSignupForm;
    },
    shwEmailSent() {
      this.showEmailSentDialog = true;
    },
    confirmClose() {
      this.showEmailSentDialog = false;
    },
  },
};
</script>

<style scoped>
section {
  margin-left: 5rem;
  margin-top: 5rem;
  font-size: 1.6rem;
}

button {
  margin-bottom: 3rem;
  font-size: 2rem;
}
</style>
