<template>
  <base-error :show="!!error" title="An error occurred!" @close="handleError">
    <p>{{ error }}</p>
  </base-error>
  <teleport to="body">
    <div @click="tryClose" class="backdrop"></div>
    <!-- <transition name="dialog" appear> -->
    <dialog open v-if="show" class="dialog">
      <header>
        <h2 v-if="mode == 'edit'">Edit Ledger - {{ ledName }}</h2>
        <h2 v-if="mode == 'new'">New Ledger</h2>
      </header>
      <div v-if="isProcessing" class="spinner">
        <base-spinner></base-spinner>
      </div>

      <form v-else-if="!isProcessing" @submit.prevent="onSave">
        <div class="section">
          <div class="sectionName">Basics:</div>

          <div class="form-control" :class="{ invalid: !frmData.ledName.isValid }">
            <label for="ledName">Name:</label>
            <input type="text" id="frm_led_name" name="ledName" v-model.trim="frmData.ledName.val" @blur="clearValidity('ledName')" maxlength="50" />
            <p class="fldErr" v-if="!frmData.ledName.isValid && frmData.ledName.invalReason == 1">Name must not be empty.</p>
            <p class="fldErr" v-if="!frmData.ledName.isValid && frmData.ledName.invalReason == 2">A Ledger with this name already exists.</p>
          </div>

          <div class="form-control" :class="{ invalid: !frmData.ledDescr.isValid }">
            <label for="ledDescr">Description:</label>
            <input type="text" id="frm_led_descr" name="ledDescr" v-model.trim="frmData.ledDescr.val" @blur="clearValidity('ledDescr')" />
            <p class="fldErr" v-if="!frmData.ledDescr.isValid">Description must not be empty.</p>
          </div>
        </div>
        <div class="section">
          <div class="sectionName">Currency:</div>
          <div class="form-control" :class="{ invalid: !frmData.baseCurr.isValid }">
            <label for="baseCurr">Code:</label>
            <input type="text" id="frm_led_baseCurr" name="baseCurr" v-model.trim="frmData.baseCurr.val" @blur="clearValidity('baseCurr')" />
            <p class="fldErr" v-if="!frmData.baseCurr.isValid">Currency code must not be empty.</p>
          </div>
          <div class="form-control" :class="{ invalid: !frmData.baseCurrDesc.isValid }">
            <label for="baseCurrDesc">Description:</label>
            <input type="text" id="frm_led_baseCurrDesc" name="baseCurrDesc" v-model.trim="frmData.baseCurrDesc.val" @blur="clearValidity('baseCurrDesc')" />
            <p class="fldErr" v-if="!frmData.baseCurrDesc.isValid">Currency description must not be empty.</p>
          </div>
          <div class="form-control">
            <label for="ledCurrNoDec">Decimal Places:</label>

            <select :disabled="mode == 'edit' && jrnls" name="ledCurrNoDec" id="frm_led_ledCurrNoDec" v-model="frmData.baseCurrNoDec.val">
              <option value="0">0</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </div>
        </div>
        <div class="section">
          <div class="sectionName">Formats:</div>
          <div class="form-control">
            <label for="frmtAmts">Amounts:</label>
            <select name="frmtAmts" id="frm_led_Amts" v-model.number="frmData.frmtAmts.val">
              <option value="0">9,999.00</option>
              <option value="1">9.999,00</option>
            </select>
          </div>
          <div class="form-control">
            <label for="frmtShrtDts">Short Dates:</label>

            <select name="frmtShrtDts" id="frm_led_ShrtDts" v-model.number="frmData.frmtShrtDts.val">
              <option value="0">DD-MM-YY</option>
              <option value="1">MM-DD-YY</option>
            </select>
          </div>
        </div>
        <div class="section" v-if="mode == 'new'">
          <div class="sectionName">Copy Categories / Accounts</div>
          <div class="selCntr">
            <label class="selLbl" for="ledgers">Copy: </label>
            <select id="frm_led_cpyOpt" class="cpyLed" v-model.number="frmData.cpyLedMode.val">
              <option class="selOpt" value="0">Create basic Categories / Accounts</option>
              <option class="selOpt" value="1">From existing Ledger</option>
              <!-- <option class="selOpt" value="1">Categories only</option> -->
              <!-- <option class="selOpt" value="2">Categories and Accounts</option> -->
            </select>
          </div>
          <div class="selCntr">
            <label class="selLbl" for="ledgers">From Ledger: </label>
            <select id="frm_led_ledgers" class="cpyLed" @input="selectedLedgerOutput($event)" :value="frmData.cpyLedId.val" :disabled="!frmData.cpyLedMode.val">
              <option class="selOpt" v-for="ledger in ledgerList" :key="ledger.ledId" :value="ledger.ledId" :descr1="ledger.description">{{ ledger.ledName }}</option>
            </select>
          </div>
        </div>
        <div class="section">
          <div class="sectionName">Other:</div>
          <div class="form-control" :class="{ invalid: !frmData.tbTitle.isValid }">
            <label for="tbTitle">Trial Balance Title:</label>
            <input type="text" id="frm_led_tbTitle" name="tbTitle" v-model.trim="frmData.tbTitle.val" @blur="clearValidity('tbTitle')" />
            <p class="fldErr" v-if="!frmData.tbTitle.isValid">Trial Balance Title must not be empty.</p>
          </div>
          <div class="form-control" :class="{ invalid: !frmData.bsTitle.isValid }">
            <label for="bsTitle">Bal Sheet Title:</label>
            <input type="text" id="frm_led_bsTitle" name="bsTitle" v-model.trim="frmData.bsTitle.val" @blur="clearValidity('bsTitle')" />
            <p class="fldErr" v-if="!frmData.bsTitle.isValid">Bal Sheet Title must not be empty.</p>
          </div>
          <div class="form-control" :class="{ invalid: !frmData.plTitle.isValid }">
            <label for="bsTitle">P&amp;L Title:</label>
            <input type="text" id="frm_led_plTitle" name="plTitle" v-model.trim="frmData.plTitle.val" @blur="clearValidity('plTitle')" />
            <p class="fldErr" v-if="!frmData.plTitle.isValid">P&amp;L Title must not be empty.</p>
          </div>
          <div class="form-control" :class="{ invalid: !frmData.ledNotes.isValid }">
            <label for="ledNotes">Notes:</label>
            <textarea id="frm_led_notes" name="ledNotes" rows="10" v-model="frmData.ledNotes.val" @blur="clearValidity('ledNotes')" />
          </div>
        </div>
        <p v-if="!formIsValid">Please fix the above errors and submit again.</p>
        <menu>
          <base-button @click="testA">Test</base-button>
          <base-button v-if="mode == 'edit'" type="submit">Save Changes</base-button>
          <base-button id="frm_led_btn_create" v-if="mode == 'new'" type="submit">Create Ledger</base-button>
          <base-button type="button" @click="tryClose">Cancel</base-button>
        </menu>
      </form>
    </dialog>
    <!-- </transition> -->
  </teleport>
</template>
<script>
import mixin from "@/mixins/mixin.js";
export default {
  mixins: [mixin],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    ledData: {
      type: Object,
      default: () => ({ empty: true }),
    },

    title: {
      type: String,
      required: false,
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["close"],
  data() {
    return {
      formIsValid: true,
      frmData: {
        ledName: { val: "", isValid: true, invalReason: null },
        ledDescr: { val: "", isValid: true },
        baseCurr: { val: "", isValid: true },
        baseCurrDesc: { val: "", isValid: true },
        baseCurrNoDec: { val: 2, isValid: true },
        frmtAmts: { val: 0, isValid: true },
        frmtShrtDts: { val: 0, isValid: true },
        cpyLedId: { val: 0, isValid: true },
        cpyLedMode: { val: 0, isValid: true },
        ledNotes: { val: "", isValid: true },
        tbTitle: { val: "Trial Balance", isValid: true },
        bsTitle: { val: "Balance Sheet as at", isValid: true },
        plTitle: { val: "Profit and Loss for Year Ending", isValid: true },
      },
      isProcessing: false,
      error: null,
    };
  },
  computed: {
    userId() {
      return this.$store.getters.getAuthUserId;
    },
    ledId() {
      return this.$store.getters.getCurrLedgerId;
    },
    ledgerList() {
      return this.$store.getters.getLedgers;
    },
    nextLedNumber() {
      return 8;
    },
    selectedLedger() {
      // return this.$store.getters.getCurrLedgerId || this.$store.getters.getAuthCurrLedId;
      // return this.$store.getters.getAuthCurrLedId;
      return this.ledId;
    },
    ledNo() {
      return this.$store.getters.getCurrLedgerNo;
    },

    ledName() {
      console.log("ledger name", this.$store.getters.getCurrLedgerName);
      return this.$store.getters.getCurrLedgerName;
    },
    ledgers() {
      return this.$store.getters.getLedgers;
    },
    cpyLed() {
      if (this.frmData.cpyLedMode.val == 0) return 0;
      return this.frmData.cpyLedId.val;
    },
    jrnls() {
      return this.$store.getters.getJournals;
    },

    //   frmData()
  },
  methods: {
    tryClose() {
      //   if (this.fixed) {
      //     return;
      //   }
      // console.log("ledData", this.ledData1);
      // console.log("frmData", this.frmData1);
      // this.$emit("close");
      this.$store.commit("setIsEditingLedger", false);
      this.$store.commit("setIsInsertingLedger", false);
    },
    clearValidity(input) {
      console.log("clearValidity", input);
      this.frmData[input].isValid = true;
    },
    validateForm() {
      this.formIsValid = true;

      if (this.frmData.ledName.val === "") {
        this.frmData.ledName.isValid = false;
        this.frmData.ledName.invalReason = 1;
        this.formIsValid = false;
      }
      // console.log("chk", this.chkLedNames(this.ledNo, this.frmData.ledName.val));
      // Do the other Ledgers have the same ledName?
      if (!this.chkLedNames(this.ledNo, this.frmData.ledName.val.trim())) {
        this.frmData.ledName.isValid = false;
        this.frmData.ledName.invalReason = 2;
        this.formIsValid = false;
      }

      if (this.frmData.ledDescr.val === "") {
        this.frmData.ledDescr.isValid = false;
        this.formIsValid = false;
      }
      if (this.frmData.bsTitle.val === "") {
        this.frmData.bsTitle.isValid = false;
        this.formIsValid = false;
      }
      if (this.frmData.plTitle.val === "") {
        this.frmData.plTitle.isValid = false;
        this.formIsValid = false;
      }
      if (this.frmData.tbTitle.val === "") {
        this.frmData.tbTitle.isValid = false;
        this.formIsValid = false;
      }
      if (this.frmData.baseCurr.val === "") {
        this.frmData.baseCurr.isValid = false;
        this.formIsValid = false;
      }
      if (this.frmData.baseCurrDesc.val === "") {
        this.frmData.baseCurrDesc.isValid = false;
        this.formIsValid = false;
      }
    },
    chkLedNames(ledNo, ledName) {
      const ledgers = this.ledgers;
      // console.log("ledNo, ledName", ledNo, ledName);
      // console.log("ledgers", ledgers);
      for (let l = 0; l < ledgers.length; l++) {
        // console.log(ledgers[l]);
        if (ledgers[l].ledNo != ledNo) {
          // console.log("led", ledgers[l].ledNo, ledNo);
          // console.log("comp", ledgers[l].ledName, ledName);
          if (ledgers[l].ledName.trim() == ledName) {
            return false;
          }
        }
      }
      return true;
    },
    onSave() {
      this.validateForm();
      if (!this.formIsValid) {
        return;
      }
      const saveData = {
        userId: this.userId,
        id: this.ledId,
        number: this.nextLedNumber,
        name: this.stripText(this.frmData.ledName.val),
        description: this.stripText(this.frmData.ledDescr.val),
        base_curr: this.frmData.baseCurr.val,
        base_curr_desc: this.stripText(this.frmData.baseCurrDesc.val),
        base_curr_no_dec: this.frmData.baseCurrNoDec.val,
        frmtAmts: this.frmData.frmtAmts.val,
        frmtShrtDts: this.frmData.frmtShrtDts.val,
        // cpyLedId: this.frmData.cpyLedId.val,
        cpyLedId: this.cpyLed,
        cpyLedMode: this.frmData.cpyLedMode.val,
        notes: this.stripText(this.frmData.ledNotes.val),
        tbTitle: this.stripText(this.frmData.tbTitle.val),
        bsTitle: this.stripText(this.frmData.bsTitle.val),
        plTitle: this.stripText(this.frmData.plTitle.val),
      };

      if (this.mode == "edit") {
        console.log("saved....edit", saveData);
        this.isProcessing = true;
        this.$store
          .dispatch("updateLedger", saveData)
          .then(() => {
            this.isProcessing = false;
            this.$store.commit("setIsEditingLedger", false);
            this.tryClose();
          })

          .catch((error) => {
            console.log("error caught" + error);
            this.isProcessing = false;
            this.error = error;
            console.log("data: error: ", this.error);
          });
      }

      if (this.mode == "new") {
        this.isProcessing = true;
        console.log("saved....new", saveData);
        console.log("isProcessing", this.isProcessing);
        this.$store
          .dispatch("insertLedger", saveData)
          .then(() => {
            this.isProcessing = false;
            this.$store.commit("setIsInsertingLedger", false);
            this.tryClose();
          })

          .catch((error) => {
            console.log("error caught" + error);
            this.isProcessing = false;
            this.error = error;
            console.log("data: error: ", this.error);
          });
      }
    },
    selectedLedgerOutput(event) {
      let selIdx = event.target.options[event.target.options.selectedIndex];
      // this.$store.commit("setAuthCurrLedId", event.target.options[event.target.options.selectedIndex].value);
      console.log("selIdx", selIdx);
      this.frmData.cpyLedId.val = parseInt(event.target.value);
      console.log("copy fields", this.frmData.cpyLedId.val, this.frmData.cpyLedMode.val);
    },
    testA() {
      // console.log("ledData", this.ledData);
      // console.log("...ledData", { ...this.ledData });
      // console.log("frmData", this.frmData);
      console.log("mode:", this.mode);
    },
    handleError() {
      this.error = null;
    },
  },
  mounted() {
    // If editing, fill the form from the prop ledData:
    if (!this.ledData.empty) {
      this.frmData = this.ledData;
      // this.frmData = JSON.parse(JSON.stringify(this.ledData));
    }

    // If inserting, set copy ledger ID dropdown:
    if (this.ledData.empty) {
      this.frmData.cpyLedId.val = this.ledId || this.ledgerList[0].ledId;
    }
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 10;
}

dialog {
  /* width: 80%;
  height: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -150px;
  margin-top: -150px; */
  position: fixed;
  top: 10vh;
  left: 20vh;
  margin: 0 auto;
  width: 60%;
  height: 80%;
  z-index: 90;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: auto;
  background-color: white;
  font-family: "Roboto Serif", serif;
}

/* @keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
} */
header {
  background-color: #3a0061;
  color: white;
  width: 100%;
  padding: 1rem;
  position: sticky;
  top: 0;
}

header h2 {
  margin: 0;
}

.section {
  margin-left: 5rem;
  margin-top: 2rem;
  font-size: 2rem;
}

menu {
  padding: 1rem;
  display: flex;
  /* justify-content: flex-end; */
  margin: 1;
  font-size: 2rem;
}

@media (min-width: 768px) {
  dialog {
    /* left: calc(50% - 20rem); */
    left: 2rem;
    width: calc(100vw - 5rem);
    height: 90vh;
  }
}

.form-control {
  margin: 0.5rem 0;
  display: flex;
  align-items: flex-start;
}

label {
  font-weight: bold;
  font-size: 2rem;
  display: inline-block;
  text-align: right;
  margin-bottom: 1rem;
  width: 20rem;
}

select {
  margin-left: 1rem;
  font-size: 2rem;
  width: 15rem;
  height: 3rem;
}

.cpyLed {
  width: 40rem;
}
input[type="checkbox"] + label {
  font-weight: normal;
  display: inline-block;
  margin: 0 0 0 0.5rem;
}

input,
textarea {
  display: inline;
  font-size: 2rem;
  width: 40rem;
  /* width: 100%; */
  margin-left: 1rem;
  border: 1px solid #ccc;
  /* font: inherit; */
}

input:focus,
textarea:focus {
  background-color: #f0e6fd;
  outline: none;
  border-color: #3d008d;
}

input[type="checkbox"] {
  display: inline;
  width: auto;
  border: none;
}

input[type="checkbox"]:focus {
  outline: #3d008d solid 1px;
}

h3 {
  margin: 0.5rem 0;
  font-size: 1rem;
}

.fldErr {
  margin-left: 2rem;
  font-size: 1.7rem;
  font-weight: bold;
  color: red;
}

.invalid label {
  color: red;
}

.invalid input,
.invalid textarea {
  border: 1px solid red;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.v-enter-active {
  transition: all 2s ease-out;
}

.v-leave-active {
  transition: all 2s ease-in;
}

.v-enter-to,
.v-leave-from {
  opacity: 1;
  transform: scale(1);
}
</style>
