<template>
  <section
    class="jnrlSec"
    :class="{
      hlighted: hlJrnl(jnlId) && !jnlExcl,
      exclJnlChk: exclJnlChk,
    }"
  >
    <section>
      <header class="crdHdr" @click="setHlLoad(jnlId, idx, jnlNo)">
        <p>
          <span class="jnrlNo">{{ frmtJrnlNo(jnlNo) }} </span>
          <!-- <span class="jrnlDate"><input type="date" id="date" name="date" v-model.lazy="frmJnlDate.val" @change="updJrnl('date')" /></span> -->
          <span class="jrnlDate"><input type="date" id="date" name="date" v-model.lazy="frmJnlDate.val" @blur="updJrnl('date')" /></span>

          <span class="exclJCb">
            <label for="exclJ">Exclude</label>
            <input type="checkbox" id="exclJ" name="exclJ" class="excljc" :checked="jnlExcl" @click.stop @change="updChk" />
          </span>
        </p>
        <p v-if="!frmJnlDate.isValid" class="fldErr">Invalid Date!</p>
      </header>
      <base-spinner v-if="isUpdating" class="spinner"></base-spinner>
      <div>
        <table>
          <thead>
            <th>Leg</th>
            <th>Account</th>
            <th>Dr.</th>
            <th>Cr.</th>
          </thead>
          <tbody>
            <jrnl-leg v-for="leg in jnlLegs" :key="leg.legId" :leg-no="leg.legNo" :sign="leg.legDrCr" :acc-id="leg.accId" :leg-id="leg.legId" :leg-note="leg.legNote" :amount="leg.legAmount" :cat-id="leg.catId" :type-id="leg.typeId"> </jrnl-leg>
          </tbody>
        </table>

        <p class="lblBeing">Being:</p>
        <textarea class="fldBeing" id="fldBeing" maxlength="255" minlength="1" required v-model="frmBeing" @blur="updJrnl('being')"></textarea>
        <div>
          <button @click.stop="mngNotes" class="btnNotes" :class="{ ntsExist: isNotesExist }">Notes</button>
        </div>
        <textarea v-if="!notesIsHidden" class="fldNotes" id="fldNotes" v-model="frmNotes" @blur="updJrnl('notes')"></textarea>
      </div>
    </section>
  </section>
</template>

<script>
import JrnlLeg from "./JrnlLeg.vue";
import mixin from "@/mixins/mixin.js";
export default {
  props: ["jnlId", "jnlNo", "jnlDate", "jnlLegs", "jnlBeing", "jnlNotes", "idx", "jnlKey", "jnlExcl"],
  components: {
    JrnlLeg,
    // JournalForm,
  },
  mixins: [mixin],
  data() {
    return {
      isUpdating: false,
      // error: null,
      exclJnlChk: this.jnlExcl,
      frmBeing: this.jnlBeing,
      frmNotes: this.jnlNotes,
      frmJnlDate: { val: this.jnlDate, isValid: true },
      notesIsHidden: true,
      error: null,
    };
  },
  computed: {
    isNotesExist() {
      //   console.log('nts length', this.jnlNotes.length);
      // console.log("jnl notes", this.jnlNotes);
      if (this.jnlNotes != null && this.jnlNotes.length > 0) return true;

      return false;
    },
    hlJrnlId() {
      return this.$store.getters.getHlJnlId;
    },
    isLoggedIn() {
      return this.$store.getters.getIsLoggedIn;
    },
  },
  methods: {
    // frmtJrnlNo(no) {
    //   return this.fillZero(no, 3);
    // },
    updChk() {
      // Emit custom event for SecJournals.vue to listen to:
      // this.$emit('toggle-excl', this.jnlExcl);
      // console.log("exclJnlChk before update", this.exclJnlChk);
      this.exclJnlChk = !this.exclJnlChk;
      // console.log("exclJnlChk after update", this.exclJnlChk);

      this.updJrnl("excl");
      // console.log("uldata after checkbox", this.$store.getters.getUlDataTypes);
    },
    updJrnl(fld) {
      const frmData = {
        jnlId: this.jnlId,
        jnlExcl: this.jnlExcl,
        fld: "",
        val: "",
        // legs: this.jnlLegs
      };
      if (fld === "being") {
        frmData.fld = "being";
        frmData.val = this.frmBeing;
      }

      if (fld === "notes") {
        frmData.fld = "description";
        frmData.val = this.frmNotes;
      }

      if (fld === "excl") {
        // console.log("excl before update", this.exclJnlChk);
        frmData.fld = "exclude";
        frmData.val = this.exclJnlChk;
      }

      if (fld === "date") {
        console.log("journal date is: ", this.frmJnlDate.val);
        if (!this.isValidDate(this.frmJnlDate.val)) {
          this.frmJnlDate.isValid = false;
          return;
        }
        if (this.isValidDate(this.frmJnlDate.val)) this.frmJnlDate.isValid = true;
        frmData.fld = "date";
        frmData.val = this.frmJnlDate.val;
      }

      this.isUpdating = true;
      if (this.isLoggedIn) {
        this.$store
          .dispatch("postShrtEditJnl", frmData)
          .then(() => {
            console.log("to be or not to be");
            this.isUpdating = false;
          })
          .catch((error) => {
            this.isUpdating = false;
            this.$store.commit("setErrorShrtUpd", error);
          });
      }
      if (!this.isLoggedIn) {
        this.$store.commit("setShortEditJnl", frmData);
        this.isUpdating = false;
      }
    },
    mngNotes() {
      this.notesIsHidden = !this.notesIsHidden;
    },
    hlJrnl(jId) {
      // const hlJrnl = this.$store.getters.getHlJnlNo;
      // const hlJrnl = this.$store.getters.getHlJnlId;
      // console.log('jId, jlJrnl', jId, hlJrnl);
      if (jId == this.hlJrnlId) {
        return true;
      } else {
        return false;
      }
    },
    // handleError() {
    //   this.error = null;
    // },
    // tryClose() {
    //   this.$emit("close");
    // },
    setHlLoad(id, index, no) {
      // const hlJrnl = this.$store.getters.getHlJnlNo;
      // const hlJrnl = this.$store.getters.getHlJnlId;
      // console.log("hlJrnl", hlJrnl);
      let acs = [];
      console.log("id, index, no", id, index, no);
      console.log("journalList", this.journalList);
      // if (hlJrnl == id) {
      if (id == this.hlJrnlId) {
        // this.$store.commit("removeJnlNo");
        this.$store.commit("clearHlights");
      } else {
        this.$store.commit("clearHlights");
        // this.$store.commit("removeJnlNo");
        this.$store.commit("updateHlJnlId", id);
        this.$store.commit("updateHlJnlNo", no);
        // console.log("jnl clicked", id, index, key);

        // this.$store.commit("updateHlAcTB", null);

        // this.$store.commit("removeJnlIdx");
        this.$store.commit("updateHlJnlIdx", index);

        // Get the legs of the journal at the given index:
        let legs = this.$store.getters.getJournals[index].legs;
        // console.log("legs", legs);

        legs.forEach(function (arrItem) {
          let x = arrItem.accId;
          acs.push(x);
        });
        // this.$store.commit("removeJnlAcIds");
        this.$store.commit("updateHlJnlAcIds", acs);

        // this.$store.commit("removeJnlKey");
        // this.$store.commit("updateJnlKey", key);
      }
      console.log("highlights", this.$store.getters.getHlights);
    },
    handleError() {
      this.error = null;
    },
  },
};
</script>

<style scoped>
.exclJCb {
  padding-left: 12rem;
  font-size: 12px;
  font-weight: bold;
}

td,
th {
  border: 1px solid #999;
  padding: 0.5ren;
  text-align: left;
  padding: 4px;
  font-family: "Courier New", Courier, monospace;
  font-size: 12px;
}

.dtAmt {
  text-align: right;
}

/* tbody tr:nth-child(odd) {
  background: #eee;
} */

/* .Jrnls {
  grid-column: 1 / span 1;
  grid-row: 2 / span 3;
  height: 100%;
  width: 100%;
} */

/* .rootCntr {
  flex: auto;

} */
.jnrlSec {
  /* background-color: hsl(275, 43%, 95%); */
  background-color: white;
  box-shadow: 3px 3px 3px #ccc;
  overflow: auto;
  /* vertical-align: column; */
  /* display: block; */
  /* flex: auto; */
  /* height: calc(100vh - 220px); */
}

.exclJnlChk {
  background-color: hsl(0, 74%, 92%);
}

.crdHdr {
  background-color: hsl(275, 43%, 95%);
  /* background-color: lightsteelblue; */
}

.excljc {
  width: 15px;
  height: 15px;
}
.jnrlCntr {
  margin-top: 2.7rem;
  margin-left: 2.5rem;
  border: 2px solid lightgrey;
  margin-right: 10px;
  border-radius: 15px;
  width: fit-content;
}

.jnrlCntr p {
  font-family: courier;
  font-size: 1rem;
  margin-left: 5px;
  padding-top: 5px;
}

.lblBeing {
  font-family: "Times New Roman", Times, serif;
  font-style: italic;
  font-weight: 900;
  font-size: 1.3rem;
  margin-left: 5px;
  padding-top: 5px;
  margin-bottom: 1px;
}
.fldBeing {
  /* font-family: "Times New Roman", Times, serif; */
  font-family: "Roboto serif", serif;
  font-size: 1.7rem;
  margin-top: 2px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 2px;
  resize: none;
  padding: 5px;
  /* color: green; */
  /* color: inherit; */
  font-weight: 500;
  width: 49.5rem;
  height: 6.5rem;
  /* height: 4rem; */
  /* width: 26rem; */
  /* background-color: hsl(275, 43%, 95%); */
  background-color: inherit;
}

.btnNotes {
  margin-left: 5px;
  margin-bottom: 10px;
  font-size: 0.7rem;
  font-weight: bold;
}
.fldNotes {
  font-family: "Roboto serif", serif;
  font-size: 1.5rem;
  margin-top: 2px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  resize: none;
  padding: 5px;
  color: black;
  font-weight: 500;
  width: 49.5rem;
  /* height: fit-content; */
  /* display: inline-block; */
  /* overflow: auto; */
  height: 40rem;
  /* min-height: 100% !important; */
  /* height: 4rem; */
  /* width: 26rem; */
  /* background-color: hsl(275, 43%, 95%); */
  background-color: inherit;
}
.ntsExist {
  background-color: hsl(120, 77%, 86%);
}
.jnrlCntr table {
  border-collapse: collapse;
  margin-left: 5px;
  margin-right: 5px;
  /* table-layout: fixed; */
  /* width: 50rem; */
}

.bckcolJrnl {
  background-color: lightcyan;
}

.jnrlNo {
  font-size: 2.2rem;
  font-weight: 900;
  font-family: "Courier New", Courier, monospace;
  color: green;
}

.jrnlDate input {
  margin-left: 2rem;
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 2rem;
  color: black;
  height: 2.2rem;
  width: 18rem;
}

.dispJnlTd {
  display: none;
}

.crdHdr .fldErr {
  margin-left: 2rem;
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: red;
}
</style>
