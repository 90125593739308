<template>
  <!-- <h2>Trial Balance</h2> -->
  <base-error :show="!!error" title="An error occurred!" @close="handleError">
    <p>{{ error }}</p>
  </base-error>
  <div v-if="isUlDataLoading" class="spinner">
    <base-spinner></base-spinner>
  </div>
  <section class="rootCntr" id="tb-list" v-else-if="trialBalList && trialBalList.length">
    <table>
      <section class="tbCntr">
        <thead>
          <tr>
            <th v-if="isProcessingTb" class="spinner" colspan="4">
              <base-spinner></base-spinner>
            </th>
            <th v-else-if="!isProcessingTb" colspan="4" class="stmtTitle"><input type="text" id="tbTitle" name="tbTitle" maxlength="200" :value="tbTitle" @blur="updTitle($event, 'bal')" /></th>
            <!-- <th></th> -->
            <!-- <th></th> -->
          </tr>
          <tr>
            <th colspan="2"></th>
            <th colspan="2" class="curr">{{ baseCurr }}</th>
          </tr>
          <tr>
            <th>Number</th>
            <th>Description</th>
            <th class="curr">Dr.</th>
            <th class="curr">Cr.</th>
          </tr>
        </thead>
        <template v-for="typ in trialBalList" :key="typ.typeId">
          <tbody v-for="cat in typ.categories" :key="cat.catId">
            <!-- <template v-if="displayCatName && !catAcsBalAllZero(cat.catId)"> -->
            <template v-if="(!displayEmpAcs && displayCatName && (cat.catNoDrLegs != 0 || cat.catNoCrLegs != 0)) || (displayEmpAcs && displayCatName)">
              <tr>
                <td colspan="2" class="catName"><br />{{ cat.catName }}</td>
                <td></td>
                <td></td>
              </tr>
            </template>
            <tr
              v-for="ac in cat.accounts"
              :key="ac.accId"
              :id="'ac_' + ac.accId"
              @click="hlFromTBload(ac.accId)"
              :class="{
                hlighted: hlFromTB(ac.accId),
                active: hlFromJrnls(ac.accId),
                hlDrLeg: hlDrLeg(ac.accId),
                hlCrLeg: hlCrLeg(ac.accId),
              }"
            >
              <!-- <tr v-for="ac in cat.accounts[0]" :key="ac.accId" > -->
              <template v-if="(!displayEmpAcs && (ac.accNoDrLegs != 0 || ac.accNoCrLegs != 0)) || displayEmpAcs">
                <td>{{ ac.accNumber }}</td>
                <td>{{ ac.accName }}</td>
                <td class="amt">{{ computeDr(ac.accBal - ac.accExclBal) }}</td>
                <td class="amt">{{ computeCr(ac.accBal - ac.accExclBal) }}</td>
              </template>
            </tr>
          </tbody>
        </template>
        <tfoot>
          <tr>
            <td></td>
            <td></td>
            <!-- <td class="amt ledTotal">{{tbTotal('D')}}</td>
                    <td class="amt ledTotal">{{tbTotal('C')}}</td> -->
            <td class="amt ledTotal">{{ totalDrBal }}</td>
            <td class="amt ledTotal">{{ totalCrBal }}</td>
          </tr>
        </tfoot>
      </section>
    </table>
    <br />
    <br />
  </section>
  <!-- <div v-else>Loading...</div> -->
</template>

<script>
// import catAcs from '../mixins/catAcs.js'
import convAmt from "../../mixins/mixin.js";
export default {
  // mixins: [catAcs],
  mixins: [convAmt],
  data() {
    return {
      // displayEmptyAcs: false,
      //   displayCatName: true,
      isHlighted: false,
      result: [],
      isProcessingTb: false,
      error: null,
    };
  },
  computed: {
    isUlDataLoading() {
      return this.$store.getters.getUlDataLoading;
    },
    ledgerId() {
      return this.$store.getters.getCurrLedgerId;
    },
    tbTitle() {
      return this.$store.getters.getLedTbTitle;
    },
    trialBalList() {
      //   return this.$store.getters.getTrialBal;
      // return this.$store.getters.getTaccounts;
      //   return this.$store.getters.getUlDataTypes;
      return this.$store.getters.getTypes;
    },
    hasTrialBalList() {
      //   return this.$store.getters.hasTrialBal;
      return this.$store.getters.hasTaccounts;
    },
    totalDrBal() {
      // return this.$store.getters.getTotalDrBal.toLocaleString();
      return this.convAmt(this.$store.getters.getCalcTotalDrBal);
    },
    totalCrBal() {
      // return this.$store.getters.getTotalCrBal.toLocaleString();
      return this.convAmt(this.$store.getters.getCalcTotalCrBal);
    },
    baseCurr() {
      return this.$store.getters.getCurrLedgerBaseCurr;
    },
    baseCurrDesc() {
      return this.$store.getters.getCurrLedgerBaseCurrDesc;
    },
    displayCatName() {
      return this.$store.getters.getDisplCtrlTb_catName;
    },
    displayEmpAcs() {
      return this.$store.getters.getDisplCtrlAcs_empAcs;
    },
    legHl() {
      return this.$store.getters.getLegHl;
    },
  },
  methods: {
    // getCatAcs(){
    //     this.$store.dispatch('loadTrialbal'); // Invokes the action to load from db to vuex.
    // },
    // displayCat(catNoDrLegs, catNoCrLegs) {
    // displayCat() {
    //   if (this.displayAllAcs) {
    //     if (this.displayCatName) {
    //       return true;
    //     }
    //   } else {
    //     return false;
    //   }
    //   return false;
    // },
    hlDrLeg(accId) {
      if (this.legHl.sign == "D" && this.legHl.accId == accId) {
        // document.getElementById("tb-list").scrollTop -= 10;
        // document.getElementById("tb-cntr").scrollTop -= 10;
        document.getElementsByClassName("tb-list acs-list").scrollTop -= 10;
        const id = "ac_" + accId;
        let el = document.getElementById(id);
        console.log("el", el);
        el.scrollIntoView({ behavior: "smooth", block: "center" });
        return true;
      }
    },
    hlCrLeg(accId) {
      if (this.legHl.sign == "C" && this.legHl.accId == accId) {
        document.getElementById("tb-list").scrollTop -= 10;

        const id = "ac_" + accId;
        let el = document.getElementById(id);
        console.log("el", el);
        el.scrollIntoView({ behavior: "smooth", block: "nearest" });
        return true;
      }
    },

    catAcsBalAllZero(catId) {
      // let catId = 12;
      for (let cat of this.result) {
        if (cat.catId === catId) {
          // console.log(cat.catName);
          for (let ac of cat.accounts[0]) {
            if (ac.accBal != 0) {
              return false;
            }
          }
        }
      }
      return true;
    },
    catOnlyZeroBalAcs(catId) {
      console.log("From catOnlyZeroBalAcs");
      console.log(catId);
      // console.log(this.catAcs.catId.value=7);
    },
    hlFromJrnls(accId) {
      const hlAcs = this.$store.getters.getJnlAcIds;
      return hlAcs.includes(accId);
    },
    hlFromTB(accId) {
      const hlAcTB = this.$store.getters.getHlTBac;
      if (hlAcTB == accId) {
        return true;
      } else {
        return false;
      }
    },
    hlFromTBload(accId) {
      const hlAcTB = this.$store.getters.getHlTBac;
      if (hlAcTB == accId) {
        this.$store.commit("updateHlAcTB", null);
      } else {
        this.$store.commit("removeJnlNo");
        this.$store.commit("removeJnlAcIds");
        this.$store.commit("updateHlAcTB", accId);
      }
    },

    computeDr(amt) {
      if (amt <= 0) {
        return "";
      }
      // return amt.toLocaleString();
      return this.convAmt(amt);
    },
    computeCr(amt) {
      if (amt >= 0) {
        return "";
      }
      let amt1 = amt * -1;
      // return amt1.toLocaleString();
      return this.convAmt(amt1);
    },
    // tbTotal(sign){
    //     if (sign === 'D') {
    //         return this.$store.getters.getTotalDrs.toLocaleString();
    //     } else {
    //         return this.$store.getters.getTotalCrs.toLocaleString();
    //     }
    // },
    updTitle(e) {
      if (e.target.value.length == 0) {
        console.log("description must not be blank");
        e.target.value = this.tbTitle;
      }
      if (e.target.value.length > 0) {
        this.updLed("tb_title", e.target.value.trim());
      }
    },
    updLed(fld, val) {
      this.isProcessingTb = true;
      const frmData = {
        ledId: this.ledgerId,
        fld: fld,
        val: val,
      };
      console.log("title update data", frmData);
      this.$store
        .dispatch("postShrtEditLed", frmData)
        .then(() => {
          this.isProcessingTb = false;
        })

        .catch((error) => {
          console.log("error caught" + error);
          this.isProcessingTb = false;
          this.error = error;
          console.log("data: error: ", this.error);
        });
    },
    handleError() {
      this.error = null;
    },
    testB() {
      console.log("hello");
      // console.log(this.$store.getters.getTest());
      console.log(this.catAcs);
      // this.catOnlyZeroBalAcs(7);
      console.log(this.catAcsBalAllZero);
      // console.log(this.displayZero);
      // console.log(this.displayCatName);
    },
  },
  created() {
    // this.getCatAcs();
  },
};
</script>

<style scoped>
.hlighted {
  background-color: rgb(201, 238, 201);
}
.active {
  background-color: lightyellow;
}
/* .rootCntr {
  margin-left: 2rem;
} */

h2 {
  margin-top: 2rem;
  margin-left: 2rem;
}

/* .tbCntr {
  overflow: auto;
  height: calc(100vh - 250px);
} */

td,
th {
  border: 1px solid #999;
  padding: 0.5ren;
  text-align: left;
  padding: 4px;
  font-family: "Courier New", Courier, monospace;
  font-size: 12px;
}

.amt {
  text-align: right;
}

.ledTotal {
  font-weight: 900;
}

.catName {
  color: green;
  font-weight: bold;
  font-size: 1.7rem;
}

table {
  border-collapse: collapse;
  background-color: white;
  margin-top: 2rem;
  margin-left: 2rem;
  /* table-layout: fixed; */
  /* width: 60rem; */
  /* table-layout: fixed; */
  /* width: 50rem; */
}

.curr {
  text-align: center;
  font-size: 16px;
  font-weight: 900;
}

.hlDrLeg {
  background-color: lightgreen;
}

.hlCrLeg {
  background-color: lightpink;
}
.stmtTitle > input {
  font-weight: 600;
  font-size: 1.8rem;
  border-collapse: collapse;
  background-color: white;
  margin-top: 1rem;
  margin-left: 2rem;
  /* table-layout: fixed; */
  width: 62rem;

  border: none;
}
</style>
