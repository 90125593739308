import { createApp } from "vue";
import "./assets/styles/reset.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import BaseButton from "./components/UI/BaseButton.vue";
import BaseSpinner from "./components/UI/BaseSpinner.vue";
import BaseDialog from "./components/UI/BaseDialog.vue";
import BaseError from "./components/UI/BaseError.vue";

const app = createApp(App);
app.component("base-button", BaseButton);
app.component("base-spinner", BaseSpinner);
app.component("base-dialog", BaseDialog);
app.component("base-error", BaseError);
// app.component("tiny-editor", Editor);

app.use(store).use(router).mount("#app");
