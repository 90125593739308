<template>
  <base-error :show="!!error" title="An error occurred!" @close="handleError">
    <p>{{ error }}</p>
  </base-error>
  <teleport to="body">
    <div @click="tryClose" class="backdrop"></div>
    <dialog open v-if="show" class="dialog">
      <header>
        <h2>Edit Ledger Notes - {{ ledName }}</h2>
      </header>
      <div v-if="isProcessing" class="spinner">
        <base-spinner></base-spinner>
      </div>
      <section v-else-if="!isProcessing">
        <div class="form-control">
          <!-- <label for="ledNotes">Notes:</label> -->
          <!-- <textarea id="ledNotes" name="ledNotes" rows="12" maxlength="10000" v-model="frmNotes" /> -->
          <tiny-editor class="tinymce" :inline="true" api-key="xtewrno7kptlzetzurvlh3j0uzi269lzz7zczr42tiu7hekj" :init="editorConfig" v-model="frmNotes" />
        </div>
        <menu>
          <base-button type="button" @click="updLedNotes">Save Changes</base-button>
          <base-button type="button" @click="tryClose">Cancel</base-button>
        </menu>
      </section>
    </dialog>
  </teleport>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
export default {
  components: { "tiny-editor": Editor },
  emits: ["close"],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      frmNotes: "",
      isProcessing: false,
      error: null,
      tinyApi: process.env.TINY_API,
    };
  },
  computed: {
    editorConfig() {
      return {
        // content_css: "tinymce/skins/content/default/content.min.css",
        // content_css: "default",
        // skin_url: process.env.BASE_URL + "tinymce/skins/ui/oxide",
        selector: ".tinymce",
        inline: true,
        height: "480",
        font_formats: "Roboto=roboto",
        content_style: "body { font-family:Roboto,sans-serif; font-size:16px } p { line-height: 18px; margin-bottom: 14px; }",
        // content_style: contentUiCss.toString() + "\n" + contentCss.toString(),
        menubar: false,
        plugins: "table lists link",
        toolbar: "fontsizeselect | bold italic underline strikethrough | backcolor forecolor | outdent indent | bullist numlist | table",
      };
    },
    ledgerId() {
      return this.$store.getters.getCurrLedgerId;
    },
    ledName() {
      return this.$store.getters.getCurrLedgerName;
    },
    ledNotes() {
      return this.$store.getters.getCurrLedgerNotes;
    },
  },
  methods: {
    tryClose() {
      this.$emit("close");
    },

    updLedNotes() {
      console.log("updLedNotes", this.frmNotes);
      this.updLed("notes", this.frmNotes);
    },

    updLed(fld, val) {
      this.isProcessing = true;
      const frmData = {
        ledId: this.ledgerId,
        fld: fld,
        val: val,
      };

      this.$store
        .dispatch("postShrtEditLed", frmData)
        .then(() => {
          this.isProcessing = false;
          this.tryClose();
        })

        .catch((error) => {
          console.log("error caught" + error);
          this.isProcessing = false;
          this.error = error;
          console.log("data: error: ", this.error);
        });
    },
    handleError() {
      this.error = null;
    },
  },
  mounted() {
    this.frmNotes = this.ledNotes;
    console.log("frmNotes", this.frmNotes);
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 10;
}
.tinymce {
  z-index: 1600 !important;
}
dialog {
  /* width: 80%;
  height: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -150px;
  margin-top: -150px; */
  position: fixed;
  top: 10vh;
  left: 20vh;
  margin: 0 auto;
  width: 60%;
  height: 80%;
  z-index: 90;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: auto;
  background-color: white;
  font-family: "Roboto Serif", serif;
}

header {
  background-color: #3a0061;
  color: white;
  width: 100%;
  padding: 1rem;
  position: sticky;
  top: 0;
}

.form-control {
  margin: 1rem;
}

textarea {
  display: block;
  font-size: 2rem;
  width: 95%;
  /* width: 100%; */
  margin-left: 1rem;
  border: 1px solid #ccc;
  /* font: inherit; */
}

textarea:focus {
  background-color: #f0e6fd;
  outline: none;
  border-color: #3d008d;
}

#ledNotes {
  font-family: "Arial", sans-serif;
}
menu {
  padding: 1rem;
  display: flex;
  /* justify-content: flex-end; */
  margin: 1;
  font-size: 2rem;
}
</style>
