import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LedgerView from "../views/LedgerView.vue";
import UserList from "../views/UserList.vue";
import FaqView from "../views/FaqView.vue";
import SupportView from "../views/SupportView.vue";
import SignUp from "../views/SignupView.vue";
import LogIn from "../views/LogIn.vue";
import VerifyFailed from "../views/VerifyFailed.vue";

const routes = [
  {
    path: "/",
    redirect: { name: "home" },
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/ledger",
    name: "ledger",
    component: LedgerView,
  },
  {
    path: "/users",
    name: "Users",
    component: UserList,
  },
  {
    path: "/faq",
    name: "faq",
    component: FaqView,
  },

  {
    path: "/support",
    name: "support",

    component: SupportView,
  },
  {
    path: "/signup",
    name: "Signup",
    component: SignUp,
  },
  {
    path: "/verifyFail",
    name: "verifyFail",
    component: VerifyFailed,
  },
  {
    path: "/login",
    name: "Login",
    component: LogIn,
  },
  {
    name: "Logout",
    redirect: { name: "home" },
  },
  {
    // path: '/:notFound(.*)',
    path: "/:catchAll(.*)",
    redirect: "/home",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
