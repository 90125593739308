<template>
  <div>
    <h2>Journals</h2>
    <jrnl-delete v-if="showJrnlDel" :show="showJrnlDel" @close="showJrnlDelete"></jrnl-delete>
    <button id="btn_new_jrnl" @click="showJournal('new')" :disabled="!ledgerId">New Journal</button>
    <!-- <router-link :to="{name: 'NewJournal'}">
          <button id="myButton" class="foo bar">Go!</button>
        </router-link> -->

    <button :disabled="showEdit" @click="showJournal('edit')">Edit</button>
    <button :disabled="!hlightedJrnl" @click="showJrnlDelete">Delete</button>
    <button @click="testA">Test</button>
  </div>
  <maint-journal v-if="showJrnl" :show="showJrnl" @close="showJournal('close')"></maint-journal>
</template>

<script>
import MaintJournal from "./maintJournal/MaintJournal.vue";
import JrnlDelete from "./jrnlDelete.vue";
import mixin from "@/mixins/mixin.js";
export default {
  components: { MaintJournal, JrnlDelete },
  mixins: [mixin],
  data() {
    return {
      showJrnlDel: false,
      showJrnl: false,
    };
  },
  computed: {
    hlightedJrnl() {
      console.log("getHlJnlNo - JrnlList", this.$store.getters.getHlJnlNo);
      return this.$store.getters.getHlJnlId;
    },
    HlightedJnlNo() {
      return this.fillZero(this.$store.getters.getHlJnlNo, 3);
    },
    currJrnl() {
      return this.$store.getters.getJnlById(this.hlightedJrnl);
    },
    showEdit() {
      // if (!this.hlightedJrnl) {
      //   if (this.currJrnl) {
      //     if (!this.currJrnl.jnlExcl) {
      //       return true;
      //     }
      //   }
      // }
      console.log(this.currJrnl);
      // if (!this.currJrnl && !this.currJrnl.jnlExcl) {
      if (!this.currJrnl) {
        return true;
      }
      if (this.currJrnl) {
        if (this.currJrnl.jnlExcl) return true;
      }

      return false;
    },
    ledgerId() {
      return this.$store.getters.getCurrLedgerId;
    },
  },
  methods: {
    testA() {
      // this.showDialog = true;
      // console.log("types", this.$store.getters.getTypes);
      // console.log("journals", this.$store.getters.getJournals);
      // this.showDelDialog = true;
      // console.log("hLights", this.$store.getters.getHlights);
      // console.log("curr jrnl", this.currJrnl);
      console.log("auth data", this.$store.getters.getAuthData);

      // console.log(this.fillZero("17", 3));
    },
    showJrnlDelete() {
      this.showJrnlDel = !this.showJrnlDel;
    },
    delDialogCancel() {
      this.showDelDialog = false;
    },
    delDialogOk() {
      this.delJrnl();
      this.showDelDialog = false;
    },
    tryClose() {
      this.$emit("close");
    },
    showJournal(mode) {
      console.log("shownewjnl");
      if (mode == "close") {
        // this.$destroy(true);
        // this.$el.parentNode.removeChild(this.$el);
        console.log("showJournal close");

        // this.showJrnl = null;
      }
      if (mode == "new") {
        // this.showJrnl = true;
        const startNewJnl = {
          jnlDate: this.todaysDate(),
          jnlBeing: "",
          jnlDescr: "",
          jnlNo: null, // this will be the next jnl number
          ledgerId: null,
          legs: [
            { legNo: 1, typeId: null, catId: null, accId: null, legNote: "", legDrCr: "", legAmount: 0 },
            { legNo: 2, typeId: null, catId: null, accId: null, legNote: "", legDrCr: "", legAmount: 0 },
          ],
        };
        this.$store.commit("clearWorkJournal");
        this.$store.commit("clearHlights");
        this.$store.commit("setWrkJrnlMode", "NEW");
        this.$store.commit("loadWorkJournal", startNewJnl);
        console.log("new jrnl initialise:", this.$store.getters.getWorkJournal_1);
        // this.showJrnl = false;
        window.scrollTo(0, top);
      }

      if (mode == "edit") {
        this.$store.commit("setWrkJrnlMode", "EDIT");
        const editJnl = this.currJrnl;
        console.log("currJrnl", this.currJrnl);
        const editJnlClone = JSON.parse(JSON.stringify(editJnl));
        this.$store.commit("loadWorkJournal", editJnlClone);
      }

      this.showJrnl = !this.showJrnl;
      // this.showJrnl = false;
      // let element = this.$refs[refName];
      // var top = element.offsetTop;
    },
    reset() {
      Object.assign(this.$data, this.$options.data());
    },
    todaysDate() {
      // console.log(new Date().toDateString());
      let d = new Date();
      let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
      let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
      // console.log(`${ye}-${mo}-${da}`);
      return `${ye}-${mo}-${da}`;
    },
  },
};
</script>

<style scoped>
* {
  font-family: "Plus Jakarta Sans", sans-serif;
}

div {
  background-color: rgb(203, 225, 240);
  height: 10rem;
  width: inherit;
  font-size: 1.7rem;
  margin-bottom: 1rem;
  position: fixed;
}

button {
  font-size: 1.5rem;
  margin-left: 1rem;
  border-radius: 10px;
}

h2 {
  margin-left: 1rem;
}

input {
  margin-left: 1rem;
}

.jrnl-del {
  font-size: 1.7rem;
  color: red;
  font-weight: bold;
}

.jrnl-no {
  font-size: 1.7rem;
  color: green;
  font-weight: bold;
}
.jrnl-del-btn {
  font-size: 1.7rem;
}
</style>
