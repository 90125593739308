<template>
  <the-home></the-home>
</template>

<script>
import TheHome from "@/components/TheHome.vue";
export default {
  components: { TheHome },
};
</script>

<style scoped>
the-home {
  background-color: rgb(235, 241, 247);
}
</style>
