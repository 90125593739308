<template>
  <base-error :show="!!error" title="An error occurred!" @close="handleError">
    <p>{{ error }}</p>
  </base-error>
  <h1>Login</h1>
  <form @submit.prevent="submitForm">
    <div class="form-control">
      <label for="email">E-Mail</label>
      <input type="email" v-model.trim="email" id="email" />
    </div>
    <div class="form-control">
      <label for="password">Password</label>
      <input type="password" v-model.trim="password" id="password" />
    </div>
    <div>
      <p v-if="!formIsValid">Please enter a valid email and password (at least 6 chars long)</p>
    </div>
    <button>Login</button>
  </form>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      formIsValid: true,
      error: null,
    };
  },
  methods: {
    handleError() {
      this.error = null;
    },
    submitForm() {
      this.formIsValid = true;
      if (this.email === "" || !this.email.includes("@") || this.password.length < 6) {
        this.formIsValid = false;
        return;
      }
      this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          // reroute to ledger
          this.$router.push({ name: "ledger" });
        })
        .catch((error) => {
          this.error = error;
          console.log("login error", this.error);
        });
    },
    // send http request - signup with email password
  },
};
</script>

<style scoped>
form {
  margin: 1rem;
  padding: 1rem;
}

.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}

input,
textarea {
  display: block;
  width: 30%;
  font: inherit;
  border: 1px solid #ccc;
  padding: 0.15rem;
}

input:focus,
textarea:focus {
  border-color: #3d008d;
  background-color: #faf6ff;
  outline: none;
}
</style>
