import mixin from "@/mixins/mixin.js";
// import htmlToPdfmake from "html-to-pdfmake"

export default {
  mixins: [mixin],
  data() {
    return {
      test: "some test data",
    };
  },

  computed: {
    noDecPl() {
      return this.$store.getters.getCurrLedgerBaseCurrNoDec;
    },
    ledgerName() {
      return this.$store.getters.getCurrLedgerName;
    },
    ledgerDescr() {
      return this.$store.getters.getCurrLedgerDescr;
    },
    ledgerNotes() {
      return this.$store.getters.getCurrLedgerNotes;
    },
    baseCurr() {
      return this.$store.getters.getCurrLedgerBaseCurr;
    },
    jrnls() {
      return this.$store.getters.getJournals;
    },
    acs() {
      return this.$store.getters.getAccounts;
    },
    types() {
      return this.$store.getters.getTypes;
    },
    bsTitle() {
      return this.$store.getters.getLedBsTitle;
    },
    plTitle() {
      return this.$store.getters.getLedPlTitle;
    },
    totalDrBal() {
      return this.convAmt(this.$store.getters.getCalcTotalDrBal);
    },
    totalCrBal() {
      return this.convAmt(this.$store.getters.getCalcTotalCrBal);
    },
    curDate() {
      const curDate = new Date();
      return curDate;
    },
    netCurrAssets() {
      return this.$store.getters.getNetCA;
    },
    totAssetsLessCurrLiabs() {
      return this.$store.getters.getTotAssetsLessCurrLiabs;
    },
    totAssetsLiabs() {
      return this.$store.getters.getTotAL;
    },
    netProfit() {
      return this.$store.getters.getNetProfit;
    },
    shareHoldSurpl() {
      return this.$store.getters.getShareHoldSurpl;
    },
    grossProfit() {
      return this.$store.getters.getGrossProfit;
    },
    netIncome() {
      return this.$store.getters.getNetIncome;
    },
  },
  methods: {
    testPdf() {
      console.log("mixin: ", this.test);
    },
    pdfgen: function () {
      let ledName = this.ledgerName;
      let ledDescr = this.ledgerDescr;
      var pdfMake = require("pdfmake/build/pdfmake.js");
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require("pdfmake/build/vfs_fonts.js");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }

      let content = [];
      content.push(this.pdfGenTitle());
      content.push(this.pdfToc());
      content.push(this.pdfGenIntro());
      content.push(this.pdfGenBS());
      content.push(this.pdfGenPL());
      content.push(this.pdfGenTB());
      content.push(this.pdfGenJrnls());
      content.push(this.pdfGenAcs());
      // content.push(this.lstPage());

      console.log("content", content);
      var docDefinition = {
        pageSize: "A4",

        content: content,
        // watermark: { text: "TAccountZone Demo", color: "blue", opacity: 0.08, bold: false, italics: true },
        // background: "simple text",
        // margin: [left, top, right, bottom]
        // header: function (page) {
        //   if (page != 1) return { text: "Hello!" };
        //   // else return { text: "Hi!" };
        // },
        // header: function (currentPage, pageCount, pageSize) {
        header: function (page) {
          // you can apply any logic and return any valid pdfmake element

          //   return [{ text: "simple text", alignment: currentPage % 2 ? "left" : "right" }, { canvas: [{ type: "rect", x: 170, y: 32, w: pageSize.width - 170, h: 40 }] }];
          if (page != 1) {
            // return { columns: [{ text: "[LOGO OF CLIENT HERE]" }, { image: "../../../assets/images/tazLogo_1.png", alignment: "right", width: 50 }] };
            return [
              {
                columns: [
                  { text: ledName, fontSize: 10, bold: true },
                  { text: ledDescr, fontSize: 10, bold: true, alignment: "right" },
                  // {
                  //   image: this.convertImgUrlToBase64("http://localhost:3000/tst_small.jpg"),
                  //   width: 50,
                  //   margin: [0, -120, 0, 0],
                  // },
                ],
                margin: [10, 10, 10, 0],
              },
              { canvas: [{ type: "line", x1: 5, y1: 5, x2: 585, y2: 5, lineWidth: 1 }] },
              // left, top, right, bottom
            ];
            // return { columns: [{ text: "[LOGO OF CLIENT HERE]" }, { text: "hello" }] };
          }
        },

        footer: function (currentPage, pageCount) {
          // if (currentPage != 1) return "Pg. " + currentPage.toString() + " of " + pageCount;

          let d = new Date();
          const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
          const mths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
          let day = days[d.getDay()];
          let mth = mths[d.getMonth()];
          let hour = ("0" + d.getHours()).slice(-2);
          let mins = ("0" + d.getMinutes()).slice(-2);
          let secs = ("0" + d.getSeconds()).slice(-2);
          let date = day + " " + d.getDate() + " " + mth + " " + d.getFullYear() + ": " + hour + ":" + mins + ":" + secs;

          if (currentPage != 1) {
            return [
              // { canvas: [{ type: "line", x1: 5, y1: 5, x2: 585, y2: 5, lineWidth: 1 }] },
              {
                columns: [
                  { text: "Pg. " + currentPage.toString() + " of " + pageCount, alignment: "right", fontSize: 10 },
                  { text: date, alignment: "right", fontSize: 10, bold: true },
                ],
                margin: [70, 20, 10, 0],
              },
            ];
          }
        },
        // pageMargins: [20, 20, 20, 20],
        defaultStyle: {
          fontSize: 11,
        },
        // footer: function (page) {
        //   if (page != 1) {
        //     // return { columns: [{ text: "[LOGO OF CLIENT HERE]" }, { image: "../../../assets/images/tazLogo_1.png", alignment: "right", width: 50 }] };
        //     return {
        //       columns: [
        //         { text: "[LOGO OF CLIENT HERE]" },
        //         // {
        //         //   image: this.convertImgUrlToBase64("http://localhost:3000/tst_small.jpg"),
        //         //   width: 50,
        //         //   margin: [0, -120, 0, 0],
        //         // },
        //       ],
        //     };
        //     // return { columns: [{ text: "[LOGO OF CLIENT HERE]" }, { text: "hello" }] };
        //   }
        // },
      };

      pdfMake.createPdf(docDefinition).open();
    },

    convertImgUrlToBase64(url) {
      //   let self = this;
      var image = new Image();
      image.setAttribute("crossOrigin", "anonymous"); // use it if you try in a different origin of your web

      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;

        canvas.getContext("2d").drawImage(this, 0, 0);

        canvas.toBlob(
          function (source) {
            var newImg = document.createElement("img"),
              url = URL.createObjectURL(source);

            newImg.onload = function () {
              // no longer need to read the blob so it's revoked
              URL.revokeObjectURL(url);
            };

            newImg.src = url;
          },
          "image/jpeg",
          1
        );

        // If you ever stumble at 18 DOM Exception, just use this code to fix it
        // let dataUrl = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
        let dataUrl = canvas.toDataURL("image/jpeg");
        console.log("cek inside url : ", url, dataUrl);

        // if(url === backgroundImg) {
        //     self.assignImageBase64Background(dataUrl);
        // } else {
        //     self.assignImageBase64(dataUrl);
        // }
      };
      image.src = url;
    },

    assignImageBase64(img) {
      this.imgBase64 = img;
    },

    // getCurDate() {
    //   const curDate = new Date();
    //   return curDate;
    // },

    pdfGenTitle() {
      let titleSec = [];
      // let titles = [];
      // let table = {
      //   widths: [500],
      //   // heights: [400],
      //   margin: [20, 5, 0, 10],
      // };
      // table.body = [];
      // table.body.push([
      //   {
      //     text: [{ text: "\n\nFinancial Statements\n\n\n", bold: true, fontSize: 24, decoration: "underline" }, { text: this.ledgerName + "\n\n\n", bold: true, fontSize: 20, color: "purple" }, { text: this.bsTitle + "\n\n\n", bold: true, fontSize: 18 }, { text: this.plTitle + "\n\n\n", bold: true, fontSize: 18 }, { text: "Trial Balance, Journals, Accounts\n\n", bold: false, fontSize: 18 }, { text: "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n" }, { text: "\n\n\n\n\n\n\n" }, { text: "TAccountZone.com", link: "http://www.taccountzone.com", bold: true, fontsize: 14, color: "blue" }],
      //     //   text: "hello",
      //     alignment: "center",
      //     pageBreak: "after",
      //   },
      // ]);
      // //   table.margin.push( {[20, 5, 0, 10]}}
      // titleSec.push({ margin: [30, 20, 20, 20], table });
      // titleSec.push({ table });
      //   titleSec.push({ dontBreakRows: true, table });

      titleSec.push({ text: "\n\nFinancial Statements\n\n\n", bold: true, fontSize: 24, decoration: "underline", alignment: "center" }, { text: this.ledgerName + "\n\n\n", bold: true, fontSize: 20, color: "purple", alignment: "center" }, { text: this.bsTitle + "\n\n\n", bold: true, fontSize: 18, alignment: "center" }, { text: this.plTitle + "\n\n\n", bold: true, fontSize: 18, alignment: "center" }, { text: "Trial Balance, Journals, Accounts\n\n", bold: false, fontSize: 18, alignment: "center" }, { text: "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n" }, { text: "\n\n\n\n\n\n\n" }, { text: "TAccountZone.com", link: "http://www.taccountzone.com", bold: true, fontsize: 14, color: "blue", alignment: "center" });

      // titleSec.push({ margin: [30, 20, 20, 20], titles });
      return titleSec;
    },
    pdfToc() {
      let tocSec = [];
      // let toc = {
      //   id: "tocAcs",
      //   title: { text: "Accounts" },
      // };
      tocSec.push({ text: "Table of Contents", margin: [0, 20, 0, 30], bold: true, fontSize: 18, alignment: "center", pageBreak: "before" });

      tocSec.push(
        {
          toc: { id: "tocIntro" },
          margin: [50, 5, 50, 5],
          fontSize: 14,
        },
        {
          toc: { id: "tocBS" },
          margin: [50, 5, 50, 5],
          fontSize: 14,
        },
        {
          toc: { id: "tocPL" },
          margin: [50, 5, 50, 5],
          fontSize: 14,
        },
        {
          toc: { id: "tocTB" },
          margin: [50, 5, 50, 5],
          fontSize: 14,
        },
        {
          toc: { id: "tocJrnls" },
          margin: [50, 5, 50, 5],
          fontSize: 14,
        },
        {
          toc: { id: "tocAcs" },
          margin: [50, 5, 50, 5],
          fontSize: 14,
        }
      );
      // tocSec.push({ toc });
      return tocSec;
    },
    pdfGenIntro() {
      var htmlToPdfmake = require("html-to-pdfmake");
      let introSec = [];
      introSec.push({ text: "Introduction", bold: true, fontSize: 16, pageBreak: "before", tocItem: "tocIntro" });
      // introSec.push({ text: this.ledgerNotes });
      var html = htmlToPdfmake(this.ledgerNotes, {
        tableAutoSize: true,
      });
      // var html =
      console.log("The converted html", html);
      // introSec.push({ text: html });
      introSec.push(html);

      // let table = {
      //   widths: [500],
      // };
      // table.body = [];
      // table.body.push([{ text: this.ledgerNotes, pageBreak: "after" }]);
      // // introSec.push({ table });
      // introSec.push({ margin: [30, 20, 20, 20], table });

      return introSec;
    },
    pdfGenBS() {
      let types = this.types;

      let balSec = [];
      balSec.push({ text: this.bsTitle, bold: true, fontSize: 18, pageBreak: "before", tocItem: "tocBS" });

      let table = {
        widths: [100, 100, 100, 90, 90],
      };
      table.body = [];
      table.body.push([{}, {}, {}, { text: this.baseCurr, colSpan: 2, bold: true, alignment: "center" }, {}]);
      table.body.push([{}, {}, {}, { canvas: [{ type: "line", x1: 20, y1: 0, x2: 190, y2: 0, lineWidth: 1 }] }, {}]);

      for (let t = 0; t < 5; t++) {
        if (types[t].categories) {
          if (types[t].categories.length > 0) {
            table.body.push([{ text: types[t].typeName, colSpan: 3, bold: true, color: "purple", margin: [0, 10, 0, 0] }, {}, {}, {}, {}]);
            for (let c = 0; c < types[t].categories.length; c++) {
              if (types[t].categories[c].accounts) {
                if (types[t].categories[c].accounts.length > 0) {
                  table.body.push([{ text: types[t].categories[c].catName, colSpan: 3, bold: true, color: "green", margin: [20, 5, 0, 0] }, {}, {}, {}, {}]);
                  for (let ac = 0; ac < types[t].categories[c].accounts.length; ac++) {
                    let acId = types[t].categories[c].accounts[ac].accId;
                    let legs = this.$store.getters.getAcTxns(acId);
                    if (legs.length > 0) {
                      // table.body.push([{ text: [{ text: types[t].categories[c].accounts[ac].accNumber, bold: true }, { text: " - " + types[t].categories[c].accounts[ac].accName }], colSpan: 3, margin: [40, 0, 0, 0] }, {}, {}, { text: this.computeAmt(types[t].categories[c].accounts[ac].accBal - types[t].categories[c].accounts[ac].accExclBal, types[t].typeNrmlSgn), alignment: "right", decoration: ac == types[t].categories[c].accounts.length - 1 ? "underline" : "" }, {}]);
                      table.body.push([{ text: [{ text: types[t].categories[c].accounts[ac].accNumber, bold: true }, { text: " - " + types[t].categories[c].accounts[ac].accName }], colSpan: 3, margin: [40, 0, 0, 0] }, {}, {}, { text: this.computeAmt(types[t].categories[c].accounts[ac].accBal - types[t].categories[c].accounts[ac].accExclBal, types[t].typeNrmlSgn), alignment: "right" }, {}]);
                      if (ac == types[t].categories[c].accounts.length - 1) {
                        table.body.push([{}, {}, {}, { canvas: [{ type: "line", x1: 20, y1: 0, x2: 90, y2: 0, lineWidth: 1 }] }, {}]);
                        table.body.push([{}, {}, {}, { text: this.computeAmt(types[t].categories[c].catBal - types[t].categories[c].catExclBal, types[t].typeNrmlSgn), alignment: "right", color: "green" }, {}]);
                      }
                    }
                  }
                  // console.log("cclen typecode", c, types[t].categories.length, types[t].typeCode);
                  if (c == types[t].categories.length - 1) {
                    console.log("ctestpass", c, types[t].typeCode);
                    table.body.push([{}, {}, {}, {}, { text: this.computeAmt(types[t].typeBal - types[t].typeExclBal, types[t].typeNrmlSgn), alignment: "right", color: "purple" }]);
                    if (types[t].typeCode == "NCA" || types[t].typeCode == "CLB") {
                      table.body.push([{}, {}, {}, {}, { canvas: [{ type: "line", x1: 20, y1: 0, x2: 90, y2: 0, lineWidth: 1 }] }]);
                    }

                    // table.body.push([{}, {}, {}, {}, { text: "hello" }]);
                  }
                }
              }
            }
          }
        }
        if (types[t].typeCode == "CLB") {
          table.body.push([{ text: this.netCurrAssets >= 0 ? "NET CURRENT ASSETS" : "NET CURRENT LIABILITIES", colSpan: 3, color: "blueviolet", bold: true, margin: [0, 10, 0, 0] }, {}, {}, {}, { text: this.computeAmt(this.netCurrAssets, "netCurrAssets"), alignment: "right", color: "blueviolet", bold: true, margin: [0, 10, 0, 0] }]);
          table.body.push([{}, {}, {}, {}, { canvas: [{ type: "line", x1: 20, y1: 0, x2: 90, y2: 0, lineWidth: 1 }], margin: [0, 5, 0, 0] }]);
          table.body.push([{ text: "TOTAL ASSETS LESS CURRENT LIABILITIES", colSpan: 3, color: "blueviolet", bold: true, margin: [0, 10, 0, 0] }, {}, {}, {}, { text: this.computeAmt(this.totAssetsLessCurrLiabs), alignment: "right", color: "blueviolet", bold: true, margin: [0, 10, 0, 0] }]);
          table.body.push([{}, {}, {}, {}, { canvas: [{ type: "line", x1: 20, y1: 0, x2: 90, y2: 0, lineWidth: 1 }], margin: [0, 5, 0, 0] }]);
        }
        if (types[t].typeCode == "NCL") {
          table.body.push([{}, {}, {}, {}, { canvas: [{ type: "line", x1: 20, y1: 0, x2: 90, y2: 0, lineWidth: 1 }], margin: [0, 5, 0, 0] }]);
          table.body.push([{ text: this.totAssetsLiabs >= 0 ? "NET ASSETS" : "NET LIABILITIES", colSpan: 3, color: "blueviolet", bold: true, margin: [0, 10, 0, 0] }, {}, {}, {}, { text: this.computeAmt(this.totAssetsLiabs), alignment: "right", color: "blueviolet", bold: true, margin: [0, 10, 0, 0] }]);
          table.body.push([{}, {}, {}, {}, { canvas: [{ type: "line", x1: 20, y1: 0, x2: 90, y2: 0, lineWidth: 1, color: "blue", bold: true }], margin: [0, 5, 0, 0] }]);
          table.body.push([{}, {}, {}, {}, { canvas: [{ type: "line", x1: 20, y1: 0, x2: 90, y2: 0, lineWidth: 1, color: "blue", bold: true }], margin: [0, 0.5, 0, 0], color: "blue" }]);
        }

        if (types[t].typeCode == "RES") {
          // console.log("netprofit", this.netProfit);
          table.body.push([{ text: this.netProfit <= 0 ? "Profit this period" : "Loss this period", bold: true, colSpan: 3, margin: [40, 0, 0, 0] }, {}, {}, {}, { text: this.computeAmt(this.netProfit, "C"), bold: true, alignment: "right" }]);
          table.body.push([{}, {}, {}, {}, { canvas: [{ type: "line", x1: 20, y1: 0, x2: 90, y2: 0, lineWidth: 1 }], margin: [0, 5, 0, 0] }]);
          table.body.push([{ text: this.shareHoldSurpl <= 0 ? "SHAREHOLDERS SURPLUS" : "SHAREHOLDERS DEFICIT", bold: true, colSpan: 3, color: "blueviolet" }, {}, {}, {}, { text: this.computeAmt(this.shareHoldSurpl, "C"), bold: true, alignment: "right", color: "blueviolet" }]);
          table.body.push([{}, {}, {}, {}, { canvas: [{ type: "line", x1: 20, y1: 0, x2: 90, y2: 0, lineWidth: 1, color: "blue", bold: true }], margin: [0, 5, 0, 0] }]);
          table.body.push([{}, {}, {}, {}, { canvas: [{ type: "line", x1: 20, y1: 0, x2: 90, y2: 0, lineWidth: 1, color: "blue", bold: true }], margin: [0, 0.5, 0, 0] }]);
        }
      }
      balSec.push({ layout: "noBorders", table });
      return balSec;
    },
    pdfGenPL() {
      let types = this.types;
      let plSec = [];
      plSec.push({ text: this.plTitle, bold: true, fontSize: 18, pageBreak: "before", tocItem: "tocPL" });
      let table = {
        widths: [100, 100, 100, 90, 90],
      };
      table.body = [];
      table.body.push([{}, {}, {}, { text: this.baseCurr, colSpan: 2, bold: true, alignment: "center" }, {}]);
      table.body.push([{}, {}, {}, { canvas: [{ type: "line", x1: 20, y1: 0, x2: 190, y2: 0, lineWidth: 1 }] }, {}]);
      for (let t = 5; t < 9; t++) {
        if (types[t].categories) {
          if (types[t].categories.length > 0) {
            table.body.push([{ text: types[t].typeName, colSpan: 3, bold: true, color: "purple", margin: [0, 10, 0, 0] }, {}, {}, {}, {}]);
            for (let c = 0; c < types[t].categories.length; c++) {
              if (types[t].categories[c].accounts) {
                if (types[t].categories[c].accounts.length > 0) {
                  table.body.push([{ text: types[t].categories[c].catName, colSpan: 3, bold: true, color: "green", margin: [20, 5, 0, 0] }, {}, {}, {}, {}]);
                  for (let ac = 0; ac < types[t].categories[c].accounts.length; ac++) {
                    let acId = types[t].categories[c].accounts[ac].accId;
                    let legs = this.$store.getters.getAcTxns(acId);
                    if (legs.length > 0) {
                      table.body.push([{ text: [{ text: types[t].categories[c].accounts[ac].accNumber, bold: true }, { text: " - " + types[t].categories[c].accounts[ac].accName }], colSpan: 3, margin: [40, 0, 0, 0] }, {}, {}, { text: this.computeAmt(types[t].categories[c].accounts[ac].accBal - types[t].categories[c].accounts[ac].accExclBal, types[t].typeNrmlSgn), alignment: "right" }, {}]);
                      if (ac == types[t].categories[c].accounts.length - 1) {
                        table.body.push([{}, {}, {}, { canvas: [{ type: "line", x1: 20, y1: 0, x2: 90, y2: 0, lineWidth: 1 }] }, {}]);
                        table.body.push([{}, {}, {}, { text: this.computeAmt(types[t].categories[c].catBal - types[t].categories[c].catExclBal, types[t].typeNrmlSgn), alignment: "right", color: "green" }, {}]);
                      }
                    }
                  }
                  if (c == types[t].categories.length - 1) {
                    console.log("ctestpass", c, types[t].typeCode);
                    table.body.push([{}, {}, {}, {}, { text: this.computeAmt(types[t].typeBal - types[t].typeExclBal, types[t].typeNrmlSgn), alignment: "right", color: "purple" }]);
                    // if (types[t].typeCode == "NCA" || types[t].typeCode == "CLB") {
                    //   table.body.push([{}, {}, {}, {}, { canvas: [{ type: "line", x1: 20, y1: 0, x2: 90, y2: 0, lineWidth: 1 }] }]);
                    // }

                    // table.body.push([{}, {}, {}, {}, { text: "hello" }]);
                  }
                }
              }
            }
          }
        }
        if (types[t].typeCode == "CSL") {
          table.body.push([{}, {}, {}, {}, { canvas: [{ type: "line", x1: 20, y1: 0, x2: 90, y2: 0, lineWidth: 1 }], margin: [0, 5, 0, 0] }]);
          table.body.push([{ text: this.grossProfit <= 0 ? "GROSS PROFIT" : "GROSS LOSS", bold: true, colSpan: 3, color: "blueviolet" }, {}, {}, {}, { text: this.computeAmt(this.grossProfit, "C"), bold: true, alignment: "right", color: "blueviolet" }]);
        }
        if (types[t].typeCode == "OIN") {
          table.body.push([{}, {}, {}, {}, { canvas: [{ type: "line", x1: 20, y1: 0, x2: 90, y2: 0, lineWidth: 1 }], margin: [0, 5, 0, 0] }]);
          table.body.push([{ text: "NET INCOME", bold: true, colSpan: 3, color: "blueviolet" }, {}, {}, {}, { text: this.computeAmt(this.netIncome, "C"), bold: true, alignment: "right", color: "blueviolet" }]);
        }
        if (types[t].typeCode == "EXP") {
          table.body.push([{}, {}, {}, {}, { canvas: [{ type: "line", x1: 20, y1: 0, x2: 90, y2: 0, lineWidth: 1 }], margin: [0, 5, 0, 0] }]);
          console.log("netprofit", this.netProfit);
          table.body.push([{ text: this.netProfit <= 0 ? "NET PROFIT" : "NET LOSS", bold: true, colSpan: 3, color: "blueviolet" }, {}, {}, {}, { text: this.computeAmt(this.netProfit, "C"), bold: true, alignment: "right", color: "blueviolet" }]);
          table.body.push([{}, {}, {}, {}, { canvas: [{ type: "line", x1: 20, y1: 0, x2: 90, y2: 0, lineWidth: 1 }], margin: [0, 5, 0, 0] }]);
          table.body.push([{}, {}, {}, {}, { canvas: [{ type: "line", x1: 20, y1: 0, x2: 90, y2: 0, lineWidth: 1 }], margin: [0, 1, 0, 0] }]);
        }
      }
      plSec.push({ layout: "noBorders", table });

      return plSec;
    },
    pdfGenTB() {
      let acs = this.acs;
      let tbSec = [];
      tbSec.push({ text: "Trial Balance", bold: true, lineHeight: 1.5, fontSize: 18, alignment: "center", pageBreak: "before", tocItem: "tocTB" });
      let table = {
        widths: [120, 160, 90, 90],
      };
      table.body = [];
      table.body.push(
        [{ text: "", colSpan: 2 }, {}, { text: this.baseCurr, colSpan: 2, alignment: "center", bold: true }, {}],
        [
          { text: "Ac Number", bold: true },
          { text: "Name", bold: true },
          { text: "Dr.", bold: true, alignment: "center" },
          { text: "Cr.", bold: true, alignment: "center" },
        ]
      );
      console.log("acs", acs);
      for (let ac = 0; ac < acs.length; ac++) {
        let amt = acs[ac].accBal - acs[ac].accExclBal;
        if (amt != 0) {
          let drAmt = null;
          let crAmt = null;
          if (amt > 0) drAmt = this.convAmt(amt);
          if (amt < 0) crAmt = this.convAmt(amt * -1);
          table.body.push([
            {
              text: acs[ac].accNumber,
            },
            { text: acs[ac].accName },
            { text: drAmt, alignment: "right" },
            { text: crAmt, alignment: "right" },
          ]);
        }
      }
      table.body.push([{ text: "", colSpan: 2 }, {}, { text: this.totalDrBal, alignment: "right", bold: true }, { text: this.totalCrBal, alignment: "right", bold: true }]);

      tbSec.push({ margin: [0, 10, 0, 0], table });
      return tbSec;
    },
    pdfGenJrnls() {
      let jrnls = this.jrnls;
      let jrnlSec = [];
      jrnlSec.push({ margin: [0, 0, 0, 0], text: "Journals", bold: true, fontSize: 18, pageBreak: "before", tocItem: "tocJrnls" });
      if (jrnls) {
        for (let j = 0; j < jrnls.length; j++) {
          if (!jrnls[j].jnlExcl) {
            let table = {
              widths: [20, 100, 30, 130, 90, 90],
            };
            table.body = [];
            table.body.push([
              {
                text: [
                  { text: "Journal: ", bold: true, lineHeight: 1.5, fontSize: 18 },
                  { text: this.frmtJrnlNo(jrnls[j].jnlNo), color: "green", bold: true, fontSize: 18 },
                ],
                colSpan: 2,
                fillColor: "yellow",
                fillOpacity: 0.1,
                margin: [5, 5],
              },
              {},
              {
                text: [
                  { text: "Date: ", bold: true, fontSize: 12 },
                  { text: jrnls[j].jnlDate, fontSize: 12 },
                ],
                colSpan: 4,
                fillColor: "yellow",
                fillOpacity: 0.1,
                margin: [5, 5],
              },
              {},
              {},
              {},
            ]);
            table.body.push([{ text: "", colSpan: 4 }, {}, {}, {}, { text: this.baseCurr, bold: true, colSpan: 2, alignment: "center" }, {}]);
            table.body.push([{ text: "Lg.", bold: true }, { text: "Account", bold: true, colSpan: 2 }, {}, { text: "Note", bold: true }, { text: "Dr.", bold: true }, { text: "Cr", bold: true }]);
            for (let lg = 0; lg < jrnls[j].legs.length; lg++) {
              let acNoNm = this.getAcNoNm(jrnls[j].legs[lg].accId);

              table.body.push([{ text: jrnls[j].legs[lg].legNo }, { text: [{ text: acNoNm.no + "\n", color: "purple", bold: true }, { text: acNoNm.nm }], colSpan: 2 }, {}, { text: jrnls[j].legs[lg].legNote }, { text: jrnls[j].legs[lg].legDrCr == "D" ? this.convAmt(jrnls[j].legs[lg].legAmount) : "", alignment: "right" }, { text: jrnls[j].legs[lg].legDrCr == "C" ? this.convAmt(jrnls[j].legs[lg].legAmount) : "", alignment: "right" }]);
            }
            if (jrnls[j].legs.length > 2) {
              table.body.push([{ text: "Total:", colSpan: 4, alignment: "right", bold: true, italics: true }, {}, {}, {}, { text: this.convAmt(jrnls[j].jnlTotDrs), alignment: "right", bold: true, italics: true }, { text: this.convAmt(jrnls[j].jnlTotCrs), alignment: "right", bold: true, italics: true }]);
            }
            table.body.push([
              {
                text: [
                  { text: "Being: ", color: "green", bold: true, italics: true, fontSize: 13, lineHeight: 2, alignment: "justify" },
                  { text: jrnls[j].jnlBeing, bold: true, fontSize: 12, alignment: "justify" },
                ],
                colSpan: 6,
              },
              {},
              {},
              {},
              {},
              {},
            ]);
            if (jrnls[j].jnlDescr) {
              table.body.push([{ text: [{ text: "Journal notes:\n", bold: true, color: "blue" }, { text: jrnls[j].jnlDescr }], colSpan: 6 }, {}, {}, {}, {}, {}]);
            }
            // jrnlSec.push({ text: "\n\n\n" });
            // console.log("pg brk calc", j, jrnls.length);

            jrnlSec.push({ margin: [0, 10, 0, 20], table });
            // if (j == jrnls.length - 1) {
            //   jrnlSec.push({ table });
            //   // table.body.push({ pageBreak: "after" });
            // }
            // jrnlSec.push({ layout: "lightHorizontalLines", table });
            console.log("jrnlSec", jrnlSec);
          }
        }
      }
      return jrnlSec;
    },
    getAcNoNm(acId) {
      let acs = this.acs;
      for (let ac = 0; ac < acs.length; ac++) {
        if (acId == acs[ac].accId) {
          return { no: acs[ac].accNumber, nm: acs[ac].accName };
        }
      }
    },
    pdfGenAcs() {
      let types = this.types;
      let acsSec = [];
      //   let txns = [];

      acsSec.push({ margin: [-20, 0, 0, 10], text: "Accounts", bold: true, fontSize: 18, tocItem: "tocAcs", pageBreak: "before" });
      for (let t = 0; t < types.length; t++) {
        if (types[t].categories) {
          if (types[t].categories.length > 0) {
            for (let c = 0; c < types[t].categories.length; c++) {
              // let txns = [];
              // let table = {widths: [10, 10, 10, 125, 125, 90,90]};
              // table.body = [];
              if (types[t].categories[c].accounts) {
                if (types[t].categories[c].accounts.length > 0) {
                  for (let ac = 0; ac < types[t].categories[c].accounts.length; ac++) {
                    // if (ac.accId == 1) {
                    let acId = types[t].categories[c].accounts[ac].accId;

                    let legs = this.$store.getters.getAcTxns(acId);
                    // let legs = this.getAcLegs(acId);
                    console.log("srtLgs legs", legs);
                    let acNoNm = this.getAcNoNm(acId);
                    if (legs.length > 0) {
                      let srtLgs = legs.sort(function (a, b) {
                        return (
                          a.jnlFullDate.localeCompare(b.jnlFullDate) ||
                          function (a, b) {
                            return a.jnlNo - b.jnlNo;
                          }
                        );
                        // return a.jnlFullDate.localeCompare(b.jnlFullDate) || a.jnlNo > b.jnlNo ? 1 : a.jnlNo < b.jnlNo ? -1 : 0 || a.legNo > b.legNo ? 1 : a.legNo < b.legNo ? -1 : 0;
                      });
                      console.log("srtLgs", srtLgs);
                      let table = {
                        widths: [50, 20, 20, 120, 100, 90, 90],
                        // margin: [left, top, right, bottom]
                        // margin: [10, 10, 10, 10],
                      };
                      // acsSec.push({ text: "Account No: " + acNoNm.no + "  Name: " + acNoNm.nm });
                      table.body = [];
                      // table.body.push([{ text: [{ text: "Account No: ", bold: true }, { text: acNoNm.no + "\n" }, { text: "      Name: ", bold: true }, { text: acNoNm.nm }], colSpan: 7 }, {}, {}, {}, {}, {}, {}]);
                      table.body.push([{ text: "Account No: \n Name:\nType / Category:", bold: true, colSpan: 3, alignment: "right" }, {}, {}, { text: acNoNm.no + "\n" + acNoNm.nm + "\n" + types[t].typeName + " / " + types[t].categories[c].catName, colSpan: 4 }, {}, {}, {}]);

                      table.body.push([{ text: "", colSpan: 5 }, {}, {}, {}, {}, { text: this.baseCurr, alignment: "center", colSpan: 2 }, {}]);

                      table.body.push([{ text: "Date", bold: true }, { text: "Jnl", bold: true }, { text: "Lg" }, { text: "Being", bold: true }, { text: "Leg Note", bold: true }, { text: "Dr.", bold: true, alignment: "center" }, { text: "Cr", bold: true, alignment: "center" }]);
                      let drTot = null;
                      let crTot = null;
                      for (let lg = 0; lg < srtLgs.length; lg++) {
                        let amt = srtLgs[lg].legAmount;
                        let drAmt = null;
                        let crAmt = null;
                        if (srtLgs[lg].legDrCr == "D") {
                          drAmt = this.convAmt(amt);
                          drTot = drTot + amt;
                        }
                        if (srtLgs[lg].legDrCr == "C") {
                          crAmt = this.convAmt(amt);
                          crTot = crTot + amt;
                        }
                        if (srtLgs[lg].jnlExcl != 1) {
                          table.body.push([
                            {
                              text: srtLgs[lg].jnlDate,
                            },
                            {
                              text: this.frmtJrnlNo(srtLgs[lg].jnlNo),
                            },
                            {
                              text: srtLgs[lg].legNo,
                            },
                            {
                              text: srtLgs[lg].jnlBeing,
                            },
                            {
                              text: srtLgs[lg].legNote,
                            },
                            { text: drAmt, alignment: "right" },
                            { text: crAmt, alignment: "right" },
                          ]);
                        }
                      }

                      let tot = null;
                      let drBal = null;
                      let crBal = null;
                      if (drTot >= crTot) {
                        tot = drTot;
                        crBal = this.convAmt(drTot - crTot);
                        drBal = "";
                      }
                      if (drTot < crTot) {
                        tot = crTot;
                        drBal = this.convAmt(crTot - drTot);
                        crBal = "";
                      }

                      table.body.push([{ text: "Balance c/f", colSpan: 5, alignment: "right" }, {}, {}, {}, {}, { text: drBal, alignment: "right" }, { text: crBal, alignment: "right" }]);

                      table.body.push([{ text: "Totals", colSpan: 5, alignment: "right", bold: true, italics: true }, {}, {}, {}, {}, { text: this.convAmt(tot), alignment: "right", bold: true, italics: true }, { text: this.convAmt(tot), alignment: "right", bold: true, italics: true }]);

                      table.body.push([{ text: "Balance b/f", colSpan: 5, alignment: "right" }, {}, {}, {}, {}, { text: crBal, alignment: "right" }, { text: drBal, alignment: "right" }]);
                      console.log("pdfGenAcs txns srtLgs", srtLgs);
                      acsSec.push({ margin: [-20, 0, 0, 30], table });

                      let accNotes = types[t].categories[c].accounts[ac].accNotes;
                      if (accNotes) {
                        table.body.push([{ text: [{ text: "Account notes:\n", bold: true, color: "blue" }, { text: accNotes }], colSpan: 7 }, {}, {}, {}, {}, {}, {}]);
                      }
                    }
                    // console.log("pdfGenAcs txns", this.$store.getters.getAcTxns(types[t].categories[c].accounts[ac].accId));
                    // }
                    // acsSec.push({ text: "\n\n\n" });
                  }
                }
              }
            }
          }
        }
      }
      return acsSec;
    },
    lstPage() {
      let lstPage = [];

      lstPage.push({ text: "*** End of Financial Statements report ***", bold: true, margin: [40, 40, 20, 20], pageBreak: "before" });

      return lstPage;
    },
  },
};
