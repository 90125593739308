<template>
  <footer><p>&copy; TAccountZone 2022</p></footer>
</template>

<script>
export default {};
</script>

<style scoped>
footer {
  color: white;
  background-color: black;
  height: 2rem;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
}

p {
  font-size: 1rem;
  padding: 2px;
  margin-top: 2px;
}
</style>
