<template>
  <div>
    <h2>Balances</h2>
    <button :disabled="!ledgerId" @click="toggleStm('trial-bal')">Trial Bal</button>
    <button :disabled="!ledgerId" @click="toggleStm('bal-pl')">Bal Sheet, P&amp;L</button>
    <button @click="testB">Test</button>
    <br />
    <!-- <input type="checkbox" id="emptyAcs" name="emptyAcs" v-model="displayEmptyAcs" />
    <label for="emptyAcs">Show Empty Acs</label> -->
    <input :disabled="!ledgerId" type="checkbox" id="catNamesT" name="catNamesT" v-model="displayCatName" />
    <label for="catNamesT">Show Categories</label>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // displayEmptyAcs: false,
      displayCatName: false,
      hlJnlOnly: false,
    };
  },
  watch: {
    displayCatName(newVal) {
      this.$store.commit("setDisplCtrlTb_catName", newVal);
    },
  },
  computed: {
    displStm() {
      //   return this.$store.getters.getTrialBal;
      return this.$store.getters.getDisplCtrlTb_displStm;
    },
    ledgerId() {
      return this.$store.getters.getCurrLedgerId;
    },
  },

  methods: {
    showMaintainAcs() {
      this.showMaintAcs = !this.showMaintAcs;
    },
    toggleStm(stm) {
      this.$store.commit("setDisplCtrlTb_displStm", stm);
    },
    testB() {
      console.log("accounts", this.$store.getters.getAccounts);
    },
  },
};
</script>

<style scoped>
* {
  font-family: "Plus Jakarta Sans", sans-serif;
}
div {
  background-color: rgb(203, 225, 240);
  height: 10rem;
  width: inherit;
  font-size: 1.7rem;
  margin-bottom: 1rem;
  position: fixed;
}
button {
  font-size: 1.5rem;
  margin-left: 1rem;
  border-radius: 10px;
}

h2 {
  margin-left: 1rem;
}

input {
  margin-left: 1rem;
}
</style>
