<template>
  <div v-if="showCnstrctMsg == 'false'">
    <the-nav></the-nav>
    <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
    <router-view />
    <the-footer></the-footer>
  </div>
  <div class="cnstrctMsg" v-if="showCnstrctMsg == 'true' && pass == false">
    <h1>This app is currently under construction.</h1>
    <input type="text" id="secTxt" v-model.trim="frmData" placeholder="Email address" />
    <div>
      <button id="regInt" @click="proc">Register Interest</button>
    </div>
  </div>
  <div v-if="showCnstrctMsg == 'true' && pass == true">
    <the-nav></the-nav>
    <router-view />
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheNav from "@/components/TheNav.vue";
import TheFooter from "@/components/TheFooter.vue";
export default {
  components: { TheNav, TheFooter },
  data() {
    return {
      showCnstrctMsg: process.env.VUE_APP_CNSTRCT,
      frmData: "",
      sec: process.env.VUE_APP_CNSTRCT_PASS,
      pass: false,
    };
  },
  methods: {
    proc() {
      // console.log("this is the field val: ", this.frmData);
      if (this.frmData == this.sec) {
        this.pass = true;
      }
    },
  },
};
</script>

<style scoped>
.cnstrctMsg {
  margin-top: 3rem;
  margin-left: 3rem;
  font-size: 2.4rem;
  font-weight: bold;
}
</style>
