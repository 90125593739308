<template>
  <base-error :show="!!error" title="An error occurred!" @close="handleError">
    <p>{{ error }}</p>
  </base-error>
  <teleport to="body">
    <div @click="tryClose" class="backdrop"></div>
    <dialog open v-if="show" class="dialog">
      <header>
        <h2>Delete Ledger - {{ ledName }}</h2>
      </header>
      <div v-if="isProcessing" class="spinner">
        <base-spinner></base-spinner>
      </div>
      <section v-else-if="!isProcessing">
        <div class="msg">
          <p>
            Ledger <span class="bld">{{ ledName }}</span> contains <span class="ul blue bld">{{ jrnlsCount }} Journals</span> and <span class="ul blue bld">{{ acsCount }} Accounts .</span>.
          </p>
          <p>
            Clicking the <span class="bld">Delete Ledger</span> button below will <span class="bld red ul">PERMANENTLY DELETE</span> ledger <span class="bld">{{ ledName }}</span> along with all its <span class="blue bld">Journals</span>, <span class="blue bld">Types</span>, <span class="blue bld">Categories</span>, and <span class="blue bld">Accounts</span>.
          </p>
          <p class="red bld">YOU WILL BE UNABLE TO RECOVER THIS LEDGER ONCE DELETED!</p>
          <p>To activate the <span class="bld">Delete Ledger</span> button, please type the <span>exact name</span> of this ledger into the field below:</p>
          <div class="inp">
            <label class="bld" for="entLedNm">Delete Ledger:</label>
            <input class="entLedNm" type="text" name="entLedNm" v-model.trim="inpLedName" :placeholder="ledName" />
          </div>
        </div>
        <menu>
          <base-button :disabled="!okToDel" :class="{ btnPostYes: okToDel, btnPostNo: !okToDel }" type="button" @click="delLedger">Delete Ledger</base-button>
          <base-button type="button" @click="tryClose">Cancel</base-button>
        </menu>
      </section>
    </dialog>
  </teleport>
</template>

<script>
export default {
  emits: ["close"],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      frmNotes: "",
      isProcessing: false,
      inpLedName: "",

      error: null,
    };
  },
  computed: {
    okToDel() {
      return this.inpLedName == this.ledName;
      // return "test1" == "test1";
    },
    ledgerId() {
      return this.$store.getters.getCurrLedgerId;
    },
    ledName() {
      return this.$store.getters.getCurrLedgerName;
    },
    jrnlsCount() {
      // return this.$store.getters.getJournals.length || 0;
      let jrnls = this.$store.getters.getJournals;
      let c = 0;
      if (jrnls) c = jrnls.length;
      return c;
    },
    acsCount() {
      let acs = this.$store.getters.getAccounts;
      let c = 0;
      if (acs) c = acs.length;
      return c;
    },
  },
  methods: {
    tryClose() {
      this.$emit("close");
    },
    delLedger() {
      this.isProcessing = true;
      this.$store
        .dispatch("deleteLedger")
        .then(() => {
          this.isProcessing = false;
          this.tryClose();
        })

        .catch((error) => {
          console.log("error caught" + error);
          this.isProcessing = false;
          this.error = error;
          console.log("data: error: ", this.error);
        });
    },

    handleError() {
      this.error = null;
    },
  },
  // mounted() {
  //   this.frmNotes = this.ledNotes;
  //   console.log("frmNotes", this.frmNotes);
  // },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 10;
}
* {
  font-family: "Plus Jakarta Sans", sans-serif;
}
dialog {
  /* width: 80%;
  height: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -150px;
  margin-top: -150px; */
  position: fixed;
  top: 10vh;
  left: 20vh;
  margin: 0 auto;
  width: 60%;
  height: 80%;
  z-index: 90;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: auto;
  background-color: white;
  font-family: "Roboto Serif", serif;
}

header {
  background-color: #3a0061;
  color: white;
  width: 100%;
  padding: 1rem;
  position: sticky;
  top: 0;
}

.form-control {
  margin: 1rem;
}

.msg {
  margin-left: 2rem;
  margin-top: 2rem;
  font-size: 2rem;
}

.msg p {
  margin-top: 2rem;
}

.inp {
  margin-top: 2rem;
}

input {
  margin-left: 1.6rem;
}
.bld {
  font-weight: 900;
}

.red {
  color: red;
}

.blue {
  color: blue;
}

.ul {
  text-decoration: underline;
}

textarea {
  display: block;
  font-size: 2rem;
  width: 95%;
  /* width: 100%; */
  margin-left: 1rem;
  border: 1px solid #ccc;
  /* font: inherit; */
}

textarea:focus {
  background-color: #f0e6fd;
  outline: none;
  border-color: #3d008d;
}
menu {
  padding: 1rem;
  display: flex;
  /* justify-content: flex-end; */
  margin: 1;
  font-size: 2rem;
}
</style>
