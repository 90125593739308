<template>
  <div class="tb-list" id="tb-cntr">
    <!-- <div>
      <button @click="setSelectedTab('trial-bal')">Trial Bal</button>
      <button @click="setSelectedTab('bal-pl')">Bal Sht PL</button>
    </div> -->
    <component :is="selectedTab"></component>
  </div>
</template>

<script>
import TrialBal from "./TrialBal.vue";
import BalPl from "./BalPl.vue";
export default {
  components: {
    TrialBal,
    BalPl,
  },
  data() {
    return {
      // selectedTab: "trial-bal",
    };
  },
  computed: {
    selectedTab() {
      return this.$store.getters.getDisplCtrlTb_displStm;
    },
  },
  methods: {
    setSelectedTab(tab) {
      this.selectedTab = tab;
    },
  },
};
</script>

<style scoped>
.tb-list {
  background-color: rgb(235, 241, 247);
  /* overflow: scroll; */
  /* height: auto; */
  width: auto;
  /* margin-left: 2rem; */
  margin-top: 8rem;
  padding-top: 2rem;
  padding-bottom: 10rem;
  height: calc(100vh - 22rem);
  overflow: scroll;
  /* overflow-x: hidden; */
}

/* .tb-type {
  margin-left: 2rem;
} */
</style>
