<template>
  <template v-if="(!dispEmpAcs && dispCatName && (acNoDrLegs != 0 || acNoCrLegs != 0)) || (dispEmpAcs && dispCatName) || (!dispEmpAcs && !dispCatName && (acNoDrLegs != 0 || acNoCrLegs != 0)) || (dispEmpAcs && !dispCatName)">
    <section id="ac-card">
      <header class="AcTitle" @click="hlFromTBload(accId)">
        <p class="AcTitleTxt">
          <span class="AcTitleNo">{{ accNumber }} </span>: {{ accName }}
          <!-- <button @click="testA">Test</button> -->
        </p>
      </header>
      <div class="AcHorzLn"></div>

      <div class="allTxns">
        <div class="CntrDr leftScrl">
          <div
            v-for="(drleg, index) in debits"
            :key="drleg.legNo"
            :id="'leg_' + drleg.legId"
            :class="{
              active: hlFromJrnls(drleg.jnlId),
              hlDrLeg: hLightLeg(drleg.legId),
            }"
            @click="hlLeg(drleg.legDrCr, accId, drleg.legId)"
            @dblclick="hlFromTacs(drleg.jnlId, index, drleg.jnlNo)"
          >
            <!-- <div class="leg" v-if="leg.drCr==='D'"> -->
            <div class="leg" :class="{ exclLeg: drleg.jnlExcl && !showExclLeg, exclLegShw: drleg.jnlExcl && showExclLeg }">
              <span class="legDte">{{ convShrtDate(drleg.jnlDate) }}</span>
              <span class="legDescr AcTitleNo"
                ><span class="JnlNo" :class="{ exclLegShw: drleg.jnlExcl && showExclLeg }">{{ frmtJrnlNo(drleg.jnlNo) }}</span
                >/{{ drleg.legNo }}</span
              >
              <span class="legAmt" :class="{ exclLegShw: drleg.jnlExcl && showExclLeg }">{{ amt(drleg.legAmount) }}</span>
            </div>
          </div>
        </div>
        <div class="AcVrtcl"></div>
        <div class="CntrCr">
          <div
            v-for="crleg in credits"
            :key="crleg.legNo"
            :id="'leg_' + crleg.legId"
            :class="{
              active: hlFromJrnls(crleg.jnlId),
              hlCrLeg: hLightLeg(crleg.legId),
            }"
            @click="hlLeg(crleg.legDrCr, accId, crleg.legId)"
            @dblclick="hlFromTacs(crleg.jnlId, index, crleg.jnlNo)"
          >
            <!-- <div class="leg" v-if="leg.drCr==='C'"> -->
            <div class="leg" :class="{ exclLeg: crleg.jnlExcl && !showExclLeg, exclLegShw: crleg.jnlExcl && showExclLeg }">
              <span class="legDte">{{ convShrtDate(crleg.jnlDate) }}</span>
              <span class="legDescr AcTitleNo"
                ><span class="JnlNo" :class="{ exclLegShw: crleg.jnlExcl && showExclLeg }">{{ frmtJrnlNo(crleg.jnlNo) }}</span
                >/{{ crleg.legNo }}</span
              >
              <span class="legAmt" :class="{ exclLegShw: crleg.jnlExcl && showExclLeg }">{{ amt(crleg.legAmount) }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
</template>

<script>
import convAmt from "../../mixins/mixin.js";
export default {
  props: ["accId", "accNumber", "accName", "debits", "credits", "acNoDrLegs", "acNoCrLegs", "dispCatName", "dispEmpAcs"],
  mixins: [convAmt],
  data() {
    return {};
  },
  computed: {
    hlJnl() {
      return this.$store.getters.getHlJnlId;
    },
    frmtShrtDts() {
      return this.$store.getters.getCurrLedgerFrmtShrtDts;
    },
    legHl() {
      return this.$store.getters.getLegHl;
    },
    showExclLeg() {
      return this.$store.getters.getDisplCtrlAcs_shwExclLegs;
    },
  },
  methods: {
    testA() {
      console.log("debits", this.debits);
      console.log("credits", this.credits);
      this.$store.commit("sortAcLegs");
    },
    // hlLeg(sign, acId, legId) {
    //   console.log("leg data", sign, acId, legId);
    //   this.$store.commit("setLegHl", { accId: acId, sign: sign, legId: legId });
    // },
    hlLeg(sign, acId, legId) {
      if (this.legHl.legId == legId) {
        this.$store.commit("clearLegHl");
      } else {
        this.$store.commit("clearLegHl");

        this.$store.commit("setLegHl", { accId: acId, sign: sign, legId: legId });
      }
    },
    // unHlLeg() {
    //   console.log("left leg");
    //   this.$store.commit("clearLegHl");
    //   // this.hlDrLeg = false;
    //   // this.hlCrLeg = false;
    // },
    hLightLeg(legId) {
      if (this.legHl.legId) {
        if (this.legHl.legId == legId) {
          // document.getElementById("ac-card").scrollTop -= 10;
          console.log("hLighLeg", this.legHl.legId, legId);
          document.getElementsByClassName("tb-list acs-list").scrollTop -= 10;

          const id = "leg_" + legId;
          let el = document.getElementById(id);
          console.log("el", el);
          el.scrollIntoView({ behavior: "smooth", block: "center" });

          return true;
        }
      }
    },
    frmtJrnlNo(no) {
      return this.fillZero(no, 3);
    },
    convShrtDate(dte) {
      // Receives 16-08-22
      if (this.frmtShrtDts == 0) return dte;
      let day = dte.substring(0, 2);
      let mth = dte.substring(3, 5);
      let year = dte.substring(6);
      if (this.frmtShrtDts == 1) {
        // Generates 16-08-68
        console.log("day / mth / yr", day, mth, year);
        return mth + "-" + day + "-" + year;
      }
    },
    amt(amt) {
      // return amt.toLocaleString();
      return this.convAmt(amt);
    },

    hlFromJrnls(jnlId) {
      // const hlJnl = this.$store.getters.getHlJnlNo;
      // this.$store.commit('updateHlAcTB', null);
      //   console.log("tac debits", this.debits);
      //   console.log("TacAcs jnlid, hlJnl:", jnlId, hlJnl);
      return jnlId == this.hlJnl;
    },

    hlFromTBload(accId) {
      const hlAcTB = this.$store.getters.getHlTBac;
      if (hlAcTB == accId) {
        this.$store.commit("updateHlAcTB", null);
      } else {
        this.$store.commit("removeJnlNo");
        this.$store.commit("removeJnlAcIds");
        this.$store.commit("updateHlAcTB", accId);
      }
    },

    hlFromTacs(jnlId, index, no) {
      const currJnl = this.$store.getters.getHlJnlId;
      // console.log("jnlId, currJnl", jnlId, currJnl);
      if (currJnl == jnlId) {
        this.$store.commit("clearHlights");
        // this.$store.commit("removeJnlAcIds");
      } else {
        this.$store.commit("clearHlights");

        // Use the jnlId to get the index in the list of Jrnls:
        let idx = this.$store.getters.getJnlIdxById(jnlId);
        this.$store.commit("updateHlJnlIdx", idx);
        this.$store.commit("updateHlJnlNo", no);

        // Use the index to get its legs:
        let legs = this.$store.getters.getJournals[idx].legs;
        let acs = [];
        // this.$store.commit("updateHlJnlAcIds", acs);
        // this.$store.commit("updateJnlNo", null);
        // this.$store.commit("updateHlAcTB", null);
        // console.log('Tac leg clicked - jnlNo', jnlId, index);
        this.$store.commit("updateHlJnlId", jnlId); // update hLights.jnlNo
        // console.log('getJnlIdxByNo:', this.$store.getters.getJnlIdxByNo(jnlId));
        // let acs = [];
        console.log("HLights Journal index: ", index);
        // console.log('Hlights Journal legs: ', legs);
        legs.forEach(function (arrItem) {
          let x = arrItem.accId;
          acs.push(x);
        });
        // console.log('hLights push acs', acs);
        this.$store.commit("updateHlJnlAcIds", acs);
        // console.log('Hlights vuex: ', this.$store.getters.getHlights);
        const id = "jnl_" + jnlId;
        let el = document.getElementById(id);
        console.log("el", el);
        el.scrollIntoView();
      }
    },
  },
};
</script>

<style scoped>
.exclLeg {
  /* background-color: lightblue; */
  display: none;
}

.active {
  background-color: lightyellow;
}

.AcCntr {
  /* display: none; */
  width: 44rem;
  height: 20rem;
  /* min-height: 12rem; */
  /* max-height: 20rem; */
  margin-right: 2rem;
  margin-left: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  background-color: white;
  border-radius: 1.5rem;
  box-shadow: 3px 3px 3px #ccc;
  vertical-align: top;
  /* border: 2px solid lightgrey; */
}

.AcTitleTxt {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 3px;
  padding-top: 3px;
  border-radius: 1.5rem;
}
.AcTitle {
  /* width: 340px; */
  background-color: hsl(275, 43%, 95%);
}
.AcTitleNo {
  font-weight: bold;
}
.AcHorzLn {
  border-top: 2px solid green;
  height: 1px;
  /* width: 340px; */
}

.allTxns {
  display: flex;
  /* overflow: hidden; */
  /* min-height: 10rem; */
  /* max-height: 16rem; */
}

.CntrDr,
.CntrCr {
  /* width: 170px; */
  overflow: auto;
  overflow-x: hidden;
  width: 50%;
  height: 16rem;
  /* min-height: 10rem; */
  /* max-height: 16rem; */
}
.leftScrl {
  direction: rtl;
}
/* Designing for scroll-bar */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: gainsboro;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(172, 170, 170);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.hlighted {
  background-color: rgb(201, 238, 201);
}
.leg {
  position: relative;
  font-family: monospace;
  font-size: 12px;
  padding: 3px;
  direction: ltr;
}

.leg .legAmt {
  float: right;
}

.legDescr {
  margin-left: 5px;
}
.inline {
  display: inline;
}
.JnlNo {
  font-weight: bold;
  color: green;
  margin-left: 0.9rem;
}
.exclLegShw {
  /* background-color: lightpink; */
  color: red;
  text-decoration: line-through;
  font-style: italic;
}
.AcVrtcl {
  border-left: 2px solid green;
  /* height: 80px; */
  width: 1px;
  left: 50%;
  height: 16rem;
  /* min-height: 10rem; */
  /* max-height: 16rem; */
}
.hlDrLeg {
  background-color: lightgreen;
}

.hlCrLeg {
  background-color: lightpink;
}
</style>
