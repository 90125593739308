<template>
  <h1>Welcome to TAccountZone.com</h1>
  <h2>The Web App for visualizing double-entry accounting transactions.</h2>
  <section>
    <p>Features:</p>
    <ul>
      <li>Create multiple self-contained accounting <span>Ledgers</span> and easily switch between them.</li>
      <li>Post accounting <span>Journals</span> within a ledger and see their effects on <span>T-Accounts</span> and <span>Balances</span></li>
      <li>Generate a <span>Financial Statements</span> report at any time.</li>
    </ul>

    <p>Think of TAccountZone.com as a ‘workbench’ for double-entry accounting transactions.</p>
    <p>You may be interested in signing up for this app if you are:</p>
    <ul>
      <li>involved in education as a teacher or student and need to understand and practice double-entry accounting.</li>
      <li>working in the Finance / FinTech sector and need to model accounting entries for various purposes.</li>
      <li>using a spreadsheet to create accounts for small entities and would like an easier, more robust solution.</li>
    </ul>

    <p>The ‘demo’ ledgers contain double-entry accounting transactions for a variety of business scenarios, all intended to showcase the app.</p>
    <p>In demo mode, you can create journals and accounts, and view the ‘Financial Statements’ report - but your changes will not be saved.</p>
    <p>Please consider signing up to create your own ledgers and save accounts and journals within them.</p>
    <p>Lastly, we welcome all comments and suggestions - please use Twittter / Facebook, or email us at support@TAccountZone.com</p>
    <p>The TAccountZone.com team.</p>
  </section>
</template>

<style scoped>
* {
  font-family: "Roboto", sans-serif;
}

h1 {
  font-size: 4rem;
  text-align: center;
}
h2 {
  font-size: 2.5rem;
  text-align: center;
}
section {
  margin: 3rem 4rem;
  font-size: 2rem;
}
span {
  font-weight: bold;
}
</style>
