export default {
  computed: {
    noDecPl() {
      return this.$store.getters.getCurrLedgerBaseCurrNoDec;
    },
    authUserId() {
      return this.$store.getters.getAuthUserId;
    },
    authLedId() {
      return this.$store.getters.getAuthCurrLedId;
    },
  },
  methods: {
    // context.commit("setAuthCurrLedId", resData.newId);
    loadUlData() {
      //   const ledId = this.$store.getters.getCurrLedgerId;
      //   const uid = this.$store.getters.getUserId;
      const uid = this.authUserId || 1;
      if (!this.authUserId) {
        this.$store.commit("setAuthUserId", uid);
      }

      const ledId = this.authLedId || 1;
      if (!this.authLedId) {
        this.$store.commit("setAuthCurrLedId", ledId);
      }
      // console.log('mount uldata ledgers', ledId, uid);
      this.$store.dispatch("loadLedgerData", {
        userId: uid,
        ledId: ledId,
      });
    },
    convAmt(number) {
      //   console.log('mixin convAmt', number, this.noDecPl);
      if (this.noDecPl == 2) {
        if (number == 0) {
          return "0.00";
        }
        let num = number / 100;
        num = num.toFixed(2);
        return this.noThouSep(num);
      }

      if (this.noDecPl == 0) {
        if (number == 0) {
          return "0";
        }
        let num = number;
        num = num.toFixed(0);
        return this.noThouSep(num);
      }

      if (this.noDecPl == 3) {
        if (number == 0) {
          return "0.000";
        }
        let num = number / 1000;
        num = num.toFixed(3);
        return this.noThouSep(num);
      }

      return number;
    },

    noThouSep(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    fillZero(n, w) {
      let str = String(n);
      for (let i = str.length; i < w; i++) {
        str = "0" + str;
      }
      return str;
    },
    frmtJrnlNo(no) {
      return this.fillZero(no, 3);
    },
    frmtDate() {
      let d = new Date();
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const mths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      let day = days[d.getDate()];
      let mth = mths[d.getMonth()];
      let date = day + "," + d.getDate() + " " + mth + " " + d.getFullYear();
      return date;
    },
    computeAmt(amt, mode) {
      // return amt.toLocaleString();
      if (mode == "C") {
        if (amt <= 0) {
          let amt_1 = amt * -1;
          return this.convAmt(amt_1);
        }
        if (amt > 0) {
          let amt_1 = "(" + this.convAmt(amt) + ")";
          return amt_1;
        }
      }
      if (mode == "D") {
        if (amt < 0) {
          let amt_1 = amt * -1;
          amt_1 = "(" + this.convAmt(amt_1) + ")";
          return amt_1;
        }
        if (amt >= 0) {
          return this.convAmt(amt);
        }
      }

      if (amt < 0) {
        let amt_1 = amt * -1;
        amt_1 = "(" + this.convAmt(amt_1) + ")";
        return amt_1;
      }
      return this.convAmt(amt);
    },
    stripText(txt) {
      let newVal = txt;
      if (txt) {
        newVal = newVal.replace(/[\\]/g, "\\\\"); // Escape backslash
        newVal = newVal.replace(/'/g, "''"); // Replace one single quote with two single quotes.
        // newVal = newVal.replace(/"/g, ""); // Get rid of "
        newVal = newVal.replace(/\//g, "\\/"); // Escape forward slash.
        newVal = newVal.replace(/\n/g, "\\n"); // Escape newline.
        // newVal = newVal.replace(/[\n]/g, "\\n");

        // newVal = newVal.replace(/[/]/g, "\\/"); // Escape forward slash
        newVal = newVal.replace(/[\b]/g, "\\b"); // Escape backspace
        newVal = newVal.replace(/[\f]/g, "\\f"); // Escape formfeed
        // // newVal = newVal.replace(/[\r]/g, "\\r"); // Escape carriage return
        newVal = newVal.replace(/[\t]/g, "\\t"); // Escape tab
        newVal = newVal.replace(/["]/g, '\\"'); // Escape double quotes
        // // newVal = newVal.replace(/\\'/g, "\\'"); // Escape single quotes
      }
      if (!txt) newVal = "";
      return newVal;
    },
    isValidDate(dt) {
      let year = dt.substr(0, 4);
      let month = dt.substr(5, 2);
      let day = dt.substr(8, 2);
      console.log("year / month / day", year, month, day);
      const date = new Date(year, +month - 1, day);
      const isValidDate = Boolean(+date) && date.getDate() == day;
      return isValidDate;
    },
  },
};
