<template>
  <tr
    class="jrnls"
    @click="hlLeg(sign, accId, legId)"
    :id="'jnlLeg_' + legId"
    :class="{
      hlDrLeg: hlDrLeg(sign, legId),
      hlCrLeg: hlCrLeg(sign, legId),
    }"
  >
    <td>{{ legNo }}</td>
    <td width="180px">
      <span class="acNm">{{ getAcNameNo(typeId, catId, accId) }}</span
      ><br />{{ acNo }}<br /><span class="lgNt">{{ legNote }}</span>
    </td>
    <td width="130px" class="amt">{{ drAmt }}</td>
    <td width="130px" class="amt">{{ crAmt }}</td>
  </tr>
</template>

<script>
// import convAmt from "../../../mixins/convAmt.js";
import mixin from "@/mixins/mixin.js";
export default {
  // props: ['legNo', 'accNum', 'accName', 'amount', 'sign'],
  props: ["legNo", "accCat", "accId", "legId", "legNote", "amount", "sign", "catId", "typeId"],
  mixins: [mixin],
  data() {
    return {
      //   allActs: this.$store.getters.getTaccounts
      acNo: "",
      acName: "",
    };
  },
  computed: {
    currAmt() {
      return this.convAmt(this.amount);
    },
    drAmt() {
      if (this.sign === "D") {
        // this.convAmt(777);
        // let amt = this.convAmt(this.amount);
        // return amt.toLocaleString();
        // return this.convAmt(this.amount).toLocaleString();
        // console.log('JournalSec JournalLeg drAmt', this.currAmt);
        return this.currAmt;
      }
      return "";
    },
    crAmt() {
      if (this.sign === "C") {
        // return this.amount.toLocaleString();
        // console.log('JournalSec JournalLeg drAmt', this.currAmt);

        return this.currAmt;
      }
      return "";
    },
    allActs() {
      return this.$store.getters.getTypes;
    },
    legHl() {
      return this.$store.getters.getLegHl;
    },
  },
  created() {},
  methods: {
    hlLeg(sign, acId, legId) {
      if (this.legHl.legId == legId) {
        this.$store.commit("clearLegHl");
      } else {
        this.$store.commit("clearLegHl");

        this.$store.commit("setLegHl", { accId: acId, sign: sign, legId: legId });
      }
    },
    unHlLeg() {
      console.log("left leg");
      this.$store.commit("clearLegHl");
      // this.hlDrLeg = false;
      // this.hlCrLeg = false;
    },
    hlDrLeg(sign, legId) {
      if (sign == "D" && this.legHl.legId == legId) {
        // const id = "jnlLeg_" + legId;
        // let el = document.getElementById(id);
        // console.log("el", el);
        // el.scrollIntoView();
        return true;
      }
    },
    hlCrLeg(sign, legId) {
      if (sign == "C" && this.legHl.legId == legId) {
        // const id = "jnlLeg_" + legId;
        // let el = document.getElementById(id);
        // console.log("el", el);
        // el.scrollIntoView();
        return true;
      }
    },

    // TODO: Ok, but is this reactive?
    getAcNameNo(typeId, catId, acId) {
      //   console.log("args", typeId, catId, acId);
      //   console.log("allActs", this.allActs);
      for (let t of this.allActs) {
        if (t.typeId == typeId) {
          if (t.categories != null) {
            for (let c of t.categories) {
              if (c.catId == catId) {
                if (c.accounts != null) {
                  for (let a of c.accounts) {
                    if (a.accId == acId) {
                      this.acNo = a.accNumber;
                      //   console.log("ac found", a.accNumber, a.accName);
                      return a.accName;
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.hlDrLeg {
  background-color: lightgreen;
}

.hlCrLeg {
  background-color: lightpink;
}
td {
  border: 1px solid #999;
  padding: 0.5rem;
  text-align: left;
  padding: 4px;
  font-family: "Courier New", Courier, monospace;
  font-size: 1.2rem;
}
.acNm {
  font-weight: 900;
}

.amt {
  text-align: right;
}

.lgNt {
  color: purple;
  font-family: "Roboto serif", serif;
  font-size: 1.2rem;
  font-weight: 500;
}
</style>
