<template>
  <tr :id="'jnl_lg_' + id">
    <td>{{ id }}</td>
    <td :id="'lg_ac_' + id">
      <leg-account tabindex="1" :value="accId" :id="'acs_' + id" @input="handleInput('accId', $event)"></leg-account>
    </td>
    <td>
      <input tabindex="2" :id="'lg_nt_' + id" type="text" :value="note" size="30" ref="input" @input="handleInput('legNote', $event)" />
    </td>
    <td>
      <input tabindex="3" :id="'lg_dr_' + id" class="amt" type="text" ref="input" :value="drAmt" :disabled="isDisabledDr" @focus="validate('drAmt', $event)" @input="validate('drAmt', $event)" @blur="formatAmt('drAmt', $event)" />
    </td>
    <td>
      <input tabindex="4" :id="'lg_cr_' + id" class="amt" type="text" :value="crAmt" :disabled="isDisabledCr" @focus="validate('crAmt', $event)" @input="validate('crAmt', $event)" @blur="formatAmt('crAmt', $event)" />
    </td>

    <td>
      <button tabindex="5" v-if="index > 1" @click="removeLeg">Remove</button>
    </td>
    <td><button @click="TestA">TestA</button></td>
  </tr>
</template>

<script>
import LegAccount from "./LegAccount.vue";
export default {
  components: { LegAccount },
  props: ["accId", "cat", "note", "drAmt", "crAmt", "id", "index"],
  emits: ["remove-leg", "update-leg", "format-amt"],
  data: function () {
    return {
      // acc1: this.acc,
      // cat1: this.cat,
      // note1: this.note,
      // drAmt1: this.drAmt,
      // crAmt1: this.crAmt,
      isDisabledDr: false,
      isDisabledCr: false,
    };
  },
  watch: {
    selAcForJrnl: function (newVal) {
      console.log("newVal, index", newVal, this.index);
      if (newVal != null) {
        this.handleInput("accId", newVal);
      }
    },
  },
  computed: {
    noDecPl() {
      return this.$store.getters.getCurrLedgerBaseCurrNoDec;
    },
    legAmtVal() {
      let regNum = /[^0-9]/g;
      let val = 0;
      if (this.drAmt != 0) {
        val = this.drAmt;
      }
      if (this.crAmt != 0) {
        val = this.crAmt;
      }
      console.log({ val });
      if (val != 0) {
        let val1 = val.replace(regNum, "");
        return val1;
      } else {
        let val1 = 0;
        return val1;
      }
    },
    selAcForJrnl() {
      return this.$store.getters.getSelAcForJrnl;
    },
    // isDisabledDr() {
    //   if (this.crAmt != '0') {
    //     return true;
    //   }
    //   if (this.crAmt != '0.00') {
    //     return true;
    //   }
    //   return false;
    // },
    // isDisabledCr() {
    //   if (this.drAmt != '0') {
    //     return true;
    //   }
    //   if (this.drAmt != '0.00') {
    //     return true;
    //   }
    //   return false;
    // }
  },
  methods: {
    // Used to focus the input from the parent
    // focus: function() {
    //   this.$refs.input.focus();
    // },
    // blur: function() {
    //   this.$refs.input.blur();
    // },

    testA() {
      console.log("type", this.typId);
      console.log("accId", this.accId);
    },
    removeLeg: function () {
      this.$emit("remove-leg", this.index);
    },

    handleInput: function (prop, e) {
      //   console.log("type", this.typId);

      if (prop == "accId" && this.selAcForJrnl) {
        console.log("ac has been selected, index", this.selAcForJrnl, this.index);
        this.$emit("update-leg", {
          index: this.index,
          prop,
          value: this.selAcForJrnl,
        });
        this.$store.commit("setSelAcForJrnl", null);
      } else {
        console.log("handleInput", prop, e.target.value);
        this.$emit("update-leg", {
          index: this.index,
          prop,
          value: e.target.value,
        });
      }

      console.log("TargetValue", e);
    },
    validate: function (prop, e) {
      // console.log('Curr Dec pl', this.noDecPl);
      // Allow only numerics or decimal in text field
      let regNum = /[^0-9.]/g;
      // let regNum = /^((\d|[1-9]\d+)(\.\d{1,2})?|\.\d{1,2})$/;
      // let regNum = /^\D+(\.\D\D)?$/;
      e.target.value = e.target.value.replace(regNum, "");

      // Disallow more than one decimal place
      if (e.target.value.split(".").length > 2) e.target.value = e.target.value.replace(/\.+$/, "");

      if (this.noDecPl == 0) {
        let regex = /\./;
        // console.log({ regex });
        e.target.value = e.target.value.replace(regex, "");
      }

      // An || in the if condition does not work for the next to if statements for some reason.

      if (this.noDecPl == 2) {
        let t = e.target.value;
        // console.log({ t });

        // Enter only 2 decimal places
        e.target.value = t.indexOf(".") >= 0 ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3) : t;
      }

      if (this.noDecPl == 3) {
        let t = e.target.value;
        // console.log({ t });

        e.target.value = t.indexOf(".") >= 0 ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 4) : t;
      }
      // console.log('drAmt', this.drAmt.value);

      // this.$emit('update-legamt', this.legAmtVal);
      // this.$emit('update-leg', {
      //   index: this.index,
      //   prop,
      //   value: e.target.value
      // });
    },

    formatAmt(prop, evt) {
      console.log("formatAmt", evt.target.value);
      let finalAmt = evt.target.value;
      if (this.noDecPl == 0) {
        finalAmt = evt.target.value;
      }
      if (this.noDecPl == 2) {
        finalAmt = evt.target.value * 100;
      }
      if (this.noDecPl == 3) {
        finalAmt = evt.target.value * 1000;
      }
      console.log({ finalAmt });
      finalAmt = this.noThouSep(evt.target.value);
      console.log({ finalAmt });
      if (finalAmt != "0.00" && prop == "drAmt") {
        console.log("finalAmt", finalAmt, prop);
        this.isDisabledDr = false;
        this.isDisabledCr = true;
      }
      if (finalAmt == "0.00" && prop == "drAmt") {
        console.log("finalAmt", finalAmt, prop);
        this.isDisabledDr = false;
        this.isDisabledCr = false;
      }

      if (finalAmt != "0.00" && prop == "crAmt") {
        console.log("finalAmt", finalAmt, prop);
        this.isDisabledDr = true;
        this.isDisabledCr = false;
      }
      if (finalAmt == "0.00" && prop == "crAmt") {
        console.log("finalAmt", finalAmt, prop);
        this.isDisabledDr = false;
        this.isDisabledCr = false;
      }
      this.$emit("update-leg", {
        index: this.index,
        prop,
        value: finalAmt,
        legAmtVal: finalAmt * 100,
      });
      // this.handleInput('legAmt', evt);
    },

    noThouSep(num) {
      console.log("num", num);
      let temp = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      console.log("temp", temp);
      if (temp.indexOf(".") == -1) {
        temp = temp + ".00";
      }
      return temp;
    },
  },
};
</script>
<style scoped>
td {
  border: 1px solid #999;
  padding: 0.5ren;
  text-align: left;
  font-size: 1.7rem;
}

.dtAmt {
  text-align: right;
}

.amt {
  text-align: right;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
