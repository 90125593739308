<template>
  <select @change="change">
    <option>&lt;---Maintain Accounts---&gt;</option>
    <!-- <template v-for="type in types" :key="type.typeId"> -->
    <optgroup class="optgroup" v-for="cat in categories" :key="cat.catId" :label="cat.catName">
      <option v-for="ac in cat.accounts" :key="ac.accId" :value="ac.accId" :catAc="cat.catId">{{ ac.accNumber }} - {{ ac.accName }} : {{ balAmt(ac.accBal) }}</option>
    </optgroup>
    <!-- </template> -->
  </select>
  <!-- <select id="accounts">
    <optgroup label="Swedish Cars">
      <option value="volvo">Volvo</option>
      <option value="saab">Saab</option>
    </optgroup>
  </select> -->
  <!-- <select id="accounts">
    <option v-for="type in types" :key="type.typeId">{{ type.typeName }}</option>
  </select> -->
</template>

<script>
//TODO: Need to have an option group showing Category Names.
import mixin from "@/mixins/mixin.js";
export default {
  mixins: [mixin],
  data: function () {
    return {
      selectedAc: {},
    };
  },
  computed: {
    types() {
      // return this.$store.getters.getCatAcs;
      return this.$store.getters.getTypes;
    },
    categories() {
      return this.$store.getters.getCategories;
    },
  },
  methods: {
    ctest(val) {
      console.log("catid", val);
    },
    change() {
      console.log("selection changed", this.selectedAc.ac);
    },
    balAmt(amt) {
      let val = this.convAmt(amt);
      if (amt > 0) {
        val = "Dr. " + val;
      }

      if (amt < 0) {
        val = val.substring(1); // remove the minus
        val = "Cr. " + val;
      }
      return val;
    },
  },
};
</script>

<style scoped>
/* option .sp {
  color: red;
} */

select {
  width: 20rem;
}
optgroup {
  color: blue;
}
option {
  color: black;
}
</style>
