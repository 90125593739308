<template>
  <div id="app">
    <!-- <TheNav /> -->
    <TheLedger />
    <Splitpanes class="default-theme" style="height: calc(100vh - 10rem)">
      <Pane class="jrnl-pane" size="30">
        <JrnlHeader />
        <JrnlList />
      </Pane>
      <Pane class="acs-pane" size="40">
        <AcsHeader />
        <AcsList />
      </Pane>
      <Pane class="tb-pane" size="30">
        <TbHeader />
        <TbContainer />
      </Pane>
    </Splitpanes>
    <!-- <TheFooter /> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import TheNav from "@/components/TheNav.vue";
import TheLedger from "@/components/1_Ledger/TheLedger.vue";
// import TheFooter from "@/components/TheFooter.vue";
import JrnlHeader from "@/components/2_Journals/JrnlHeader.vue";
import JrnlList from "@/components/2_Journals/JrnlList.vue";
import AcsHeader from "@/components/3_Accounts/AcsHeader.vue";
import AcsList from "@/components/3_Accounts/AcsList.vue";
import TbHeader from "@/components/4_Balances/TbHeader.vue";
import TbContainer from "@/components/4_Balances/TbContainer.vue";

import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import mixin from "@/mixins/mixin.js";
export default {
  name: "LedgerView",
  mixins: [mixin],
  components: {
    // TheNav,
    TheLedger,
    // TheFooter,
    Splitpanes,
    Pane,
    JrnlHeader,
    JrnlList,
    AcsHeader,
    AcsList,
    TbHeader,
    TbContainer,
  },
  computed: {
    isUlDataLoading() {
      return this.$store.getters.getUlDataLoading;
    },
  },
  // created() {
  //   this.loadUlData();
  // },
  mounted() {
    // this.loadUlData(); // mixin.js
    this.loadUlData();
  },
};
</script>

<style scoped>
/* .jrnl-pane { */
/* overflow: scroll; */
/* overflow-x: hidden; */
/* } */

/* .jrnl-pane:first-child {
  background-color: blue;
} */

/* .acs-pane {
  overflow: scroll;
  overflow-x: hidden;
} */

/* .acs-pane:first-child {
  background-color: blue;
} */

/* .tb-pane {
  overflow: scroll;
  overflow-x: hidden;
} */
</style>
