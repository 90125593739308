<template>
  <nav>
    <!-- <h1><a>T-Account Zone</a></h1> -->
    <log-out v-if="showLogout" :show="showLogout" @close="showLogoutMsg"></log-out>

    <h1>
      <router-link :to="{ name: 'home' }"><img src="../assets/images/Nav_Logo_1.png" alt="" /></router-link>
    </h1>
    <!-- <ul>
      <li>
        <a><input type="checkbox" id="nav_isSup" name="isSup" value="isSup" @click="toggleSup" /> <label for="isSup">Supervisor</label></a>
      </li>
    </ul> -->
    <div class="selUser" v-if="isSup">
      <label class="selLbl" for="userList">Select User: </label>
      <select id="nav_userList" class="selDrp" :value="selectedUser || 0" @input="selectedUserOutput($event)">
        <option value="0">&lt;------------&gt;</option>
        <option class="selOpt" v-for="user in userList" :key="user.id" :value="user.id" :email="user.email" :led="user.curr_ledger_id">{{ user.username }}</option>
      </select>
      <!-- <base-button btnStyle="flat" @click="pdfgen">PDF</base-button> -->
      <!-- <base-button btnStyle="flat" @click="this.pdfgen">PDF</base-button> -->
      <!-- <ul>
        <li>
          <a><input type="checkbox" id="nav_loggedIn" name="loggedIn" value="loggedIn" @click="toggleLogIn" /> <label for="loggedIn">Logged In</label></a>
        </li>
      </ul> -->
    </div>
    <ul>
      <router-link :to="{ name: 'home' }">Home</router-link>
      <router-link :to="{ name: 'ledger' }" id="nav_mnu_led">Ledger</router-link>
      <router-link v-if="isSup && isLoggedIn" :to="{ name: 'Users' }">Users</router-link>
      <router-link :to="{ name: 'faq' }">FAQs</router-link>
      <router-link :to="{ name: 'support' }">Support</router-link>
      <router-link v-if="!isLoggedIn" :to="{ name: 'Signup' }">Sign Up</router-link>
      <router-link v-if="!isLoggedIn" :to="{ name: 'Login' }">Login</router-link>
      <!-- <router-link v-if="isLoggedIn" @click="logout" :to="{ name: 'ledger' }">Logout:</router-link> -->
      <li class="logout" v-if="isLoggedIn" @click="showLogoutMsg">Logout:</li>
      <li class="usrName">{{ userName }}</li>
    </ul>
  </nav>
</template>

<script>
import mixin from "@/mixins/mixin.js";
import LogOut from "@/components/0_Auth/LogOut.vue";
export default {
  mixins: [mixin],
  components: { LogOut },
  data() {
    return {
      // loggedIn: false,
      showLogout: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.getters.getIsLoggedIn;
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    userName() {
      return this.$store.getters.getUserName;
    },
    userList() {
      // console.log("userlist", this.$store.getters.getUserList);
      return this.$store.getters.getUserList;
    },
    selectedUser() {
      return this.$store.getters.getAuthUserId;
    },
    isSup() {
      return this.$store.getters.getIsSup;
    },
  },
  methods: {
    // toggleLogIn() {
    //   this.$store.commit("setIsLoggedIn");
    //   // this.loggedIn = !this.loggedIn;
    //   console.log(this.loggedIn);
    // },
    // toggleSup() {
    //   this.$store.commit("setIsSup");
    //   // this.$store.dispatch("loadUsers");
    //   console.log("isSupervisor", this.isSup);
    // },
    // login() {},
    logout() {
      this.$store.dispatch("logout").then((usr) => {
        console.log("logout selected", usr);
        // this.$router.replace({ name: "ledger" });
      });
      // this.$store.dispatch("logout").then(() => {
      //   console.log("hello from logout", this.$store.getters.getAuthUserId, this.$store.getters.getAuthCurrLedId);
      //   this.router.push({ name: "ledger" });
      //   this.loadUlData;
      // });
      // // this.$store.dispatch('loadLedgers');
      // this.$store.dispatch('loadJournals');
      // this.$store.dispatch('loadTaccounts');
    },
    showLogoutMsg() {
      this.showLogout = !this.showLogout;
    },
    selectedUserOutput(event) {
      let selIdx = event.target.options[event.target.options.selectedIndex];
      // this.$store.commit("setAuthCurrLedId", event.target.options[event.target.options.selectedIndex].value);
      // console.log("event.target", event);
      // console.log("selIdx", selIdx, selIdx.value, selIdx.attributes, selIdx.attributes.email.value);
      // console.log("selIdx attributes", selIdx.attributes, selIdx.attributes.email.value);
      this.$store.commit("setAuthUserId", parseInt(selIdx.value));
      this.$store.commit("setAuthCurrLedId", selIdx.attributes.led.value);
      // Clear Hlights:
      // this.$store.commit("clearHlights");

      // if (selIdx.value == 0) {
      //   console.log("deleting the ledger");
      //   this.$store.commit("delLedger");
      // }

      if (selIdx.value != 0) {
        this.loadUlData(); // from mixin
        console.log("ulData", this.$store.getters.getUlData);
      }
    },
  },
};
</script>

<style scoped>
/* nav {
  height: 4rem;
  background-color: lightgray;
  font-family: "Roboto serif", serif;
  font-size: 2rem;
}

p {
  display: inline-block;
}

*/
input {
  margin-left: 4rem;
  zoom: 1.5;
}

label {
  margin-left: 1rem;
  margin-right: 4rem;
}
/* header {
  top: 0;
  width: 100%;
  height: 50px;
  background-color: #3d008d;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: sticky;
  flex: none;
} */
nav {
  width: 100%;
  height: 4rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0b006a;
  /* display: flex; */
  /* overflow: hidden; */
  /* position: sticky; */
  flex: none;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.hlTxt {
  color: yellow;
}

a {
  text-decoration: none;
  color: white;
  display: inline-block;
  padding: 0.2rem 1.5rem;
  border: 1px solid transparent;
  font-size: 2rem;
  /* position: static; */
}

.logout {
  color: white;
  display: inline-block;
  padding: 0.2rem 1.5rem;
  border: 1px solid transparent;
  font-size: 2rem;
}

.logout:hover {
  border: 1px solid #50d8d7;
}
a:active,
a:hover,
a.router-link-active {
  /* border: 1px solid #f391e3; */
  /* border: 1px solid #3311a8; */
  border: 1px solid #50d8d7;
}

h1 {
  margin: 0;
  color: white;
}

h1 a {
  color: white;
  margin: 0;
}

h1 a:hover,
h1 a:active,
h1 a.router-link-active {
  border-color: transparent;
}

.usrName {
  color: yellow;
  font-size: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
h2 {
  color: lightblue;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

li {
  margin: 0 0.5rem;
  color: yellow;
}
.selUser {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-weight: 500;
  font-size: 1.6rem;
}
.selLbl {
  width: 10rem;
  /* height: 1.6rem; */
  color: white;
  font-weight: bold;
  padding-top: 3px;
}
.selDrp {
  font-size: 1.4rem;
  width: 25rem;
  height: 1.6rem;
}
</style>
