<template>
  <base-error :show="!!error" title="An error occurred!" @close="handleError">
    <p>{{ error }}</p>
  </base-error>
  <section>
    <div>
      <button @click="getUsers">Get Users</button>
    </div>

    <div>
      <table>
        <thead>
          <tr>
            <th>id</th>
            <th>username</th>
            <th>email</th>
            <th>password</th>
            <th>currLedger</th>
            <th>status</th>
            <th>note</th>
            <th>active</th>
            <th>supervisor</th>
            <th>created</th>
            <th>lastUpdate</th>
          </tr>
        </thead>
        <tbody v-for="user in userList" :key="user.id">
          <tr>
            <td>{{ user.id }}</td>
            <td>{{ user.username }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.password }}</td>
            <td>{{ user.curr_ledger_id }}</td>
            <td>{{ user.status }}</td>
            <td>{{ user.note }}</td>
            <td>{{ user.active }}</td>
            <td>{{ user.supervisor }}</td>
            <td>{{ user.created }}</td>
            <td>{{ user.last_update }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <h2>*** End of User List</h2>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  computed: {
    userList() {
      //   return this.$store.getters.getTrialBal;
      return this.$store.getters.getUserList;
    },
    isSupervisor() {
      return this.$store.getters.getIsSup;
    },
    isLoggedIn() {
      return this.$store.getters.getIsLoggedIn;
    },
  },
  data() {
    return {
      error: null,
    };
  },
  methods: {
    handleError() {
      this.error = null;
    },
    getUsers() {
      if (this.isLoggedIn && this.isSupervisor) {
        this.$store.dispatch("loadUsers").catch((error) => {
          this.error = error;
          console.log("user fetch error", this.error);
        });
      }
    },
  },
};
</script>

<style scoped>
section {
  margin-left: 5rem;
  margin-top: 5rem;
}
.tbCntr {
  overflow: auto;
  height: calc(100vh - 250px);
}

table {
  border-collapse: collapse;
  /* table-layout: fixed; */
  /* width: 50rem; */
}

td,
th {
  border: 1px solid #999;
  /* padding-right: 1rem; */
  text-align: left;
  padding: 0.5rem;
  font-family: "Courier New", Courier, monospace;
  font-weight: 900;
  font-size: 2rem;
}
button {
  margin-bottom: 3rem;
  font-size: 3rem;
}
h2 {
  margin-top: 2rem;
  font-size: 3rem;
  color: blue;
}
</style>
