import { createStore } from "vuex";

export default createStore({
  state: {
    uldata: {
      uldata: {
        ledgers: [],
        currLedger: {
          details: {
            calc_total_dr_bal: 0,
            calc_total_cr_bal: 0,
          },
          journals: [],
          types: [],
        },
      },
    },
    auth: {
      token: null,
      tokenExpiration: null,
      userId: 0,
      currLedgerId: 0,
      userName: "DEMO",
      active: null,
      supervisor: false,
      isLoggedIn: false,
      // isSuper: false,
      isActive: null,
      demoUserId: 1,
      demoCurrLedgerId: 1,
    },
    users: [],
    ui: {
      isUlDataLoading: false,
      isJrnlPosting: false,
      error: null, // uldata
      errorShrtUpd: null,
      isEditingLedger: false,
      isInsertingLedger: false,
      selAcForJrnl: null,
    },
    auto: {
      retrieveLedsAfterInsert: true,
    },
    hLights: {
      jnlIdx: null,
      jnlId: null,
      jnlNo: null,
      jnlAcIds: [],
      TBac: null,
    },
    legHl: {
      accId: null,
      sign: null,
      legId: null,
    },
    displCntrl: {
      acs: {
        displayEmpAcs: false,
        displayCatName: false,
        hlJnlOnly: false,
        shwExclLegs: false,
      },
      tb: { displayCatName: false, displStm: "trial-bal" },
    },
    new: {
      catId: null,
      acId: null,
      jnlId: null,
    },
    workJournal: {
      jrnlMode: "",
      startJrnl: null,
    },
  },
  getters: {
    getState(state) {
      return state;
    },
    getUserList(state) {
      return state.users;
    },
    getIsSup(state) {
      return state.auth.supervisor;
    },
    isAuthenticated(state) {
      return !!state.auth.token; // Convert to a boolean; true if token exists.
    },
    token(state) {
      return state.auth.token;
    },
    getLegHl(state) {
      return state.legHl;
    },
    getRetrieveLedsAfterInsert(state) {
      return state.auto.retrieveLedsAfterInsert;
    },
    getWrkJrnlMode(state) {
      return state.workJournal.jrnlMode;
    },
    getWorkJournal(state) {
      console.log("state workjournal", state.workJournal);
      return state.workJournal.startJrnl;
    },
    getWorkJournal_1(state) {
      return state.workJournal;
    },
    getLastJournalNo(state, getters) {
      const jrnls = getters.getJournals;
      let no = 0;

      if (jrnls) {
        if (jrnls.length != 0) {
          no = jrnls[jrnls.length - 1].jnlNo;
        }
      }

      console.log("last jrnl no", no);
      return no;
    },
    getWorkJournalNo(state) {
      return state.workJournal.startJrnl.jnlNo;
    },
    getIsLoggedIn(state) {
      return state.auth.isLoggedIn;
    },
    getUlData(state) {
      return state.uldata;
    },
    getUlDataLoading(state) {
      return state.ui.isUlDataLoading;
    },
    getIsJrnlPosting(state) {
      return state.ui.isJrnlPosting;
    },
    getError(state) {
      return state.ui.error;
    },
    getErrorShrtUpd(state) {
      return state.ui.errorShrtUpd;
    },
    getTypes(state) {
      if (state.uldata.uldata.currLedger.types) {
        if (state.uldata.uldata.currLedger.types.length > 0) {
          return state.uldata.uldata.currLedger.types;
        }
      }
      return null;
    },
    getIsEditingLedger(state) {
      return state.ui.isEditingLedger;
    },
    getIsInsertingLedger(state) {
      return state.ui.isInsertingLedger;
    },
    getCurrLedger(state) {
      return state.uldata.uldata.currLedger;
    },
    getCurrLedgerDetails(state) {
      return state.uldata.uldata.currLedger.details;
    },
    getCurrLedJrnlList(state) {
      return state.uldata.uldata.currLedger.journals;
    },
    getCurrLedgerBaseCurrNoDec(state) {
      return state.uldata.uldata.currLedger.details.baseCurrNoDec;
    },
    getCurrLedgerFrmtShrtDts(state) {
      return state.uldata.uldata.currLedger.details.frmtShrtDts;
    },
    getCurrLedgerId(state) {
      if (state.uldata.uldata.currLedger.details.ledId) {
        return state.uldata.uldata.currLedger.details.ledId;
      }
      return null;
    },
    getCurrLedgerUserId(state) {
      return state.uldata.uldata.currLedger.details.userId;
    },
    getCurrLedgerNo(state) {
      return state.uldata.uldata.currLedger.details.ledNo;
    },
    getCurrLedgerName(state) {
      return state.uldata.uldata.currLedger.details.ledName;
    },
    getCurrLedgerDescr(state, getters) {
      // return state.uldata.uldata.currLedger.details.ledDescr || "Please select a ledger";
      console.log("state", state);
      console.log("led Descr Auth Led", getters.getAuthCurrLedId);
      return getters.getAuthCurrLedId ? state.uldata.uldata.currLedger.details.ledDescr : "Please select or create a new Ledger";
    },
    getCurrLedgerNotes(state) {
      return state.uldata.uldata.currLedger.details.ledNotes;
    },
    getLedBsTitle(state) {
      return state.uldata.uldata.currLedger.details.bsTitle;
    },
    getLedPlTitle(state) {
      return state.uldata.uldata.currLedger.details.plTitle;
    },
    getLedTbTitle(state) {
      return state.uldata.uldata.currLedger.details.tbTitle;
    },
    getCurrLedgerBaseCurr(state) {
      return state.uldata.uldata.currLedger.details.baseCurr;
    },
    getLedgers(state) {
      return state.uldata.uldata.ledgers;
    },
    getSelAcForJrnl(state) {
      return state.ui.selAcForJrnl;
    },
    getAuthData(state) {
      return state.auth;
    },
    getAuthUserId(state) {
      return state.auth.userId;
    },
    getAuthCurrLedId(state) {
      return state.auth.currLedgerId;
    },
    getCategories(state, getters) {
      // Used to get a list of categories for drop-downs in Ac Maint.
      const types = getters.getTypes;
      const cats = [];

      for (let t = 0; t < types.length; t++) {
        if (types[t].categories) {
          for (let c = 0; c < types[t].categories.length; c++) {
            console.log("cat name", types[t].categories[c].catName);
            cats.push({
              typeId: types[t].typeId,
              typeSort: types[t].typeSort,
              catId: types[t].categories[c].catId,
              catSort: types[t].categories[c].catSort,
              catName: types[t].categories[c].catName,
              catBal: types[t].categories[c].catBal,
              catDescr: types[t].categories[c].catDescr,
              // catNoAcs: types[t].categories[c].catNoAcs,
              catNoDrLegs: types[t].categories[c].catNoDrLegs,
              catNoCrLegs: types[t].categories[c].catNoCrLegs,
              catExclBal: types[t].categories[c].catExclBal,
              catExclNoDrLegs: types[t].categories[c].catExclNoDrLegs,
              catExclNoCrLegs: types[t].categories[c].catExclNoCrLegs,
              accounts: types[t].categories[c].accounts,
            });
          }
        }
      }
      // TODO Needs to be sorted by 1. typeSort; 2. catSort
      return cats;
    },
    getAccounts(state, getters) {
      const types = getters.getTypes;
      const acs = [];
      if (types) {
        for (let t = 0; t < types.length; t++) {
          if (types[t].categories) {
            for (let c = 0; c < types[t].categories.length; c++) {
              if (types[t].categories[c].accounts) {
                for (let a = 0; a < types[t].categories[c].accounts.length; a++) {
                  acs.push({
                    typeId: types[t].typeId,
                    typeSort: types[t].typeSort,
                    catId: types[t].categories[c].catId,
                    catSort: types[t].categories[c].catSort,
                    catName: types[t].categories[c].catName,
                    catBal: types[t].categories[c].catBal,
                    catDescr: types[t].categories[c].catDescr,
                    // catNoAcs: types[t].categories[c].catNoAcs,
                    catNoDrLegs: types[t].categories[c].catNoDrLegs,
                    catNoCrLegs: types[t].categories[c].catNoCrLegs,
                    catExclBal: types[t].categories[c].catExclBal,
                    catExclNoDrLegs: types[t].categories[c].catExclNoDrLegs,
                    catExclNoCrLegs: types[t].categories[c].catExclNoCrLegs,
                    accId: types[t].categories[c].accounts[a].accId,
                    accName: types[t].categories[c].accounts[a].accName,
                    accNumber: types[t].categories[c].accounts[a].accNumber,
                    accBal: types[t].categories[c].accounts[a].accBal,
                    accExclBal: types[t].categories[c].accounts[a].accExclBal,
                    debits: types[t].categories[c].accounts[a].debits,
                    credits: types[t].categories[c].accounts[a].credits,
                    // legs: types[t].categories[c].accounts[a].debits.concat(types[t].categories[c].accounts[a].credits),
                  });
                  // acs.legs.concat(types[t].categories[c].accounts[a].credits);
                  // acs[0].legs[0].push(types[t].categories[c].accounts[a].credits);
                  // for (let lg = 0; lg < types[t].categories[c].accounts[a].debits.length; lg++) {
                  //   acs.legs.push({types[t].categories[c].accounts[a].debits[lg]});
                  // }
                  if (types[t].categories[c].accounts[a].debits) {
                    console.log("dbts", types[t].categories[c].accounts[a].debits);
                    console.log("oth debts", acs[a].debits);
                    // acs[a].legs.push(acs[a].debits);
                    // acs[a].legs.push(...types[t].categories[c].accounts[a].debits);
                    for (let lg = 0; lg < types[t].categories[c].accounts[a].debits.length; lg++) {
                      // acs[a].legs.push(acs[a].debits[lg]);
                      // acs.legs.push({types[t].categories[c].accounts[a].debits[lg]});
                      // console.log("debit leg", acs[a].debits[lg]);
                    }
                  }
                  if (types[t].categories[c].accounts[a].credits) {
                    console.log("dbts", types[t].categories[c].accounts[a].credits);
                    // for (let lg = 0; lg < types[t].categories[c].accounts[a].credits.length; lg++) {
                    //   acs[a].legs.push(types[t].categories[c].accounts[a].credits[lg]);
                    // }
                    // acs[a].legs.push(...types[t].categories[c].accounts[a].credits);
                  }
                }
              }
            }
          }
        }
      }
      console.log("acs legs", acs);
      return acs;
    },
    getNewCatId(state) {
      return state.new.catId;
    },
    getNewAcId(state) {
      return state.new.acId;
    },
    getNewJnlId(state) {
      return state.new.jnlId;
    },
    getHlJnlIdx(state) {
      return state.hLights.jnlIdx;
    },

    getHlJnlNo(state) {
      return state.hLights.jnlNo;
    },
    getHlJnlId(state) {
      return state.hLights.jnlId;
    },

    getJournals(state) {
      return state.uldata.uldata.currLedger.journals;
    },
    getJnlIdxById: (state, getters) => (jnlId) => {
      // Given a journal id, get the index number in the list of journals:
      const jrnls = getters.getJournals;
      console.log("Need to use state: LedgerId", state.ledgerId);
      console.log("All jrnls", jrnls);
      for (let i = 0; i < jrnls.length; i++) {
        if (jrnls[i].jnlId == jnlId) {
          console.log("selected jnl", jnlId, jrnls[i], state.ledgerId, i);
          return i;
        }
      }
      return null;
    },
    getJnlById: (state, getters) => (id) => {
      const journals = getters.getJournals;
      console.log("getJnlById", id, journals);
      if (journals) {
        for (let jnl of journals) {
          if (jnl.jnlId == id) {
            // console.log('selected jnl', jnlNo, jnl);
            return jnl;
          }
        }
      }
      return null;
    },
    getAcTxns: (state, getters) => (acId) => {
      const acs = getters.getAccounts;
      // console.log("acs", acs);
      // console.log("acs", acs[11]);
      let txns = [];
      for (let ac of acs) {
        console.log("condition ", ac.accId, acId);
        if (ac.accId == acId) {
          // console.log("acs", ac);

          if (ac.debits) {
            // console.log("debits", ac.debits);
            // txns.push(ac.debits);
            for (let lg of ac.debits) {
              // console.log('ac dr leg', ac.debits[l])
              txns.push(lg);
              // console.log(acs[ac].debits[lg]);
            }
          }
          if (ac.credits) {
            // console.log("credits", ac.credits);
            // txns.push(ac.credits);
            for (let lg of ac.credits) {
              txns.push(lg);
              // console.log(acs[ac].debits[lg]);
            }
          }

          // txns.push(acs[ac].credits);
        }
      }
      // console.log("txns before sort", txns);
      // let clonedArray = JSON.parse(JSON.stringify(nodesArray))
      // let txns_cp = JSON.parse(JSON.stringify(txns));
      // this.commit("sortAllLegs", txns);
      // let srtLegs = txns.sort(function (a, b) {
      //   console.log("jnlFullDate", a.jnlFullDate);
      //   return a.jnlFullDate.localeCompare(b.jnlFullDate) || a.jnlNo > b.jnlNo ? 1 : a.jnlNo < b.jnlNo ? -1 : 0;
      //   // return a.jnlFullDate.localeCompare(b.jnlFullDate) || a.jnlNo > b.jnlNo ? 1 : a.jnlNo < b.jnlNo ? -1 : 0 || a.legNo > b.legNo ? 1 : a.legNo < b.legNo ? -1 : 0;
      // });
      // let srtLegs = getters.sortAllLegs(txns);
      // let srtLegs = getters.sortAcLegs(txns);
      // console.log("srtLegs", srtLegs);
      return txns;
    },
    sortAllLegs(state, payload) {
      // console.log("sort payload", payload);
      // let srtLgs = payload.sort(function (a, b) {
      payload.sort(function (a, b) {
        // return a.jnlFullDate.localeCompare(b.jnlFullDate) || a.jnlNo.localeCompare(b.jnlNo);
        // return a.jnlFullDate.localeCompare(b.jnlFullDate) || a.jnlNo > b.jnlNo ? 1 : a.jnlNo < b.jnlNo ? -1 : 0;
        return a.jnlFullDate.localeCompare(b.jnlFullDate) || a.jnlNo > b.jnlNo ? 1 : a.jnlNo < b.jnlNo ? -1 : 0 || a.legNo > b.legNo ? 1 : a.legNo < b.legNo ? -1 : 0;
      });

      // console.log("srtLgs", srtLgs);
      // return srtLgs;
      // payload = srtLegs;
    },
    getJnlAcIds(state) {
      return state.hLights.jnlAcIds;
    },
    getHlTBac(state) {
      return state.hLights.TBac;
    },
    getHlights(state) {
      return state.hLights;
    },

    getDisplCtrlAcs_empAcs(state) {
      return state.displCntrl.acs.displayEmpAcs;
    },
    getDisplCtrlAcs_catName(state) {
      return state.displCntrl.acs.displayCatName;
    },
    getDisplCtrlAcs_hlJnlOnly(state) {
      return state.displCntrl.acs.hlJnlOnly;
    },
    getDisplCtrlAcs_shwExclLegs(state) {
      return state.displCntrl.acs.shwExclLegs;
    },
    getDisplCtrlTb_catName(state) {
      return state.displCntrl.tb.displayCatName;
    },
    getDisplCtrlTb_displStm(state) {
      return state.displCntrl.tb.displStm;
    },

    getCalcTotalDrBal(state) {
      return state.uldata.uldata.currLedger.details.calc_total_dr_bal;
    },
    getCalcTotalCrBal(state) {
      return state.uldata.uldata.currLedger.details.calc_total_cr_bal;
    },
    getNetCA(state, getters) {
      // Net Current Assets
      const types = getters.getTypes;
      console.log("types from getNectCA", types);
      const nca = types[1].typeBal - types[1].typeExclBal;
      const ncl = types[2].typeBal - types[2].typeExclBal;
      return nca + ncl;
    },
    getTotAssetsLessCurrLiabs(state, getters) {
      return getters.get_NCA + getters.getNetCA;
    },
    getTotAL(state, getters) {
      return getters.get_NCA + getters.get_CAS + getters.get_CLB + getters.get_NCL;
    },
    getNetProfit(state, getters) {
      console.log("netprofit from index", getters.get_REV + getters.get_CSL + getters.get_OIN + getters.get_EXP);
      return getters.get_REV + getters.get_CSL + getters.get_OIN + getters.get_EXP;
    },
    getShareHoldSurpl(state, getters) {
      return getters.get_RES + getters.getNetProfit;
    },
    getGrossProfit(state, getters) {
      return getters.get_REV + getters.get_CSL;
    },
    getNetIncome(state, getters) {
      return getters.getGrossProfit + getters.get_OIN;
    },
    getSummaries(state, getters) {
      const totals = {};
      const types = getters.getTypes;
      if (types && types.length > 0) {
        totals.NCA = types[0].typeBal - types[0].typeExclBal;
        totals.CAS = types[1].typeBal - types[1].typeExclBal;
        totals.CLB = types[2].typeBal - types[2].typeExclBal;
        totals.NCL = types[3].typeBal - types[3].typeExclBal;
        totals.RES = types[4].typeBal - types[4].typeExclBal;
        totals.REV = types[5].typeBal - types[5].typeExclBal;
        totals.CSL = types[6].typeBal - types[6].typeExclBal;
        totals.OIN = types[7].typeBal - types[7].typeExclBal;
        totals.EXP = types[8].typeBal - types[8].typeExclBal;
      }
      console.log("totals", totals);
      return totals;
    },
    get_NCA(state, getters) {
      // NCA - Non-Current Assets
      const types = getters.getTypes;
      if (types.length > 0) {
        return types[0].typeBal - types[0].typeExclBal;
      }
      return 0;
      // return state.uldata.uldata.currLedger.types[0].typeBal - state.uldata.uldata.currLedger.types[0].typeBal;
      // return types[0].typeBal - types[0].typeExclBal;
    },
    get_CAS(state, getters) {
      // CAS - Current Assets
      const types = getters.getTypes;
      return types[1].typeBal - types[1].typeExclBal;
    },
    get_CLB(state, getters) {
      // CLB - Current Liabilities
      const types = getters.getTypes;
      return types[2].typeBal - types[2].typeExclBal;
    },
    get_NCL(state, getters) {
      // NCL - Non-Current Liabilities
      const types = getters.getTypes;
      return types[3].typeBal - types[3].typeExclBal;
    },
    get_RES(state, getters) {
      // RES - Reserves
      const types = getters.getTypes;
      return types[4].typeBal - types[4].typeExclBal;
    },
    get_REV(state, getters) {
      // REV - Revenue
      const types = getters.getTypes;
      return types[5].typeBal - types[5].typeExclBal;
    },
    get_CSL(state, getters) {
      // CSL - Cost of Sales
      const types = getters.getTypes;
      return types[6].typeBal - types[6].typeExclBal;
    },
    get_OIN(state, getters) {
      // OIN - Other Income
      const types = getters.getTypes;
      return types[7].typeBal - types[7].typeExclBal;
    },
    get_EXP(state, getters) {
      // EXP - Expenses
      const types = getters.getTypes;
      return types[8].typeBal - types[8].typeExclBal;
    },
    getUserName(state) {
      return state.auth.userName;
    },
    getDemoUserId(state) {
      return state.auth.demoUserId;
    },
    getDemoCurrLedgerId(state) {
      return state.auth.demoCurrLedgerId;
    },
  },
  mutations: {
    setUlData(state, payload) {
      state.uldata = null;
      // state.uldataLoaded = false;
      state.uldata = payload;
      // state.uldataLoaded = true;
    },
    // setCurrLedgerDescr(state, payload) {},
    setSelAcForJrnl(state, payload) {
      console.log("setSelAcForJrnl payload", payload);
      state.ui.selAcForJrnl = payload;
    },
    setAuthUserId(state, payload) {
      state.auth.userId = payload;
    },
    setAuthCurrLedId(state, payload) {
      console.log("setAuthCurrLedId payload", payload);
      state.auth.currLedgerId = payload;
    },
    setUlDataLoading(state, payl) {
      payl ? (state.ui.isUlDataLoading = true) : (state.ui.isUlDataLoading = false);
    },
    setIsJrnlPosting(state, payl) {
      payl ? (state.ui.isJrnlPosting = true) : (state.ui.isJrnlPosting = false);
    },
    setError(state, payl) {
      state.ui.error = payl;
    },
    setErrorShrtUpd(state, payl) {
      state.ui.errorShrtUpd = payl;
    },
    setIsEditingLedger(state, payl) {
      return (state.ui.isEditingLedger = payl);
    },
    setIsInsertingLedger(state, payl) {
      return (state.ui.isInsertingLedger = payl);
    },
    setIsLoggedIn(state) {
      state.auth.isLoggedIn = !state.auth.isLoggedIn;
    },
    setIsSup(state) {
      state.auth.supervisor = !state.auth.supervisor;
    },
    setCurrLedgerDescr(state, payl) {
      state.uldata.uldata.currLedger.details.ledDescr = payl;
    },
    setCurrLedgerNotes(state, payl) {
      state.uldata.uldata.currLedger.details.ledNotes = payl;
    },
    setLedBsTitle(state, payl) {
      state.uldata.uldata.currLedger.details.bsTitle = payl;
    },
    setLedPlTitle(state, payl) {
      state.uldata.uldata.currLedger.details.plTitle = payl;
    },
    setLedTbTitle(state, payl) {
      state.uldata.uldata.currLedger.details.tbTitle = payl;
    },
    updateLedgerList(state, payload) {
      const ledgerList = this.getters.getLedgers;
      console.log("ledgerList", ledgerList);
      ledgerList.forEach((ledger) => {
        if (ledger.ledId == payload.id) {
          ledger.ledName = payload.name;
        }
      });
      this.commit("sortLedgerList", ledgerList);
    },
    updateLedgerDetails(state, payload) {
      const ledDetails = this.getters.getCurrLedgerDetails;
      console.log("ledDetails", ledDetails);
      ledDetails.ledName = payload.name.replace(/''/g, "'");
      ledDetails.ledDescr = payload.description.replace(/''/g, "'");
      ledDetails.baseCurr = payload.base_curr;
      ledDetails.baseCurrDesc = payload.base_curr_desc.replace(/''/g, "'");
      ledDetails.baseCurrNoDec = payload.base_curr_no_dec;
      ledDetails.frmtAmts = payload.frmtAmts;
      ledDetails.frmtShrtDts = payload.frmtShrtDts;
      ledDetails.ledNotes = payload.notes.replace(/''/g, "'");
      ledDetails.bsTitle = payload.bsTitle.replace(/''/g, "'");
      ledDetails.plTitle = payload.plTitle.replace(/''/g, "'");
      ledDetails.tbTitle = payload.tbTitle.replace(/''/g, "'");
    },
    sortLedgerList(state, payload) {
      let srtLeds = payload.sort(function (a, b) {
        return a.ledName.localeCompare(b.ledName);
      });
      payload = srtLeds;
    },
    delLedgerFromList() {
      const ledId = this.getters.getCurrLedgerId;
      console.log("ledId to del", ledId);
      const ledgerList = this.getters.getLedgers;

      for (let i = 0; i < ledgerList.length; i++) {
        if (ledgerList[i].ledId == ledId) {
          // console.log('ledger delete loop found:', currLedgers[i].id),
          // currLedgers[i].name;
          ledgerList.splice(i, 1);
        }
      }
      console.log("ledgers after delete:", ledgerList);
    },
    delLedger(state) {
      // const blank = [];
      state.uldata.uldata.currLedger = { details: { ledDescr: "Please select or create a new Ledger.", ledNo: 0 }, journals: [], types: [] };
      console.log("aft del", state.uldata.uldata);
    },
    updateCalcDrCrBalTotals(state) {
      let accounts = this.getters.getAccounts;
      console.log("accounts", accounts);
      let totalDr_bal = 0;
      let totalCr_bal = 0;
      for (let ac of accounts) {
        // console.log("ac bal, excl:", ac.accBal, ac.accExclBal);

        let wrkBal = ac.accBal - ac.accExclBal;
        if (wrkBal >= 0) totalDr_bal = totalDr_bal + wrkBal;
        if (wrkBal < 0) totalCr_bal = totalCr_bal + wrkBal;
      }

      console.log("total dr, cr", totalDr_bal, totalCr_bal);
      state.uldata.uldata.currLedger.details.calc_total_dr_bal = totalDr_bal;

      state.uldata.uldata.currLedger.details.calc_total_cr_bal = totalCr_bal * -1;
      // store.commit('updateTotalDrBal', totalDr_bal);
      // store.commit('updateTotalCrBal', totalCr_bal);
    },
    cmtNewCategory(state, payload) {
      const types = this.getters.getTypes;
      console.log("cmtNewCategory payload", payload);
      // console.log('comit cats', categories);
      for (let t = 0; t < types.length; t++) {
        if (types[t].typeId == payload.catType) {
          if (!types[t].categories) {
            types[t].categories = [];
          }
          types[t].categories.push({
            accounts: [],
            catBal: 0,
            catDescr: payload.catNotes,
            catExclBal: 0,
            catExclNoCrLegs: 0,
            catExclNoDrLegs: 0,
            catId: payload.id,
            catName: payload.catName,
            // catNoAcs: 0,
            catNoCrLegs: 0,
            catNoDrLegs: 0,
            catSort: payload.catSort,
            catType: payload.catType,
          });
        }
      }

      console.log("cmtNewCategory: types af new push", types);
    },
    setNewCatId(state, payload) {
      console.log("setNewCatId", payload);
      state.new.catId = payload;
    },
    setNewJnlId(state, payload) {
      console.log("setNewJnlId", payload);
      state.new.jnlId = payload;
    },
    cmtEditCategory(state, payload) {
      console.log("mutat edit cat:", payload);

      const types = this.getters.getTypes;
      console.log("types:", types);
      const mvCat = [];

      loop1: for (let t = 0; t < types.length; t++) {
        if (types[t].categories) {
          for (let c = 0; c < types[t].categories.length; c++) {
            if (types[t].categories[c].catId == payload.catId) {
              types[t].categories[c].catName = payload.catName;
              types[t].categories[c].catDescr = payload.catNotes;
              if (payload.initCatType != payload.catType) {
                mvCat.push(types[t].categories[c]);
                mvCat[0].catType = payload.catType;
                types[t].typeBal = types[t].typeBal - mvCat[0].catBal;
                types[t].categories.splice(c, 1);
              }
              break loop1;
            }
          }
        }
      }
      console.log("mvCat", mvCat);
      if (mvCat.length > 0) {
        for (let t = 0; t < types.length; t++) {
          if (types[t].typeId == payload.catType) {
            if (types[t].categories == null) types[t].categories = [];
            if (types[t].categories) mvCat[0].catSort = types[t].categories.length;
            if (!types[t].categories) mvCat[0].catSort = 0;
            console.log("mvCat before push", mvCat);
            types[t].categories.push(mvCat[0]);
            types[t].typeBal = types[t].typeBal + mvCat[0].catBal;

            break;
          }
        }
      }
      console.log("types af push", types);
    },
    cmtCatMove(state, payload) {
      // console.log("catMove commit payload", payload);
      let types = this.getters.getTypes;
      let categories = types[payload.typIdx].categories;

      const fromId = payload.fromId;
      const fromSort = payload.fromSort;
      const toId = payload.toId;
      const toSort = payload.toSort;

      for (let i = 0; i < categories.length; i++) {
        if (categories[i].catId == fromId) {
          categories[i].catSort = fromSort;
        }

        if (categories[i].catId == toId) {
          categories[i].catSort = toSort;
        }
      }
      // console.log("categories:", categories);
      // console.log("types after move", this.getters.getTypes);
      let srtCats = categories.sort(function (a, b) {
        return a.catSort - b.catSort;
      });
      types[payload.typIdx].categories = srtCats;
      console.log("types after move, srt", this.getters.getTypes);
    },
    cmtCatMove_1(state, payload) {
      let types = this.getters.getTypes;
      console.log("payload", payload);
      console.log("types before mv", types);

      // Remove and save the category moved
      let trgCat = state.uldata.uldata.currLedger.types[payload.typIdx].categories.splice(payload.catIdx, 1)[0];
      if (payload.dir == "up") {
        trgCat.catSort = trgCat.catSort - 1; // Change the sort of the removed category.
        // Change sort of the previous category:
        state.uldata.uldata.currLedger.types[payload.typIdx].categories[payload.catIdx - 1].catSort = payload.catIdx;
        state.uldata.uldata.currLedger.types[payload.typIdx].categories.splice(payload.catIdx - 1, 0, trgCat);
      }

      if (payload.dir == "down") {
        trgCat.catSort = trgCat.catSort + 1; // Change the sort of the removed category.
        // Change sort of the next category:
        state.uldata.uldata.currLedger.types[payload.typIdx].categories[payload.catIdx].catSort = payload.catIdx;
        state.uldata.uldata.currLedger.types[payload.typIdx].categories.splice(payload.catIdx + 1, 0, trgCat);
      }

      console.log("trgCat", trgCat);
      console.log("types after mv", types);
    },

    cmtNewAccount(state, payload) {
      const types = this.getters.getTypes;
      console.log("ac sort payload, types", payload, types);
      for (let t = 0; t < types.length; t++) {
        if (types[t].categories) {
          for (let c = 0; c < types[t].categories.length; c++) {
            if (types[t].categories[c].catId == payload.acCat) {
              if (!types[t].categories[c].accounts) {
                types[t].categories[c].accounts = [];
              }
              types[t].categories[c].accounts.push({
                accId: payload.id,
                accCatId: payload.acCat,
                accExclBal: 0,
                accExclNoCrLegs: 0,
                accExclNoDrLegs: 0,
                accName: payload.acName,
                accNumber: payload.acNo,
                accNotes: payload.acNotes,
                accBal: 0,
                accNoDrLegs: 0,
                accNoCrLegs: 0,
                debits: [],
                credits: [],
              });
              // });
              // console.log('newAcs:', cat.accounts);
              // let sortAccounts = cat.accounts.sort(function(a, b) {
              //   return a.accNumber - b.accNumber;
              // });
              // let srtActs = sortAccounts(cat);
              this.commit("sortAccounts", types[t].categories[c]);
              // context.commit('sortAccounts')
              // cat.accounts = state.commit.sortAccounts(cat);
            }
          }
        }
      }
    },
    cmtDeleteAc(state, payload) {
      const types = this.getters.getTypes;
      if (types) {
        for (let t = 0; t < types.length; t++) {
          if (types[t].categories) {
            for (let c = 0; c < types[t].categories.length; c++) {
              if (types[t].categories[c].accounts) {
                for (let a = 0; a < types[t].categories[c].accounts.length; a++) {
                  if (types[t].categories[c].accounts[a].accId == payload.acId) {
                    types[t].categories[c].accounts.splice(a, 1);
                  }
                }
              }
            }
          }
        }
      }
    },
    cmtDeleteCat(state, payload) {
      const types = this.getters.getTypes;
      if (types) {
        for (let t = 0; t < types.length; t++) {
          if (types[t].categories) {
            for (let c = 0; c < types[t].categories.length; c++) {
              if (types[t].categories[c].catId == payload) {
                types[t].categories.splice(c, 1);
              }
            }
          }
        }
      }
    },
    setNewAcId(state, payload) {
      console.log("setNewAcId", payload);
      state.new.acId = payload;
    },
    sortAccounts(state, payload) {
      let srtAcs = payload.accounts.sort(function (a, b) {
        // return a.accNumber - b.accNumber;
        return a.accNumber.localeCompare(b.accNumber);
      });
      payload.accounts = srtAcs;
    },
    sortCategories(state, payload) {
      let srtCats = payload.sort(function (a, b) {
        return a.catSort - b.catSort;
      });
      payload = srtCats;
    },
    sortTyps(state, payload) {
      if (payload) {
        let srtTyps = payload.sort(function (a, b) {
          return a.typeSort - b.typeSort;
        });
        payload = srtTyps;
      }
      // return srtTyps;
      console.log("sortTyps payload", payload);
    },
    sortTypes(state) {
      let srtTypes = this.getters.getTypes;
      if (srtTypes && srtTypes.length > 1) {
        srtTypes = srtTypes.sort(function (a, b) {
          return a.typeSort - b.typeSort;
        });
        state.uldata.uldata.currLedger.types = srtTypes;
      }
    },
    sortTypesAll() {
      if (this.getters.getTypes) {
        let srtTypes = this.getters.getTypes;
        console.log("srtTypes", srtTypes);
        this.commit("sortTyps", srtTypes);
        console.log("srtTypes after sort", srtTypes);

        for (let t = 0; t < srtTypes.length; t++) {
          if (srtTypes[t].categories) {
            if (srtTypes[t].categories.length > 1) {
              this.commit("sortCategories", srtTypes[t].categories);
            }

            if (srtTypes[t].categories.length > 0) {
              for (let c = 0; c < srtTypes[t].categories.length; c++) {
                if (srtTypes[t].categories[c].accounts && srtTypes[t].categories[c].accounts.length > 1) this.commit("sortAccounts", srtTypes[t].categories[c]);
              }
            }
          }
          // state.uldata.uldata.currLedger.types = srtTypes;
        }
      }
    },
    cmtEditAccount(state, payload) {
      // Update basic field data
      state.uldata.uldata.currLedger.types[payload.typIdx].categories[payload.catIdx].accounts[payload.acIdx].accCatId = payload.acCat;
      state.uldata.uldata.currLedger.types[payload.typIdx].categories[payload.catIdx].accounts[payload.acIdx].accName = payload.acName;
      state.uldata.uldata.currLedger.types[payload.typIdx].categories[payload.catIdx].accounts[payload.acIdx].accNumber = payload.acNo;
      state.uldata.uldata.currLedger.types[payload.typIdx].categories[payload.catIdx].accounts[payload.acIdx].accNotes = payload.acNotes;

      if (payload.acCat != payload.initAcCatId) {
        console.log("Ac category has changed", payload);

        // Remove ac from original category and save:
        let mvAc = state.uldata.uldata.currLedger.types[payload.typIdx].categories[payload.catIdx].accounts.splice(payload.acIdx, 1)[0];

        // Add to new category
        const types = this.getters.getTypes;
        console.log("types", types);
        for (let t = 0; t < types.length; t++) {
          // Is oldType different to newType?
          // If so:
          // 1. Reduce bals, etc. on oldType
          // 2. Increase bals, etc. on newType
          if (payload.oldTypeId != payload.newTypeId) {
            if (types[t].typeId == payload.oldTypeId) {
              types[t].typeBal = types[t].typeBal - payload.acBal;
              types[t].typeExclBal = types[t].typeExclBal - payload.acExclBal;
              types[t].typeNoLegs = types[t].typeNoLegs - (payload.acNoDrLegs + payload.acNoCrLegs);
              types[t].typeExclNoLegs = types[t].typeExclNoLegs - (payload.acExclNoDrLegs + payload.acExclNoCrLegs);
            }
            if (types[t].typeId == payload.newTypeId) {
              types[t].typeBal = types[t].typeBal + payload.acBal;
              types[t].typeExclBal = types[t].typeExclBal + payload.acExclBal;
              types[t].typeNoLegs = types[t].typeNoLegs + (payload.acNoDrLegs + payload.acNoCrLegs);
              types[t].typeExclNoLegs = types[t].typeExclNoLegs + (payload.acExclNoDrLegs + payload.acExclNoCrLegs);
            }
          }
          if (types[t].categories) {
            for (let c = 0; c < types[t].categories.length; c++) {
              if (types[t].categories[c]) {
                // The old category:
                if (types[t].categories[c].catId == payload.initAcCatId) {
                  types[t].categories[c].catBal = types[t].categories[c].catBal - payload.acBal;
                  types[t].categories[c].catExclBal = types[t].categories[c].catExclBal - payload.acExclBal;
                  types[t].categories[c].catNoDrLegs = types[t].categories[c].catNoDrLegs - payload.acNoDrLegs;
                  types[t].categories[c].catNoCrLegs = types[t].categories[c].catNoCrLegs - payload.acNoCrLegs;
                  types[t].categories[c].catExclNoDrLegs = types[t].categories[c].catExclNoDrLegs - payload.acExclNoDrLegs;
                  types[t].categories[c].catExclNoCrLegs = types[t].categories[c].catExclNoCrLegs - payload.acExclNoCrLegs;
                }
                // The new categogy:
                if (types[t].categories[c].catId == payload.acCat) {
                  types[t].categories[c].catBal = types[t].categories[c].catBal + payload.acBal;
                  types[t].categories[c].catExclBal = types[t].categories[c].catExclBal + payload.acExclBal;
                  types[t].categories[c].catNoDrLegs = types[t].categories[c].catNoDrLegs + payload.acNoDrLegs;
                  types[t].categories[c].catNoCrLegs = types[t].categories[c].catNoCrLegs + payload.acNoCrLegs;
                  types[t].categories[c].catExclNoDrLegs = types[t].categories[c].catExclNoDrLegs + payload.acExclNoDrLegs;
                  types[t].categories[c].catExclNoCrLegs = types[t].categories[c].catExclNoCrLegs + payload.acExclNoCrLegs;
                  console.log("types-categories: ", types[t], types[t].categories[c], types[t].categories[c].catId);

                  // if (types[t].categories[c].accounts) {
                  //   types[t].categories[c].accounts.push(mvAc);
                  // }
                  // if (!types[t].categories[c].accounts) {
                  (types[t].categories[c].accounts = types[t].categories[c].accounts || []).push(mvAc);
                  // }
                  let srtAcs = types[t].categories[c].accounts.sort(function (a, b) {
                    // return a.accNumber - b.accNumber;
                    return a.accNumber.localeCompare(b.accNumber);
                  });
                  state.uldata.uldata.currLedger.types[t].categories[c].accounts = srtAcs;
                }
              }
            }
          }
        }
      }
    },
    // removeJnlNo(state) {
    //   state.hLights.jnlNo = null;
    // },
    updateHlJnlAcIds(state, payload) {
      state.hLights.jnlAcIds = [];
      // console.log('updJnlAcIds: ', payload);
      state.hLights.jnlAcIds = payload;
    },
    // removeJnlAcIds(state) {
    //   state.hLights.jnlAcIds = [];
    // },
    updateHlJnlNo(state, payload) {
      state.hLights.jnlNo = null;
      state.hLights.jnlNo = payload;
    },
    updateHlJnlId(state, payload) {
      state.hLights.jnlId = payload;
    },
    updateHlAcTB(state, payload) {
      state.hLights.TBac = null;
      state.hLights.TBac = payload;
    },
    updateHlJnlIdx(state, payload) {
      state.hLights.jnlIdx = null;
      state.hLights.jnlIdx = payload;
    },

    // removeJnlIdx(state) {
    //   state.hLights.jnlIdx = null;
    // },
    // updateJnlKey(state, payload) {
    //   state.hLights.jnlKey = null;
    //   state.hLights.jnlKey = payload;
    // },

    // removeJnlKey(state) {
    //   state.hLights.Key = null;
    // },
    clearHlights(state) {
      state.hLights.jnlId = null;
      state.hLights.jnlNo = null;
      state.hLights.jnlIdx = null;
      state.hLights.jnlAcIds = [];
      // state.hLights.TBac = null;
    },
    setLegHl(state, payload) {
      state.legHl.accId = payload.accId;
      state.legHl.sign = payload.sign;
      state.legHl.legId = payload.legId;
    },
    clearLegHl(state) {
      state.legHl.accId = null;
      state.legHl.sign = null;
      state.legHl.legId = null;
    },
    setDisplCtrlAcs_empAcs(state, payload) {
      state.displCntrl.acs.displayEmpAcs = payload;
    },
    setDisplCtrlAcs_catName(state, payload) {
      state.displCntrl.acs.displayCatName = payload;
    },
    setDisplCtrlAcs_hlJnlOnly(state, payload) {
      state.displCntrl.acs.hlJnlOnly = payload;
    },
    setDisplCtrlAcs_shwExclLegs(state, payload) {
      state.displCntrl.acs.shwExclLegs = payload;
    },
    setDisplCtrlTb_catName(state, payload) {
      state.displCntrl.tb.displayCatName = payload;
    },
    setDisplCtrlTb_displStm(state, payload) {
      state.displCntrl.tb.displStm = payload;
    },

    setWrkJrnlMode(state, payload) {
      state.workJournal.jrnlMode = payload;
    },
    loadWorkJournal(state, payload) {
      state.workJournal.startJrnl = null;
      state.workJournal.startJrnl = payload;

      if (this.getters.getWrkJrnlMode == "EDIT") {
        const wrkLegs = this.getters.getWorkJournal.legs;
        for (let i = 0; i < wrkLegs.length; i++) {
          // console.log('work leg', wrkLegs[i]);
          let num = wrkLegs[i].legAmount / 100;
          num = num.toFixed(2);
          wrkLegs[i].legAmount = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      }
    },
    clearWorkJournal(state) {
      state.workJournal.startJrnl = null;
      // state.workJournal.jrnlMode = "";
    },

    sortJournals(state) {
      // console.log("sortJournals payload", payload);
      let srtJnls = this.getters.getJournals;
      if (srtJnls && srtJnls.length > 1) {
        srtJnls = srtJnls.sort(function (a, b) {
          // console.log('a.name', a.name, b.name);
          // console.log('a.jnlId', a.jnlId);
          // if (a.jnlId > b.jnlId) return 1;
          // if (a.JnlId < b.jnlId) return -1;
          // return 0;

          return a.jnlNo - b.jnlNo;
          // return a.jnlId.localeCompare(b.jnlId);
        });
        // console.log('sorted journals', srtJnls);
        // payload = srtJnls;
        state.uldata.uldata.currLedger.journals = srtJnls;
      }
    },
    cmtEditJournal(state, payload) {
      console.log("cmtEditJournal", payload);
      state.uldata.uldata.currLedger.journals.push({
        // ledgerId: parseInt(this.getters.getCurrLedgerId),
        ledgerId: this.getters.getCurrLedgerId,
        // jnlId: store.getters.getJournalCount,
        // jnlKey: payload.jnlKey,
        jnlId: payload.jnlId,
        jnlNo: payload.jnlNo,
        jnlDate: payload.jnlDate,
        // jnlBeing: payload.jnlBeing,
        jnlBeing: payload.jnlBeing.replace(/''/g, "'"),
        // jnlDescr: payload.jnlLongDesc,
        jnlDescr: payload.jnlLongDesc.replace(/''/g, "'"),
        jnlDrAmts: payload.jnlDrAmts,
        jnlDrCount: payload.jnlDrCount,
        jnlCrAmts: payload.jnlCrAmts,
        jnlCrCount: payload.jnlCrCount,
        legs: payload.legs,
      });
      payload.mode = "new";
      this.commit("cmtJrnlLegs", payload);
    },
    cmtNewJournal(state, payload) {
      console.log("cmtNewJournal", payload);
      // store.commit('updateJnlCount');
      console.log("payload newJournal", payload);
      if (this.getters.getWrkJrnlMode == "EDIT") {
        payload.jnlId = this.getters.getHlJnlId;
      }
      // Assign the new JnlId to each leg:
      if (this.getters.getWrkJrnlMode == "NEW") {
        payload.legs.forEach(function (leg) {
          leg.jnlId = payload.jnlId;
        });
      }

      // If a ledger has no journals, create a bucket for them.
      if (!state.uldata.uldata.currLedger.journals) {
        state.uldata.uldata.currLedger.journals = [];
      }
      state.uldata.uldata.currLedger.journals.push({
        // ledgerId: parseInt(this.getters.getCurrLedgerId),
        ledgerId: this.getters.getCurrLedgerId,
        // jnlId: store.getters.getJournalCount,
        // jnlKey: payload.jnlKey,
        jnlId: payload.jnlId,
        jnlNo: payload.jnlNo,
        jnlDate: payload.jnlDate,
        jnlBeing: payload.jnlBeing.replace(/''/g, "'"),
        jnlDescr: payload.jnlLongDesc.replace(/''/g, "'"),
        jnlDrAmts: payload.jnlDrAmts,
        jnlDrCount: payload.jnlDrCount,
        jnlCrAmts: payload.jnlCrAmts,
        jnlCrCount: payload.jnlCrCount,
        legs: payload.legs,
      });
      payload.mode = "new";
      this.commit("cmtJrnlLegs", payload);

      let jrnls = this.getters.getJournals;
      console.log("jrnls after insert", jrnls);

      let jrnl_id = "jnl_";
      // Highlight the newly created journal:
      console.log("WrkJrnlMode", this.getters.getWrkJrnlMode);
      if (this.getters.getWrkJrnlMode == "NEW") {
        console.log("this is a NEW journal, payload", payload);
        this.commit("clearHlights");
        this.commit("updateHlJnlId", payload.jnlId);
        this.commit("updateHlJnlIdx", jrnls.length - 1);
        this.commit("updateHlJnlNo", payload.jnlNo);
        let acs = [];
        payload.legs.forEach(function (arrItem) {
          let x = arrItem.accId;
          acs.push(x);
        });
        jrnl_id = jrnl_id + payload.jnlId;

        console.log("new / jrnlid", jrnl_id, this.getters.getNewJnlId);

        this.commit("updateHlJnlAcIds", acs);
      }
      console.log("hlights after insert", this.getters.getHlights);

      if (this.getters.getWrkJrnlMode == "EDIT") {
        jrnl_id = jrnl_id + this.getters.getHlJnlId;
      }
      // let jrnl_id = payload.newJnlId;
      console.log("jrnl_id", jrnl_id);
      // let el = document.getElementById(jrnl_id);
      // el.scrollHeight;

      if (this.getters.getWrkJrnlMode == "EDIT") {
        setTimeout(() => {
          // let el = document.getElementById(jrnl_id);
          console.log("scrolling...");
          // let el = document.getElementById("jrnl-list");
          // el.scrollTop = el.scrollHeight + 150;
          // el.scrollTo(1000000);
          // el.scrollTo(0, document.getElementById("jrnl-list").scrollHeight);
          // el.scrollIntoView;
        }, 0);
      }

      // this.$nextTick(() => {
      //   let el = document.getElementById("jnl_90");
      //   console.log("el", el);
      //   el.scrollIntoView();
      // });
      // document.querySelector(`[id=${CSS.escape(jrnl_id)}]`).scrollIntoView();
      // let doc = document.querySelector(`[id="` + jrnl_id + `"]`);
      // let doc = document.querySelector('[id="' + jrnl_id + '"]');
      // let doc = document.querySelector("#jnl_72");
      // console.log("the jrnl", doc);

      // for (let leg of payload.legs) {
      //   // console.log('pay leg', leg.legAmount);
      //   for (let c of this.getters.getTaccounts) {
      //     // const obj = c.accounts.find(o => o.accId === payload.acc);
      //     // console.log('inner loop', c.catName, leg.legAmount);
      //     if (c.accounts.length > 0) {
      //       const obj = c.accounts.find((o) => o.accId === leg.accId);
      //       if (obj) {
      //         // Update Account Balance
      //         obj.sign = leg.legDrCr;
      //         obj.amt = leg.legAmount;
      //         const payload = { acObj: obj, addSub: "add" };
      //         this.commit("updateAcBal", payload);

      //         // store.commit('updateAcBal', 'add', obj);

      //         // Push leg to T Account
      //         if (leg.legDrCr === "D") {
      //           obj.debits.push(leg);
      //         } else {
      //           obj.credits.push(leg);
      //         }

      //         // Update account no_of_legs
      //         if (obj.sign === "D") {
      //           this.commit("updateAcNoDrs", payload);
      //         } else {
      //           this.commit("updateAcNoCrs", payload);
      //         }
      //         this.commit("updateCatFields", payload);
      //       } else {
      //         // console.log('acc object not found');
      //       }
      //     }
      //   }
      // }

      // // Update the Ledger totalDr and totalCrs
      // this.commit("updateDrCrBalTotals");

      // // Highlight the new journal:
      // this.commit("removeAllHlights");
      // console.log("update jrnl store", payload);
      // this.commit("updateJnlNo", payload.jnlId);
      // this.commit("updateJnlKey", payload.jnlKey);
      // let idx = this.getters.getJournals.length;
      // this.commit("updateJnlIdx", idx);
      // let acs = [];
      // payload.legs.forEach(function(arrItem) {
      //   let x = arrItem.accId;
      //   acs.push(x);
      // });
      // this.commit("updateJnlAcIds", acs);
    },
    cmtJrnlLegs(state, payload) {
      console.log("cmtJrnlLegs payload", payload);
      let types = this.getters.getTypes;
      console.log("types", types);
      for (let lg = 0; lg < payload.legs.length; lg++) {
        // console.log("value of leg before chng", payload.legs[lg].legNote);
        payload.legs[lg].legNote = payload.legs[lg].legNote.replace(/''/g, "'");
        loop1: for (let t = 0; t < types.length; t++) {
          // console.log("type ids", types[t].typeId, payload.legs[lg]);
          if (types[t].typeId == payload.legs[lg].typeId) {
            // console.log("typeId found", types[t].typeId, "index", t);
            if (payload.mode == "new") {
              if (payload.legs[lg].legDrCr == "D") {
                types[t].typeBal = types[t].typeBal + payload.legs[lg].legAmount;
                types[t].typeNoLegs = types[t].typeNoLegs + 1;
              }
              if (payload.legs[lg].legDrCr == "C") {
                types[t].typeBal = types[t].typeBal - payload.legs[lg].legAmount;
                types[t].typeNoLegs = types[t].typeNoLegs + 1;
              }
            }
            if (payload.mode == "delete") {
              if (payload.legs[lg].legDrCr == "D") {
                types[t].typeBal = types[t].typeBal - payload.legs[lg].legAmount;
                types[t].typeNoLegs = types[t].typeNoLegs - 1;
                if (payload.jnlExcl == true) {
                  types[t].typeExclBal = types[t].typeExclBal - payload.legs[lg].legAmount;
                  types[t].typeExclNoLegs = types[t].typeExclNoLegs - 1;
                }
              }
              if (payload.legs[lg].legDrCr == "C") {
                types[t].typeBal = types[t].typeBal + payload.legs[lg].legAmount;
                types[t].typeNoLegs = types[t].typeNoLegs - 1;
                if (payload.jnlExcl == true) {
                  types[t].typeExclBal = types[t].typeExclBal + payload.legs[lg].legAmount;
                  types[t].typeExclNoLegs = types[t].typeExclNoLegs - 1;
                }
              }
            }
            if (payload.fld == "exclude") {
              if (payload.val == true) {
                types[t].typeExclNoLegs = types[t].typeExclNoLegs + 1;

                if (payload.legs[lg].legDrCr == "D") {
                  types[t].typeExclBal = types[t].typeExclBal + payload.legs[lg].legAmount;
                }
                if (payload.legs[lg].legDrCr == "C") {
                  types[t].typeExclBal = types[t].typeExclBal - payload.legs[lg].legAmount;
                }
              }
              if (payload.val == false) {
                types[t].typeExclNoLegs = types[t].typeExclNoLegs - 1;

                if (payload.legs[lg].legDrCr == "D") {
                  types[t].typeExclBal = types[t].typeExclBal - payload.legs[lg].legAmount;
                }
                if (payload.legs[lg].legDrCr == "C") {
                  types[t].typeExclBal = types[t].typeExclBal + payload.legs[lg].legAmount;
                }
              }
            }
            if (types[t].categories != null) {
              for (let c = 0; c < types[t].categories.length; c++) {
                if (types[t].categories[c].catId == payload.legs[lg].catId) {
                  // console.log("catId found", types[t].categories[c].catId, "index", c);
                  if (payload.mode == "new") {
                    if (payload.legs[lg].legDrCr == "D") {
                      types[t].categories[c].catBal = types[t].categories[c].catBal + payload.legs[lg].legAmount;

                      types[t].categories[c].catNoDrLegs = types[t].categories[c].catNoDrLegs + 1;
                    }
                    if (payload.legs[lg].legDrCr == "C") {
                      types[t].categories[c].catBal = types[t].categories[c].catBal - payload.legs[lg].legAmount;

                      types[t].categories[c].catNoCrLegs = types[t].categories[c].catNoCrLegs + 1;
                    }
                  }

                  if (payload.mode == "delete") {
                    if (payload.legs[lg].legDrCr == "D") {
                      types[t].categories[c].catBal = types[t].categories[c].catBal - payload.legs[lg].legAmount;
                      types[t].categories[c].catNoDrLegs = types[t].categories[c].catNoDrLegs - 1;

                      if (payload.jnlExcl == true) {
                        types[t].categories[c].catExclBal = types[t].categories[c].catExclBal - payload.legs[lg].legAmount;

                        types[t].categories[c].catExclNoDrLegs = types[t].categories[c].catExclNoDrLegs - 1;
                      }
                    }
                    if (payload.legs[lg].legDrCr == "C") {
                      types[t].categories[c].catBal = types[t].categories[c].catBal + payload.legs[lg].legAmount;
                      types[t].categories[c].catNoCrLegs = types[t].categories[c].catNoCrLegs - 1;

                      if (payload.jnlExcl == true) {
                        types[t].categories[c].catExclBal = types[t].categories[c].catExclBal + payload.legs[lg].legAmount;

                        types[t].categories[c].catExclNoCrLegs = types[t].categories[c].catExclNoCrLegs - 1;
                      }
                    }
                  }

                  if (payload.fld == "exclude") {
                    if (payload.val == true) {
                      if (payload.legs[lg].legDrCr == "D") {
                        types[t].categories[c].catExclBal = types[t].categories[c].catExclBal + payload.legs[lg].legAmount;

                        types[t].categories[c].catExclNoDrLegs = types[t].categories[c].catExclNoDrLegs + 1;
                      }
                      if (payload.legs[lg].legDrCr == "C") {
                        types[t].categories[c].catExclBal = types[t].categories[c].catExclBal - payload.legs[lg].legAmount;

                        types[t].categories[c].catExclNoCrLegs = types[t].categories[c].catExclNoCrLegs + 1;
                      }
                    }

                    if (payload.val == false) {
                      if (payload.legs[lg].legDrCr == "D") {
                        types[t].categories[c].catExclBal = types[t].categories[c].catExclBal - payload.legs[lg].legAmount;

                        types[t].categories[c].catExclNoDrLegs = types[t].categories[c].catExclNoDrLegs - 1;
                      }
                      if (payload.legs[lg].legDrCr == "C") {
                        types[t].categories[c].catExclBal = types[t].categories[c].catExclBal + payload.legs[lg].legAmount;

                        types[t].categories[c].catExclNoCrLegs = types[t].categories[c].catExclNoCrLegs - 1;
                      }
                    }
                  }

                  if (types[t].categories[c].accounts != null) {
                    for (let a = 0; a < types[t].categories[c].accounts.length; a++) {
                      // console.log("acc id", types[t].categories[c].accounts[a].accId, "payl ac", payload.legs[lg].accId);
                      if (!types[t].categories[c].accounts[a].debits) {
                        types[t].categories[c].accounts[a].debits = [];
                      }
                      if (!types[t].categories[c].accounts[a].credits) {
                        types[t].categories[c].accounts[a].credits = [];
                      }

                      if (types[t].categories[c].accounts[a].accId == payload.legs[lg].accId) {
                        console.log("hello", types[t].categories[c].accounts[a].accId);

                        if (payload.fld == "exclude") {
                          // console.log("account", types[t].categories[c].accounts[a]);

                          if (payload.val == true) {
                            if (payload.legs[lg].legDrCr == "D") {
                              types[t].categories[c].accounts[a].accExclBal += payload.legs[lg].legAmount;
                              types[t].categories[c].accounts[a].accExclNoDrLegs = types[t].categories[c].accounts[a].accExclNoDrLegs + 1;
                            }
                            if (payload.legs[lg].legDrCr == "C") {
                              types[t].categories[c].accounts[a].accExclBal -= payload.legs[lg].legAmount;
                              types[t].categories[c].accounts[a].accExclNoCrLegs = types[t].categories[c].accounts[a].accExclNoCrLegs + 1;
                            }
                          }

                          if (payload.val == false) {
                            if (payload.legs[lg].legDrCr == "D") {
                              types[t].categories[c].accounts[a].accExclBal -= payload.legs[lg].legAmount;
                              types[t].categories[c].accounts[a].accExclNoDrLegs = types[t].categories[c].accounts[a].accExclNoDrLegs - 1;
                            }
                            if (payload.legs[lg].legDrCr == "C") {
                              types[t].categories[c].accounts[a].accExclBal += payload.legs[lg].legAmount;
                              types[t].categories[c].accounts[a].accExclNoCrLegs = types[t].categories[c].accounts[a].accExclNoCrLegs - 1;
                            }
                          }
                        }

                        if (payload.mode == "new") {
                          if (payload.legs[lg].legDrCr == "D") {
                            types[t].categories[c].accounts[a].accBal = types[t].categories[c].accounts[a].accBal + payload.legs[lg].legAmount;

                            types[t].categories[c].accounts[a].accNoDrLegs = types[t].categories[c].accounts[a].accNoDrLegs + 1;

                            types[t].categories[c].accounts[a].debits.push(payload.legs[lg]);
                            this.commit("sortAcLegs", types[t].categories[c].accounts[a].debits);
                          }
                          if (payload.legs[lg].legDrCr == "C") {
                            types[t].categories[c].accounts[a].accBal = types[t].categories[c].accounts[a].accBal - payload.legs[lg].legAmount;

                            types[t].categories[c].accounts[a].accNoCrLegs = types[t].categories[c].accounts[a].accNoCrLegs + 1;
                            types[t].categories[c].accounts[a].credits.push(payload.legs[lg]);
                            this.commit("sortAcLegs", types[t].categories[c].accounts[a].credits);
                          }
                        }

                        if (payload.mode == "delete") {
                          if (payload.legs[lg].legDrCr == "D") {
                            types[t].categories[c].accounts[a].accBal = types[t].categories[c].accounts[a].accBal - payload.legs[lg].legAmount;

                            types[t].categories[c].accounts[a].accNoDrLegs = types[t].categories[c].accounts[a].accNoDrLegs - 1;

                            if (payload.jnlExcl == true) {
                              types[t].categories[c].accounts[a].accExclBal = types[t].categories[c].accounts[a].accExclBal - payload.legs[lg].legAmount;

                              types[t].categories[c].accounts[a].accExclNoDrLegs = types[t].categories[c].accounts[a].accExclNoDrLegs - 1;
                            }

                            // this.commit("sortAcLegs", types[t].categories[c].accounts[a].debits);
                          }
                          if (payload.legs[lg].legDrCr == "C") {
                            types[t].categories[c].accounts[a].accBal = types[t].categories[c].accounts[a].accBal + payload.legs[lg].legAmount;

                            types[t].categories[c].accounts[a].accNoCrLegs = types[t].categories[c].accounts[a].accNoCrLegs - 1;

                            if (payload.jnlExcl == true) {
                              types[t].categories[c].accounts[a].accExclBal = types[t].categories[c].accounts[a].accExclBal + payload.legs[lg].legAmount;

                              types[t].categories[c].accounts[a].accExclNoCrLegs = types[t].categories[c].accounts[a].accExclNoCrLegs - 1;
                            }
                            // this.commit("sortAcLegs", types[t].categories[c].accounts[a].credits);
                          }
                        }

                        if (payload.legs[lg].legDrCr == "D") {
                          console.log("before dr ac leg delete", types[t].categories[c].accounts[a].debits, payload.legs[lg].legId);
                          for (let d = 0; d < types[t].categories[c].accounts[a].debits.length; d++) {
                            if (types[t].categories[c].accounts[a].debits[d].legId == payload.legs[lg].legId) {
                              console.log("delete ac dr leg found", types[t].categories[c].accounts[a].debits[d], payload.mode);
                              if (payload.mode == "delete") {
                                types[t].categories[c].accounts[a].debits.splice(d, 1);
                              }

                              if (payload.fld == "exclude") {
                                // console.log("excl val", payload.val);
                                types[t].categories[c].accounts[a].debits[d].jnlExcl = payload.val;
                              }
                              if (payload.fld == "date") {
                                console.log("drs before date update", types[t].categories[c].accounts[a].debits);
                                let strDate = payload.val;
                                let fmtDate = strDate.substring(8) + "-" + strDate.substring(5, 7) + "-" + strDate.substring(2, 4);

                                types[t].categories[c].accounts[a].debits[d].jnlDate = fmtDate;
                                types[t].categories[c].accounts[a].debits[d].jnlFullDate = payload.val;
                                // console.log("drs after date update", types[t].categories[c].accounts[a].debits);

                                this.commit("sortAcLegs", types[t].categories[c].accounts[a].debits);
                              }
                            }
                          }
                        }
                        if (payload.legs[lg].legDrCr == "C") {
                          console.log("before updating cr leg", payload.legs[lg]);

                          for (let cr = 0; cr < types[t].categories[c].accounts[a].credits.length; cr++) {
                            if (types[t].categories[c].accounts[a].credits[cr].legId == payload.legs[lg].legId) {
                              // console.log("cr leg found", types[t].categories[c].accounts[a].credits[cr]);
                              if (payload.mode == "delete") {
                                types[t].categories[c].accounts[a].credits.splice(cr, 1);
                              }

                              if (payload.fld == "exclude") {
                                // console.log("excl val", payload.val);
                                types[t].categories[c].accounts[a].credits[cr].jnlExcl = payload.val;
                              }
                              if (payload.fld == "date") {
                                let strDate = payload.val;
                                let fmtDate = strDate.substring(8) + "-" + strDate.substring(5, 7) + "-" + strDate.substring(2, 4);
                                types[t].categories[c].accounts[a].credits[cr].jnlDate = fmtDate;
                                types[t].categories[c].accounts[a].credits[cr].jnlFullDate = payload.val;
                                this.commit("sortAcLegs", types[t].categories[c].accounts[a].credits);
                              }
                            }
                          }
                        }

                        break loop1;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      this.commit("updateCalcDrCrBalTotals");
    },
    cmtJrnlLegs_old(state, payload) {
      const types = this.getters.getTypes;
      // console.log("types", types);
      // console.log("payload received", payload);
      console.log("payload legs", payload.legs);
      for (let lg = 0; lg < payload.legs.length; lg++) {
        for (let t = 0; t < types.length; t++) {
          // console.log("type ids", types[t].typeId, payload.legs[lg]);
          if (types[t].typeId == payload.legs[lg].typeId) {
            // console.log("typeId found", types[t].typeId, "index", t);
            if (types[t].categories != null) {
              for (let c = 0; c < types[t].categories.length; c++) {
                if (types[t].categories[c].catId == payload.legs[lg].catId) {
                  // console.log("catId found", types[t].categories[c].catId, "index", c);

                  if (types[t].categories[c].accounts != null) {
                    for (let a = 0; a < types[t].categories[c].accounts.length; a++) {
                      // console.log("acc id", types[t].categories[c].accounts[a].accId, "payl ac", payload.legs[lg].accId);

                      if (types[t].categories[c].accounts[a].accId == payload.legs[lg].accId) {
                        if (payload.fld == "exclude") {
                          // console.log("account", types[t].categories[c].accounts[a]);

                          if (payload.val == true) {
                            if (payload.legs[lg].legDrCr == "D") {
                              state.uldata.uldata.currLedger.types[t].categories[c].accounts[a].accExclBal += payload.legs[lg].legAmount;
                            }
                            if (payload.legs[lg].legDrCr == "C") {
                              state.uldata.uldata.currLedger.types[t].categories[c].accounts[a].accExclBal -= payload.legs[lg].legAmount;
                            }
                          }

                          if (payload.val == false) {
                            if (payload.legs[lg].legDrCr == "D") {
                              state.uldata.uldata.currLedger.types[t].categories[c].accounts[a].accExclBal -= payload.legs[lg].legAmount;
                            }
                            if (payload.legs[lg].legDrCr == "C") {
                              state.uldata.uldata.currLedger.types[t].categories[c].accounts[a].accExclBal += payload.legs[lg].legAmount;
                            }
                          }
                        }
                        if (payload.legs[lg].legDrCr == "D") {
                          console.log("before updating dr leg", payload.legs[lg]);
                          for (let d = 0; d < types[t].categories[c].accounts[a].debits.length; d++) {
                            if (types[t].categories[c].accounts[a].debits[d].legId == payload.legs[lg].legId) {
                              // console.log("dr leg found", types[t].categories[c].accounts[a].debits[d]);
                              if (payload.fld == "exclude") {
                                // console.log("excl val", payload.val);
                                types[t].categories[c].accounts[a].debits[d].jnlExcl = payload.val;
                              }
                              if (payload.fld == "date") {
                                // console.log("drs before date update", types[t].categories[c].accounts[a].debits);
                                let strDate = payload.val;
                                let fmtDate = strDate.substring(8) + "-" + strDate.substring(5, 7) + "-" + strDate.substring(2, 4);

                                types[t].categories[c].accounts[a].debits[d].jnlDate = fmtDate;
                                types[t].categories[c].accounts[a].debits[d].jnlFullDate = payload.val;
                                // console.log("drs after date update", types[t].categories[c].accounts[a].debits);

                                this.commit("sortAcLegs", types[t].categories[c].accounts[a].debits);
                              }
                            }
                          }
                        }

                        if (payload.legs[lg].legDrCr == "C") {
                          console.log("before updating cr leg", payload.legs[lg]);

                          for (let cr = 0; cr < types[t].categories[c].accounts[a].credits.length; cr++) {
                            if (types[t].categories[c].accounts[a].credits[cr].legId == payload.legs[lg].legId) {
                              // console.log("cr leg found", types[t].categories[c].accounts[a].credits[cr]);
                              if (payload.fld == "exclude") {
                                // console.log("excl val", payload.val);
                                types[t].categories[c].accounts[a].credits[cr].jnlExcl = payload.val;
                              }
                              if (payload.fld == "date") {
                                let strDate = payload.val;
                                let fmtDate = strDate.substring(8) + "-" + strDate.substring(5, 7) + "-" + strDate.substring(2, 4);
                                types[t].categories[c].accounts[a].credits[cr].jnlDate = fmtDate;
                                types[t].categories[c].accounts[a].credits[cr].jnlFullDate = payload.val;
                                this.commit("sortAcLegs", types[t].categories[c].accounts[a].credits);
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    sortAllAcLegs() {
      const types = this.getters.getTypes;
      if (types) {
        for (let t = 0; t < types.length; t++) {
          if (types[t].categories) {
            for (let c = 0; c < types[t].categories.length; c++) {
              if (types[t].categories[c].accounts) {
                for (let a = 0; a < types[t].categories[c].accounts.length; a++) {
                  if (types[t].categories[c].accounts[a].debits) {
                    this.commit("sortAcLegs", types[t].categories[c].accounts[a].debits);
                  }
                  if (types[t].categories[c].accounts[a].credits) {
                    this.commit("sortAcLegs", types[t].categories[c].accounts[a].credits);
                  }
                  // if (types[t].categories[c].accounts[a].legs) {
                  //   console.log("sorting legs now", types[t].categories[c].accounts[a].legs);
                  //   this.commit("sortAcLegs", types[t].categories[c].accounts[a].legs);
                  // }
                }
              }
            }
          }
        }
      }
    },
    sortAcLegs(state, payload) {
      // console.log("sort payload", payload);
      let srtLgs = payload.sort(function (a, b) {
        // payload.sort(function (a, b) {
        // return a.jnlFullDate.localeCompare(b.jnlFullDate);
        // return a.jnlFullDate.localeCompare(b.jnlFullDate) || a.jnlNo - b.jnlNo;
        // return a.jnlFullDate.localeCompare(b.jnlFullDate) || a.jnlNo > b.jnlNo ? 1 : a.jnlNo < b.jnlNo ? -1 : 0;
        return a.jnlFullDate.localeCompare(b.jnlFullDate) || a.jnlNo > b.jnlNo ? 1 : a.jnlNo < b.jnlNo ? -1 : 0 || a.legNo > b.legNo ? 1 : a.legNo < b.legNo ? -1 : 0;
      });
      // state.uldata.uldata.currLedger.journals = srtJnls;
      console.log("sortAcLegs", srtLgs);
      // return srtLgs;
      payload = srtLgs;
    },
    setShortEditJnl(state, payload) {
      // console.log("setShortEditJnl", payload);
      // console.log("getUlJrnls", store.getters.getUlDataJrnls);
      const jrnls = this.getters.getJournals;
      // const paylLegs = {
      //   ...payload.legs,
      //   jnlExcl: payload.jnlExcl
      // };
      // console.log('paylLegs', paylLegs);

      for (let i = 0; i < jrnls.length; i++) {
        if (jrnls[i].jnlId == payload.jnlId) {
          if (payload.fld == "being") {
            jrnls[i].jnlBeing = payload.val;
          }

          if (payload.fld == "description") {
            jrnls[i].jnlDescr = payload.val;
          }

          if (payload.fld == "exclude") {
            jrnls[i].jnlExcl = payload.val;
            // console.log("jnlLegs", jnlLegs);
          }
          if (payload.fld == "date") {
            let strDate = payload.val;
            let fmtDate = strDate.substring(8) + "-" + strDate.substring(5, 7) + "-" + strDate.substring(2, 4);
            // console.log("payl date", strDate);
            // console.log("payl date fmt", strDate.substring(8) + "-" + strDate.substring(5, 7) + "-" + strDate.substring(2, 4));
            jrnls[i].jnlDate = fmtDate;
            jrnls[i].jnlFullDate = payload.val;
            // store.commit("setJnlAcLegs", {
            //   jnlId: payload.jnlId,
            //   fld: "date",
            //   val: payload.val,
            // });
          }
          const jnlLegs = {
            jnlId: jrnls[i].jnlId,
            legs: [...jrnls[i].legs],
            fld: payload.fld,
            val: payload.val,
            mode: "update",
          };
          console.log("jrnl Legs", jnlLegs);
          console.log("jrnls after shrt updates:", jrnls);
          this.commit("cmtJrnlLegs", jnlLegs);
        }
      }
    },
    cmtDelJournal() {
      console.log("deljournal payload");

      // payload is the array index of the journal
      const delJnlIdx = this.getters.getHlJnlIdx;
      console.log("del jrnl list index", delJnlIdx);
      // const delJnlId = context.getters.getHlJnlId;

      // Delete the journal
      const jnlList = this.getters.getJournals;
      let delJnl = {};
      for (let i = 0; i < jnlList.length; i++) {
        if (i == delJnlIdx) {
          delJnl = jnlList[i];
          console.log("del journal loop", jnlList[i]);
          jnlList.splice(i, 1);
        }
      }
      delJnl.mode = "delete";

      console.log("payload");
      console.log("jnlList", jnlList);
      console.log("the jrnl to be deleted", delJnl);
      if (this.getters.getWrkJrnlMode == "NEW") {
        this.commit("clearHlights");
      }
      this.commit("cmtJrnlLegs", delJnl);
    },
    setLoadUsers(state, payload) {
      let ld = this.getters.getIsSup;
      if (ld) {
        state.users = [];
        state.users = payload;
      }
      if (!ld) {
        state.users = [];
      }
    },
    setUser(state, payload) {
      state.auth.token = payload.token;
      state.auth.tokenExpiration = payload.tokenExpiration;
      state.auth.userId = payload.userId;
      state.auth.currLedgerId = payload.currLedgerId;
      state.auth.userName = payload.userName;
      state.auth.active = payload.active;
      state.auth.supervisor = payload.supervisor;
      state.auth.isLoggedIn = payload.isLoggedIn;
    },
  },
  actions: {
    loadLedgerData(context, payload) {
      // const ledData = { userId: 1, ledgerId: 1 };
      // let url = "http://localhost:3000/ledger/ledgerData/" + payload.userId + "/" + payload.ledId;
      let url = "";
      if (this.getters.getIsLoggedIn) {
        url = process.env.VUE_APP_BCKURL + "ledger/ledgerData/" + payload.userId + "/" + payload.ledId;
      }

      if (!this.getters.getIsLoggedIn) {
        // The DEMO mode
        url = process.env.VUE_APP_BCKURL + "ledger/ledgerDataDEMO/" + this.getters.getDemoUserId + "/" + payload.ledId;
      }

      const token = context.getters.token;
      context.commit("setUlDataLoading", true);
      fetch(url, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          // if (res.status !== 200) {
          //   throw new Error(res.message || "Failed to fetch ledgerData");
          // }
          if (!res.ok) {
            const error = new Error("Ledger Data: " + res.url + " : " + res.status + " - " + res.statusText || "Failed to fetch Ledger Data!");

            throw error;
          }

          return res.json();
        })
        .then((resData) => {
          context.commit("setUlData", resData[0]);
          context.commit("setUlDataLoading", false);

          console.log("getUlData", this.getters.getUlData);

          // context.commit("sortTypes");
          context.commit("sortTypesAll");
          // context.commit("sortCategories");
          // let jrnls = this.getters.getJournals;
          context.commit("sortJournals");
          // Sort all account legs
          context.commit("sortAllAcLegs");
          // Sort the ledger list
          const ledgerList = this.getters.getLedgers;
          context.commit("sortLedgerList", ledgerList);

          context.commit("setAuthUserId", this.getters.getCurrLedgerUserId);

          // Calaculate the Ledger Dr and Cr totals:
          context.commit("updateCalcDrCrBalTotals");
        })
        // .catch(this.catchError);
        .catch(function (error) {
          context.commit("setError", error);
          console.log("error from action", error);
        });
    },
    genExcel(context, payload) {
      // let url = "http://localhost:3000/file/createExcel/" + payload.userId + "/" + payload.ledId;
      let url = process.env.VUE_APP_BCKURL + "file/createExcel/" + payload.userId + "/" + payload.ledId;
      fetch(url, {
        method: "GET",
        // mode: "cors",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (res.status !== 200) {
            throw new Error(res.message || "Failed to fetch file");
          }
          // return res.json();
          // res.responseType = "blob";
          console.log("res data", res);
          res.blob().then((excelBlob) => {
            // console.log("genExcel", resData);
            const url = URL.createObjectURL(excelBlob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "test_filename");
            document.body.appendChild(link);
            link.click();
          });
        })
        .catch((error) => {
          return error;
        });
    },
    genPdf(context, payload) {
      // let url = "http://localhost:3000/file/createPdf/" + payload.userId + "/" + payload.ledId;
      let url = process.env.VUE_APP_BCKURL + "file/createPdf/" + payload.userId + "/" + payload.ledId;
      fetch(url, {
        method: "GET",
        // mode: "cors",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (res.status !== 200) {
            throw new Error(res.message || "Failed to fetch file");
          }
          // return res.json();
          // res.responseType = "blob";
          console.log("res data", res);
          res.blob().then((excelBlob) => {
            // console.log("genExcel", resData);
            const url = URL.createObjectURL(excelBlob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "test_filename");
            document.body.appendChild(link);
            link.click();
          });
        })
        .catch((error) => {
          return error;
        });
    },
    insertLedger(context, payload) {
      if (this.getters.getIsLoggedIn) {
        const postLed = { ...payload };
        // let url = "http://localhost:3000/ledger/newLedger";
        let url = process.env.VUE_APP_BCKURL + "ledger/newLedger";
        let method = "POST";
        return fetch(url, {
          method: method,
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            postLed,
          }),
        })
          .then((res) => {
            if (!res.ok) {
              console.log("res", res);
              const error = new Error(res.url + " : " + res.status + " - " + res.statusText || "Failed to create new ledger.");

              throw error;
              // return Promise.reject(error);
            } else {
              console.log("res is ok: ", res);
            }
            return res.json();
          })
          .then((resData) => {
            console.log("the response", resData);
            if (this.getters.getRetrieveLedsAfterInsert) {
              context.commit("setAuthCurrLedId", resData.newId);
              context.dispatch("loadLedgerData", { userId: this.getters.getAuthUserId, ledId: this.getters.getAuthCurrLedId });
            }
            // console.log("the response", resData.newId);
            // const newLed = {
            //   ...postLed,
            //   ledId: resData.newId,
            // };
            // context.commit("newJournal", newLed);

            // newJrnlId = resData.newId;
            // console.log('newly assigned newJrnlId', newJrnlId);
            // console.log('ledger id', resData.ledgerId);
            // console.log('ledgers', store.getters.getLedgers);
            // console.log('ledgers', postLed);

            // console.log('newJrnl', newJrnl);
          });
        // .catch((error) => {
        //   console.log(error);
        // });
        // update store:

        // console.log('newJrnl', newJrnl);
        // context.commit("updateJnlCount");

        // context.commit('removeAllHlights');
        // console.log("new jrnl", payload);
        // console.log("all jrnls", context.getters.getJournals);
      }
    },
    updateLedger(context, payload) {
      if (this.getters.getIsLoggedIn) {
        // let url = "http://localhost:3000/ledger/editLedger";
        let url = process.env.VUE_APP_BCKURL + "ledger/editLedger";
        let method = "PUT";
        return fetch(url, {
          method: method,
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...payload }),
        })
          .then((res) => {
            if (!res.ok) {
              console.log("res", res);
              const error = new Error(res.url + " : " + res.status + " - " + res.statusText || "Failed to update ledger!");

              throw error;
              // return Promise.reject(error);
            } else {
              console.log("res is ok: ", res);
            }
            return res.json();
          })
          .then((resData) => {
            console.log("the response", resData);
            context.commit("updateLedgerList", payload);
            context.commit("updateLedgerDetails", payload);
          });
      }
    },

    updateLedDescr(context, payload) {
      if (this.getters.getIsLoggedIn) {
        console.log("updating short ledger");
      }
      context.commit("setCurrLedgerDescr", payload);
    },
    postShrtEditLed(context, payload) {
      // const editJnl = {
      //   ...payload,
      //   ledgerId: store.getters.getCurrLedgerId
      //   // editJnlKey: editJnlKey
      // };
      if (this.getters.getIsLoggedIn) {
        // console.log("postShrtEditLed", payload);
        // let url = "http://localhost:3000/ledger/shrtEditLedger";
        let url = process.env.VUE_APP_BCKURL + "ledger/shrtEditLedger";
        let method = "PUT";
        return fetch(url, {
          method: method,
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            payload,
          }),
        })
          .then((res) => {
            if (!res.ok) {
              console.log("res", res, res.json());
              const error = new Error(res.url + " : " + res.status + " - " + res.statusText || "Failed to update ledger!");

              throw error;
            } else {
              console.log("res is ok: ", res);
            }
            return res.json();
          })
          .then((resData) => {
            console.log("the response", resData);

            if (payload.fld == "description") {
              context.commit("setCurrLedgerDescr", payload.val);
            }
            if (payload.fld == "notes") {
              context.commit("setCurrLedgerNotes", payload.val);
            }
            if (payload.fld == "bs_title") {
              context.commit("setLedBsTitle", payload.val);
            }
            if (payload.fld == "pl_title") {
              context.commit("setLedPlTitle", payload.val);
            }
            if (payload.fld == "tb_title") {
              context.commit("setLedTbTitle", payload.val);
            }
          });
      }
    },
    deleteLedger(context) {
      // deleteLedger(context){
      const ledId = this.getters.getCurrLedgerId;
      if (this.getters.getIsLoggedIn) {
        let method = "DELETE";
        // let url = "http://localhost:3000/ledger/delLedger";
        let url = process.env.VUE_APP_BCKURL + "ledger/delLedger";

        return fetch(url, {
          method: method,
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // postLed
            id: ledId,
          }),
        })
          .then((res) => {
            if (!res.ok) {
              console.log("res", res);
              const error = new Error(res.url + " : " + res.status + " - " + res.statusText || "Failed to delete ledger!");

              throw error;
            } else {
              console.log("res is ok: ", res);
            }
            return res.json();
          })
          .then(() => {
            context.commit("delLedgerFromList");
            context.commit("setAuthCurrLedId", 0);
            context.commit("delLedger");
          });
      }
    },
    insertCategory(context, payload) {
      const postCat = {
        ...payload,
        led_id: this.getters.getCurrLedgerId,
        userId: this.getters.getCurrLedgerUserId,
      };
      delete postCat.catId;
      delete postCat.initCatType;

      console.log("insertCat postCat", postCat);
      // let url = "http://localhost:3000/taccount/newCategory";
      let url = process.env.VUE_APP_BCKURL + "taccount/newCategory";

      let method = "POST";
      return fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          postCat,
        }),
      })
        .then((res) => {
          // console.log(res);
          if (!res.ok) {
            console.log("res", res);
            const error = new Error(res.url + " : " + res.status + " - " + res.statusText || "Failed to create new ledger.");

            throw error;
            // return Promise.reject(error);
          } else {
            console.log("res is ok: ", res);
          }
          return res.json();
        })
        .then((resData) => {
          // console.log('the response', resData);
          // console.log('cat id', resData.accId);
          // console.log('ledgers', store.getters.getLedgers);
          // console.log('cats', postCat);
          const storeCat = {
            ...postCat,
            id: resData.catId,
          };
          console.log("storeCat", storeCat);
          context.commit("cmtNewCategory", storeCat);
          context.commit("setNewCatId", storeCat.id);
          // context.commit('setNewAcId', storeCat.id);
          // console.log(
          //   'new ac no in index:',
          //   storeCat.id,
          //   store.getters.getNewAcId
          // );
        });
    },
    editCategory(context, payload) {
      const postCat = {
        ...payload,
      };
      // let url = "http://localhost:3000/taccount/editCategory";
      let url = process.env.VUE_APP_BCKURL + "taccount/editCategory";
      let method = "PUT";
      return fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          postCat,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            console.log("res", res);
            const error = new Error(res.url + " : " + res.status + " - " + res.statusText || "Failed to edit category.");

            throw error;
            // return Promise.reject(error);
          } else {
            console.log("res is ok: ", res);
          }
          // return res.json();
        })
        .then(() => {
          context.commit("cmtEditCategory", payload);
        });
    },
    deleteCategory(context, payload) {
      let url = process.env.VUE_APP_BCKURL + "taccount/delCategory";
      let catId = payload;
      let method = "DELETE";
      return fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: catId,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            console.log("res", res);
            const error = new Error(res.url + " : " + res.status + " - " + res.statusText || "Failed to delete category.");

            throw error;
            // return Promise.reject(error);
          } else {
            console.log("res is ok: ", res);
          }
          // return res.json();
        })
        .then(() => {
          console.log("about to cmtDeleteCat");
          context.commit("cmtDeleteCat", catId);
        });
    },
    catMove(context, payload) {
      const moveCat = {
        ...payload,
      };
      // let url = "http://localhost:3000/taccount/editCategorySort";
      let url = process.env.VUE_APP_BCKURL + "taccount/editCategorySort";

      let method = "PUT";
      return fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          moveCat,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            console.log("res", res);
            const error = new Error(res.url + " : " + res.status + " - " + res.statusText || "Failed to delete category.");

            throw error;
            // return Promise.reject(error);
          } else {
            console.log("res is ok: ", res);
          }

          // return res.json();
        })
        .then(() => {
          context.commit("cmtCatMove_1", moveCat);
        });
    },
    insertAccount(context, payload) {
      const postAc = {
        acCat: payload.acCat,
        acNo: payload.acNo,
        acName: payload.acName,
        acNotes: payload.acNotes,
      };

      // let url = "http://localhost:3000/taccount/newAccount";
      let url = process.env.VUE_APP_BCKURL + "taccount/newAccount";

      let method = "POST";
      return fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          postAc,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            console.log("res", res);
            const error = new Error(res.url + " : " + res.status + " - " + res.statusText || "Failed to create new account.");

            throw error;
            // return Promise.reject(error);
          } else {
            console.log("res is ok: ", res);
          }
          // console.log(res);
          return res.json();
        })
        .then((resData) => {
          // console.log('the response', resData);
          // console.log('account id', resData.accId);
          // console.log('ledgers', store.getters.getLedgers);
          // console.log('accounts', postAc);
          const storeAc = {
            ...postAc,
            id: resData.accId,
          };
          console.log("storeAc", storeAc);
          context.commit("cmtNewAccount", storeAc);
          context.commit("setNewAcId", storeAc.id);
          // context.commit("setNewAcId", storeAc.id);
        });
      // .catch((error) => {
      // console.log(error);
      // });
    },

    editAccount(context, payload) {
      const postAc = {
        ...payload,
      };
      // let url = "http://localhost:3000/taccount/editAccount";
      let url = process.env.VUE_APP_BCKURL + "taccount/editAccount";
      let method = "PUT";
      return fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          postAc,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            console.log("res", res);
            const error = new Error(res.url + " : " + res.status + " - " + res.statusText || "Failed to edit account.");

            throw error;
            // return Promise.reject(error);
          } else {
            console.log("res is ok: ", res);
          }
          // return res.json();
        })
        .then(() => {
          context.commit("cmtEditAccount", payload);
        });
    },
    deleteAccount(context, payload) {
      const delAcData = {
        id: payload.acId,
      };
      // let url = "http://localhost:3000/taccount/delAccount";
      let url = process.env.VUE_APP_BCKURL + "taccount/delAccount";

      let method = "DELETE";
      return fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          delAcData,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            console.log("res", res);
            const error = new Error(res.url + " : " + res.status + " - " + res.statusText || "Failed to create new ledger.");

            throw error;
            // return Promise.reject(error);
          } else {
            console.log("res is ok: ", res);
          }
          // return res.json();
        })
        .then(() => {
          context.commit("cmtDeleteAc", payload);
        });
    },
    postNewJournal(context, payload) {
      // console.log('postNewJournal', payload);
      // const postJnl = null;
      // getCurrLedgerId
      const postJnl = { ...payload, ledgerId: this.getters.getCurrLedgerId };
      console.log("postJnl", postJnl);
      context.commit("setIsJrnlPosting", true);
      let newJrnlId = null;
      // let url = "http://localhost:3000/journal/journal";
      let url = process.env.VUE_APP_BCKURL + "journal/journal";
      let method = "POST";
      return fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          postJnl,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            console.log("res", res);
            const error = new Error(res.url + " : " + res.status + " - " + res.statusText || "Failed to fetch!");

            throw error;
            // return Promise.reject(error);
          } else {
            console.log("res is ok: ", res);
          }
          // return res.json();
          return res.json();
        })
        .then((resData) => {
          // console.log("the response", res);
          console.log("the response", resData.newJnlId);
          const theResp = JSON.parse(resData.newJnlData);
          console.log("the response", theResp);
          const newJrnl = {
            ...postJnl,
            // newJnlId: theResp.newJnlId,
          };
          // if (this.getters.getWrkJrnlMode == "EDIT") {
          // debugger;
          // }
          newJrnlId = newJrnl.newJnlId;
          console.log("newly assigned newJrnlId", newJrnlId);
          console.log("newJrnl object", newJrnl);
          for (let jl = 0; jl < newJrnl.legs.length; jl++) {
            for (let rl = 0; rl < theResp.legs.length; rl++) {
              if (newJrnl.legs[jl].legNo == theResp.legs[rl].legNo) {
                // console.log('jnl jl rl', jl)
                newJrnl.legs[jl].jnlId = theResp.newJnlId;
                newJrnl.legs[jl].legId = theResp.legs[rl].legId;
                break;
              }
            }
          }
          newJrnl.jnlId = theResp.newJnlId;
          console.log("newJrnl object after update", newJrnl);

          // console.log('ledger id', resData.ledgerId);
          // console.log('ledgers', store.getters.getLedgers);
          // console.log('ledgers', postLed);

          context.commit("setNewJnlId", theResp.newJnlId);
          this.commit("cmtNewJournal", newJrnl);
          context.commit("setIsJrnlPosting", false);
          context.commit("sortJournals");
        });
      // .catch((error) => {
      //   console.log(error);
      // });
      // update store:
      // console.log("newly assigned newJrnlId", newJrnlId);

      // // console.log('newJrnl', newJrnl);
      // // context.commit("updateJnlCount");

      // // context.commit('removeAllHlights');
      // console.log("new jrnl", payload);
      // console.log("all jrnls", context.getters.getJournals);
    },
    // postEditJournal(context, payload) {
    //   const editJnlId = this.getters.getHlJnlId;

    //   const editJnl = {
    //     ...payload,
    //     ledgerId: this.getters.getCurrLedgerId,
    //     editJnlId: editJnlId,
    //   };
    //   context.dispatch('deleteJournal');
    // },

    postEditJournal_alt(context, payload) {
      const editJnlId = this.getters.getHlJnlId;

      const editJnl = {
        ...payload,
        ledgerId: this.getters.getCurrLedgerId,
        editJnlId: editJnlId,
      };

      // context.commit("cmtDelJournal");

      console.log("editJnl", editJnl);
      // let url = "http://localhost:3000/journal/editJournal";
      let url = process.env.VUE_APP_BCKURL + "journal/editJournal";
      let method = "PUT";
      fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          editJnl,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((resData) => {
          console.log("the response", resData);
          console.log("the response", resData[0][1][0].editJnlData);
          const theResp = JSON.parse(resData[0][1][0].editJnlData);
          console.log("the response", theResp);
          // const newJrnl = {
          // ...postJnl,
          // newJnlId: theResp.newJnlId,
          // };
          console.log("edit jrnl id", editJnlId);
          console.log("editJnl object", editJnl);
          for (let jl = 0; jl < editJnl.legs.length; jl++) {
            for (let rl = 0; rl < theResp.legs.length; rl++) {
              if (editJnl.legs[jl].legNo == theResp.legs[rl].legNo) {
                // console.log('jnl jl rl', jl)
                editJnl.legs[jl].jnlId = editJnlId;
                editJnl.legs[jl].legId = theResp.legs[rl].legId;
                break;
              }
            }
          }
          // newJrnl.jnlId = theResp.newJnlId;
          console.log("Edit Jrnl object after update", editJnl);

          // context.commit("cmtDelJournal");

          // let jrnls = this.getters.getJournals;
          // console.log("jrnls after edit", jrnls);
        })
        .then(() => {
          context.commit("cmtEditJournal", editJnl);
          context.commit("sortJournals");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    postEditJournal(context, payload) {
      // const editJnlIdx = this.getters.getHlJnlIdx;
      const editJnlId = this.getters.getHlJnlId;

      const editJnl = {
        ...payload,
        ledgerId: this.getters.getCurrLedgerId,
        editJnlId: editJnlId,
      };

      // context.commit("cmtDelJournal");

      console.log("editJnl", editJnl);
      // let url = "http://localhost:3000/journal/editJournal";
      let url = process.env.VUE_APP_BCKURL + "journal/editJournal";
      let method = "PUT";
      return fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          editJnl,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            const error = new Error(res.url + " : " + res.status + " - " + res.statusText || "Failed to fetch!");

            throw error;
          }
          return res.json();
        })
        .then((resData) => {
          console.log("the response", resData);
          console.log("the response", resData[0][1][0].editJnlData);
          const theResp = JSON.parse(resData[0][1][0].editJnlData);
          console.log("the response", theResp);
          // const newJrnl = {
          // ...postJnl,
          // newJnlId: theResp.newJnlId,
          // };
          console.log("edit jrnl id", editJnlId);
          console.log("editJnl object", editJnl);
          for (let jl = 0; jl < editJnl.legs.length; jl++) {
            for (let rl = 0; rl < theResp.legs.length; rl++) {
              if (editJnl.legs[jl].legNo == theResp.legs[rl].legNo) {
                // console.log('jnl jl rl', jl)
                editJnl.legs[jl].jnlId = editJnlId;
                editJnl.legs[jl].legId = theResp.legs[rl].legId;
                break;
              }
            }
          }
          // newJrnl.jnlId = theResp.newJnlId;
          console.log("Edit Jrnl object after update", editJnl);

          // console.log('ledger id', resData.ledgerId);
          // console.log('ledgers', store.getters.getLedgers);
          // console.log('ledgers', postLed);

          // context.commit("setNewJnlId", theResp.newJnlId);
          // this.commit("cmtNewJournal", newJrnl);
          // context.commit("setIsJrnlPosting", false);
          // console.log("edit jrnl after promise");
          context.commit("cmtDelJournal");

          // let jrnls = this.getters.getJournals;
          // console.log("jrnls after edit", jrnls);
        })
        .then(() => {
          context.commit("cmtNewJournal", editJnl);
          context.commit("sortJournals");
        });
      // .catch((error) => {
      //   console.log(error);
      // });

      // Delete the highlighted journal from the store
    },

    postShrtEditJnl(context, payload) {
      // const editJnl = {
      //   ...payload,
      //   ledgerId: store.getters.getCurrLedgerId
      //   // editJnlKey: editJnlKey
      // };
      console.log("postShrtEditJnl", payload);
      // let url = "http://localhost:3000/journal/shrtEditJournal";
      let url = process.env.VUE_APP_BCKURL + "journal/shrtEditJournal";
      const token = context.getters.token;
      let method = "PUT";
      return fetch(url, {
        method: method,
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          payload,
        }),
      }).then((res) => {
        if (!res.ok) {
          const error = new Error("Journal Edit Data: " + res.url + " : " + res.status + " - " + res.statusText || "Failed to update Journal Data!");

          throw error;
        }
        context.commit("setShortEditJnl", payload);
      });
    },

    deleteJournal(context) {
      // const delJnlIdx = context.getters.getHlJnlIdx;
      const delJnlId = context.getters.getHlJnlId;
      // console.log(delJnlIdx);
      const delJnlData = {
        id: delJnlId,
      };
      // let url = "http://localhost:3000/journal/delJournal";
      let url = process.env.VUE_APP_BCKURL + "journal/delJournal";
      let method = "DELETE";
      return fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          delJnlData,
        }),
      }).then((res) => {
        if (!res.ok) {
          const error = new Error("Journal Data: " + res.url + " : " + res.status + " - " + res.statusText || "Failed to delete Journal!");

          throw error;
        }
        if (res.ok) {
          context.commit("cmtDelJournal");
        }
      });
    },
    loadUsers(context) {
      // let url = "http://localhost:3000/auth/allUsers";
      let url = process.env.VUE_APP_BCKURL + "auth/allUsers";
      const token = context.getters.token;
      // console.log('loadusers:', token);
      return fetch(url, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          if (!res.ok) {
            const error = new Error("User list error: " + res.url + " : " + res.status + " - " + res.statusText || "Failed to load users!!");

            throw error;
          }

          return res.json();
        })
        .then((resData) => {
          // console.log(resData);
          console.log(resData.users);
          // this.result = resData.jnlLegs;
          // console.log(this.result); getIsSup
          context.commit("setLoadUsers", resData.users); // Call mutation to load db data into store.
        });
      // .catch(this.catchError);
    },
    async signup_old(context, payload) {
      // let url = "http://localhost:3000/auth/signup";
      let url = process.env.VUE_APP_BCKURL + "auth/signup";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          username: payload.username,
          email: payload.email,
          password: payload.password,
          // returnSecureToken: true
        }),
      });
      const responseData = await response.json();
      if (!response.ok) {
        // console.log('ResponseData: ', responseData);
        const error = new Error(responseData.message || "Failed to authenticate");
        throw error;
      }
      console.log("respdata:", responseData);
    },
    signup(context, payload) {
      let url = process.env.VUE_APP_BCKURL + "auth/signup";
      return fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          username: payload.username,
          email: payload.email,
          password: payload.password,
          // returnSecureToken: true
        }),
      }).then((res) => {
        if (!res.ok) {
          const error = new Error("Failed to create user: " + res.status + " - " + res.statusText || "Failed to create user!");

          throw error;
        }
        if (res.ok) {
          // context.commit("cmtDelJournal");
        }
      });
    },
    setAcLegs(context, payload) {
      const acs = context.getters.getAccounts;
      let txns = [];
      for (let ac of acs) {
        console.log("condition ", ac.accId, payload);
        if (ac.accId == payload) {
          // console.log("acs", ac);

          if (ac.debits) {
            // console.log("debits", ac.debits);
            // txns.push(ac.debits);
            for (let lg of ac.debits) {
              // console.log('ac dr leg', ac.debits[l])
              txns.push(lg);
              // console.log(acs[ac].debits[lg]);
            }
          }
          if (ac.credits) {
            // console.log("credits", ac.credits);
            // txns.push(ac.credits);
            for (let lg of ac.credits) {
              txns.push(lg);
              // console.log(acs[ac].debits[lg]);
            }
          }

          // txns.push(acs[ac].credits);
        }
      }
    },
    login(context, payload) {
      // console.log(payload.email);
      // let url = "http://localhost:3000/auth/login";
      let url = process.env.VUE_APP_BCKURL + "auth/login";
      return fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          email: payload.email,
          password: payload.password,
          // returnSecureToken: true
        }),
      })
        .then((res) => {
          console.log("res status", res.status);
          if (res.status === 422) {
            throw new Error("Email address not found.");
          }
          if (res.status === 401) {
            throw new Error("Password incorrect.");
          }
          if (res.status !== 200 && res.status !== 201) {
            // console.log('Error!');
            throw new Error("Could not authenticate you!");
          }
          return res.json();
        })
        .then((resData) => {
          // console.log('Resp Data', resData);
          // console.log('Expires in', resData.expiration);
          context.commit("setUser", {
            token: resData.token,
            tokenExpiration: resData.expiresIn,
            userId: resData.id,
            currLedgerId: resData.currLedgerId,
            userName: resData.userName,
            active: resData.active,
            supervisor: resData.supervisor,
            isLoggedIn: true,
          });
          // context.dispatch("loadLedgers");
          // context.dispatch("loadJournals");
          // context.dispatch("loadTaccounts");

          // if (!resData.supervisor) {
          //   context.dispatch("loadLedgerData", {
          //     userId: resData.id,
          //     ledId: resData.currLedgerId,
          //   });
          // }
          // if (resData.supervisor) {
          //   context.dispatch("loadLedgerData", {
          //     userId: 1,
          //     ledId: 1,
          //   });
          // }
          console.log("Logged in user:", resData);
          // this.router.push("/ledger");
        });
    },

    logout(context) {
      return context.commit("setUser", {
        token: null,
        tokenExpiration: null,
        userId: this.getters.getDemoUserId,
        currLedgerId: this.getters.getDemoCurrLedgerId,
        userName: "DEMO",
        active: null,
        supervisor: false,
        isLoggedIn: false,
      });
      // console.log('logout: userId', store.getters.getUserId);
      // console.log('logout: currLedgerId', store.getters.getCurrLedgerId);
    },
  },
  modules: {},
});
