<template>
  <base-error :show="!!error" title="An error occurred!" @close="handleError">
    <p>{{ error }}</p>
  </base-error>
  <teleport to="body">
    <div @click="tryClose" class="backdrop"></div>
    <dialog open v-if="show" class="dialog">
      <base-dialog title="test dialog" :show="showDialog" :backdropTwo="true" @close="confirmError">
        <template #default>
          <p>This is the default slot.</p>
        </template>
        <template v-slot:actions>
          <base-button @click="confirmError">Okay</base-button>
        </template>
      </base-dialog>
      <header>
        <h2>Maintain Accounts - {{ ledName }}</h2>
      </header>
      <section class="list">
        <table class="listTypes">
          <thead>
            <tr>
              <th>Type</th>
              <th>Category</th>
              <th colspan="2">Account</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(type, tidx) in types" :key="type.typeId">
              <tr class="row-type">
                <td colspan="4" class="cell-type">{{ type.typeName }}</td>
              </tr>
              <template v-for="(cat, index) in type.categories" :key="cat.catId">
                <tr class="row-cat" @click="catSelRow(type.typeId, cat.catId, cat.catName, cat.catDescr, cat.catSort, tidx, index, cat.accounts ? cat.accounts.length : 0, type.categories.length)" :class="{ highlight: cat.catId == selectedCat }">
                  <td></td>
                  <td colspan="3">{{ cat.catName }}</td>
                </tr>
                <template v-for="(ac, acIndex) in cat.accounts" :key="ac.accId">
                  <tr class="row-ac" @click="acSelRow(tidx, index, acIndex, ac.accId, ac.accCatId, ac.accNumber, ac.accName, ac.accNotes, ac.accBal, ac.accNoDrLegs, ac.accNoCrLegs, ac.accExclBal, ac.accExclNoDrLegs, ac.accExclNoCrLegs, type.typeId)" :class="{ highlight: ac.accId == selectedAc }">
                    <td></td>
                    <td></td>
                    <td>{{ ac.accNumber }}</td>
                    <td><span>&nbsp;&nbsp;&nbsp;</span>{{ ac.accName }}</td>
                  </tr>
                </template>
              </template>
            </template>
          </tbody>
        </table>
      </section>
      <section class="fields">
        <menu class="mnuNew">
          <base-button id="btn_new_cat" :disabled="disableBtnNewCat" @click="catAcNew('cat')">New Category</base-button>
          <base-button id="btn_new_ac" :disabled="disableBtnNewAc" @click="catAcNew('ac')">New Account</base-button>
        </menu>
        <div v-if="isProcessing" class="spinner">
          <base-spinner></base-spinner>
        </div>
        <div class="maintMsg" v-if="maintMsg == 'selCatAc'">Select <span class="wrdCat">Category</span> or <span class="wrdAc">Account</span> line in left pane to View / Edit.</div>

        <!-- <div v-if="!catIsDeletable">
          <p class="frmErr">This category contains {{ catNoAcs }} accounts. In order to be deleted, you will first need to delete all its accounts.</p>
        </div> -->
        <div class="maintMsg" v-else-if="maintMsg == 'editCat'">
          <span class="wrdCat">Category</span>
        </div>
        <div class="maintMsg" v-else-if="maintMsg == 'editAc'"><span class="wrdAc">Account</span></div>
        <div class="maintMsg" v-if="maintMsg == 'newCat'">New <span class="wrdCat">Category</span></div>
        <div class="maintMsg" v-if="maintMsg == 'newAc'">New <span class="wrdAc">Account</span></div>
        <div v-if="mode == 'cat'">
          <div class="form-control">
            <label for="type">Type</label>
            <select id="sel_typ" name="typ" v-model="frmCatType.val" :disabled="disableCatFlds || disableEditTypSel">
              <option v-for="type in types" :key="type.typeId" :value="type.typeId" :selected="type.typeId == frmCatType.val">{{ type.typeName }}</option>
            </select>
          </div>
          <div class="form-control">
            <label for="cat">Name</label>
            <input type="text" id="cat_name" v-model.trim="frmCatName.val" :disabled="disableCatFlds" @blur="clearValidity('frmCatName')" />
          </div>
          <p class="fldErr" v-if="!frmCatName.isValLen">Length must not be greater than 50 characters.</p>
          <p class="fldErr" v-if="!frmCatName.isUnique">Name must be unique within Categories.</p>
          <p class="fldErr" v-if="!frmCatName.isNotEmp">Name must not be empty.</p>
          <div class="form-control">
            <label for="catNotes">Notes</label>
            <textarea id="cat_notes" name="catNotes" rows="10" v-model.trim="frmCatNotes.val" :disabled="disableCatFlds" @blur="clearValidity('frmCatNotes')" />
          </div>
          <p class="fldErr" v-if="!frmCatNotes.isValLen">Notes length must not be greater than 2000 characters.</p>

          <button @click="catMove('up')" :disabled="disableBtnCatUp">Move Up</button>
          <button @click="catMove('down')" :disabled="disableBtnCatDown">Move Down</button>
        </div>

        <div v-if="mode == 'ac'">
          <div class="form-control">
            <label for="cat">Category</label>
            <select id="sel_cat" name="cat" v-model="frmAcCat.val" :disabled="disableAcFlds">
              <option v-for="cat in categories" :key="cat.catId" :value="cat.catId" :selected="cat.catId == frmAcCat.val">{{ cat.catName }}</option>
            </select>
          </div>

          <div class="form-control">
            <label for="acNum">Number</label>
            <input id="ac_no" type="text" name="acNum" v-model.trim="frmAcNo.val" :disabled="disableAcFlds" />
          </div>
          <p class="fldErr" v-if="!frmAcNo.isValLen">Length must not be greater than 50 characters.</p>
          <p class="fldErr" v-if="!frmAcNo.isUnique">Number must be unique within Accounts.</p>
          <p class="fldErr" v-if="!frmAcNo.isNotEmp">Number must not be empty.</p>
          <div class="form-control">
            <label for="acName">Name</label>
            <input id="ac_name" type="text" name="acName" v-model.trim="frmAcName.val" :disabled="disableAcFlds" />
          </div>
          <p class="fldErr" v-if="!frmAcName.isValLen">Length must not be greater than 50 characters.</p>
          <p class="fldErr" v-if="!frmAcName.isUnique">Name must be unique within Accounts.</p>
          <p class="fldErr" v-if="!frmAcName.isNotEmp">Name must not be empty.</p>
          <div class="form-control">
            <label for="acNotes">Notes</label>
            <textarea id="ac_notes" name="acNotes" rows="10" v-model.trim="frmAcNotes.val" :disabled="disableAcFlds" />
          </div>
          <p class="fldErr" v-if="!frmAcNotes.isValLen">Notes length must not be greater than 2000 characters.</p>
        </div>
        <div v-if="!formIsValid">
          <p class="frmErr">Please fix the above error(s) and Save again.</p>
        </div>
        <div class="catDel" v-if="showCatDelMsg">
          <p>This category contains {{ catNoAcs }} account<span v-if="catNoAcs > 1">s</span>.</p>
          <br />
          <p>All accounts must be removed from a category before it can be deleted.</p>
        </div>

        <div class="acDel" v-if="showAcDelMsg">
          <p>This account contains {{ acTotNoLegs }} journal leg<span v-if="acTotNoLegs > 1">s</span>.</p>
          <br />
          <p>All journal legs must be removed from an account before it can be deleted.</p>
        </div>

        <div class="lwrBtns">
          <menu class="mnuEditDel">
            <base-button @click="edit" :disabled="disableBtnEdit">Edit</base-button>
            <base-button @click="deleteAcCat" :disabled="disableBtnDelete">Delete</base-button>
          </menu>
          <menu class="mnuSvCncl">
            <base-button id="acMaint_btn_save" @click="save" :disabled="disableBtnSave">Save</base-button>
            <base-button id="acMaint_btn_save_oth" @click="save_oth" :disabled="disableBtnSaveOth">Save &amp; Create Another</base-button>
            <base-button @click="cancel" :disabled="disableBtnCancel">Cancel</base-button>
            <base-button id="acMaint_btn_close" @click="tryClose">Close</base-button>
            <base-button @click="selAcJrnl" :disabled="disableBtnSel">Select</base-button>
            <base-button @click="test1">Test1</base-button>
            <base-button @click="test2">Test2</base-button>
          </menu>
        </div>
      </section>
    </dialog>
  </teleport>
</template>

<script>
export default {
  emits: ["close"],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      mode: "", // 'cat' or 'ac'
      action: "", // 'edit' or 'new'

      isProcessing: false,
      error: null,
      tstTyp: null,

      selectedCat: null,
      frmCatType: { val: "", initVal: "" },
      frmCatName: { val: "", initVal: "", isValLen: true, isUnique: true, isNotEmp: true },
      frmCatNotes: { val: "", initVal: "", isValLen: true },
      typIdx: null,
      catSort: null,
      catIdx: null,
      catEditOrNew: "",
      catNoAcs: 0,
      catIsDeletable: false,
      disableCatFlds: true,

      curTypCatLen: null,

      formIsValid: true,

      saveAnother: false,

      selectedAc: null,
      acIdx: null,
      frmAcCat: { val: "", initVal: "", typ: "" },
      frmAcName: { val: "", initVal: "", isValLen: true, isUnique: true, isNotEmp: true },
      frmAcNo: { val: "", initVal: "", isValLen: true, isUnique: true, isNotEmp: true },
      frmAcNotes: { val: "", initVal: "", isValLen: true },
      acBal: 0,
      acNoDrLegs: 0,
      acNoCrLegs: 0,
      acExclBal: 0,
      acExclNoDrLegs: 0,
      acExclNoCrLegs: 0,
      oldTypeId: null,
      acIsDeletable: "na",
      disableAcFlds: true,
      showAcDelMsg: false,
      showCatDelMsg: false,

      disableBtnNewCat: false,
      disableBtnNewAc: false,
      disableBtnEdit: true,
      disableBtnDelete: true,
      disableBtnSave: true,
      disableBtnSaveOth: true,
      disableBtnCancel: true,
      disableBtnSel: true,
      disableEditTypSel: true,

      disableBtnCatUp: true,
      disableBtnCatDown: true,

      maintMsg: "selCatAc",

      showDialog: false,
    };
  },
  watch: {
    // catNoAcs(newVal) {
    //   if (newVal > 0) this.catIsDeletable = false;
    //   if (newVal == 0) this.catIsDeletable = true;
    // },
    // "frmAcCat.val": function (newVal) {
    //   const cats = this.$store.getters.getCategories;
    //   for (let c = 0; c < cats.length; c++) {
    //     if (cats[c].catId == newVal) {
    //       this.frmAcCat.typ = cats[c].typeId;
    //     }
    //     break;
    //   }
    // },
  },
  computed: {
    acTotNoLegs() {
      return this.acNoDrLegs + this.acNoCrLegs;
    },
    ledgerId() {
      return this.$store.getters.getCurrLedgerId;
    },
    ledName() {
      return this.$store.getters.getCurrLedgerName;
    },
    types() {
      return this.$store.getters.getTypes;
    },
    categories() {
      // Used for cats drop-down
      return this.$store.getters.getCategories;
    },
    newCatId() {
      return this.$store.getters.getNewCatId;
    },
    newAcId() {
      return this.$store.getters.getNewAcId;
    },
  },
  methods: {
    test1() {
      console.log("types: ", this.types);
      // console.log("first cats: ", this.categories);
      // console.log("first cats: ", this.categories[0].catId);
      // console.log("tstTyp: ", this.tstTyp);
      // console.log("frmAcCat.typ: ", this.frmAcCat.typ);
    },

    test2() {
      this.catSelRow(1, 144, "goodbye", null, 1, 0, 1, 0, 2);
    },
    selAcJrnl() {
      console.log("sel ac from maintain acs", this.selectedAc);
      // this.$store.commit("setSelAcForJrnl", this.selectedAc);
      this.$store.commit("setSelAcForJrnl", this.selectedAc);
      console.log("sel ac after commit", this.$store.getters.getSelAcForJrnl);
      this.tryClose();
    },
    confirmError() {
      this.showDialog = false;
    },
    tryClose() {
      this.$emit("close");
    },
    catSelRow(catType, catId, catNm, catNote, catSrt, tidx, cidx, catNoAcs, typCatLen) {
      // If already higlighed, un-highlight:
      // console.log("selectCat", this.selectedCat, "catId", catId);

      console.log("types", this.types);
      console.log("catType:", catType, "catId:", catId, "catNm:", catNm, "catNote:", catNote, "catSrt:", catSrt, "tidx:", tidx, "idx:", cidx, "catNoAcs", catNoAcs, "catTypeLen:", typCatLen);
      if (this.selectedCat == catId) {
        this.maintMsg = "selCatAc";

        this.selectedCat = null;
        this.disableBtnEdit = true;
        this.disableBtnDelete = true;
        (this.curTypCatLen = null), (this.catIdx = null), (this.mode = "");
        return;
      }
      // console.log("selectedCat after null", this.selectedCat);
      this.maintMsg = "editCat";
      this.mode = "cat";
      this.disableCatFlds = true;
      this.disableBtnEdit = false;
      this.disableBtnDelete = false;
      this.disableBtnSave = true;
      this.disableBtnSaveOth = true;
      this.disableBtnCancel = false;
      // this.disableBtnCatUp = true;
      // this.disableBtnCatDown = true;
      this.curTypCatLen = typCatLen;
      this.typIdx = tidx;
      this.catIdx = cidx;
      this.acIdx = null;
      this.testCatPosn();

      this.frmCatType.val = catType;
      this.frmCatType.initVal = catType;
      this.frmCatName.val = catNm;
      this.frmCatName.initVal = catNm;
      this.frmCatNotes.val = catNote;
      this.frmCatNotes.initVal = catNote;
      this.selectedCat = catId;
      this.selectedAc = null; // deselect ac if already selected.
      this.catSort = catSrt;
      this.catNoAcs = catNoAcs;
      this.catIsDeletable = false;
      this.showCatDelMsg = false;
      this.showAcDelMsg = false;

      // this.acIsDeletable = false;

      console.log("catIdx", this.catIdx);
    },
    testCatPosn() {
      console.log("testCatPosn called");
      this.disableBtnCatUp = true;
      this.disableBtnCatDown = true;
      console.log("curTypeCatLen", this.curTypCatLen);
      console.log("catIdx", this.catIdx);
      if (this.curTypCatLen > 1) {
        if (this.catIdx < this.curTypCatLen - 1) this.disableBtnCatDown = false;
        if (this.catIdx > 0) this.disableBtnCatUp = false;
      }
    },
    acSelRow(typIdx, catIdx, acIdx, acId, acCat, acNo, acName, acNote, acBal, acDrLegs, acCrLegs, acExclBal, acExclDrLegs, acExclCrLegs, oldTyp) {
      // If already highlighted, un-highlight:
      if (this.saveAnother == false) {
        if (this.selectedAc == acId) {
          this.selectedAc = null;
          this.disableBtnEdit = true;
          this.disableBtnDelete = true;

          this.mode = "";
          this.maintMsg = "selCatAc";
          return;
        }
        this.typIdx = typIdx;
        this.catIdx = catIdx;
        this.acIdx = acIdx;
        this.maintMsg = "editAc";
        this.mode = "ac";
        // this.disableBtnEdit = false;
        this.selectedAc = acId;
        this.selectedCat = null; // deselect cat if already selected.

        this.frmAcCat.val = acCat;
        this.frmAcCat.initVal = acCat;
        this.frmAcNo.val = acNo;
        this.frmAcNo.initVal = acNo;
        this.frmAcName.val = acName;
        this.frmAcName.initVal = acName;
        this.frmAcNotes.val = acNote;
        this.frmAcNotes.initVal = acNote;
        // this.originalAcNo = acNo;

        this.acBal = acBal;
        this.acNoDrLegs = acDrLegs;
        this.acNoCrLegs = acCrLegs;
        this.acExclBal = acExclBal;
        this.acExclNoDrLegs = acExclDrLegs;
        this.acExclNoCrLegs = acExclCrLegs;
        this.disableBtnEdit = false;
        this.disableBtnDelete = false;
        this.disableBtnCancel = false;
        // this.acIsDeletable = true;
        // this.catIsDeletable = true;
        this.disableBtnSel = false;
        // this.catIsDeletable = false;
        this.acIsDeletable = false;
        this.showAcDelMsg = false;
        this.showCatDelMsg = false;
        this.oldTypeId = oldTyp;
        console.log("this.selectedAc:", this.selectedAc);
        // if (acBal == 0 && acDrLegs == 0 && acCrLegs == 0) {
        //   this.acIsDeletable = true;
        // } else {
        //   this.acIsDeletable = false;
        // }
      }
    },
    catAcNew(mode) {
      this.mode = mode;
      this.action = "new";
      this.disableBtnNewCat = true;
      this.disableBtnNewAc = true;
      this.disableBtnEdit = true;
      this.disableBtnDelete = true;
      this.disableBtnSave = false;
      this.disableBtnSaveOth = false;
      this.disableBtnCancel = false;
      if (this.mode == "cat") {
        this.maintMsg = "newCat";
        this.selectedCat = null;

        // If the Type select is null, select the first in the list:
        if (!this.frmCatType.initVal) this.frmCatType.initVal = this.types[0].typeId;

        this.frmCatType.val = this.frmCatType.initVal;
        this.frmCatName.val = "";
        this.frmCatNotes.val = "";
        this.disableCatFlds = false;
        this.disableEditTypSel = false;
      }

      if (this.mode == "ac") {
        this.maintMsg = "newAc";
        this.selectedAc = null;
        console.log("acCat initVal", this.frmAcCat.initVal, this.categories[0].catId);
        if (!this.frmAcCat.initVal) this.frmAcCat.initVal = this.categories[0].catId;
        this.frmAcCat.val = this.frmAcCat.initVal;

        this.frmAcNo.val = "";
        this.frmAcName.val = "";
        this.frmAcNotes.val = "";

        this.disableAcFlds = false;
      }
    },
    edit() {
      this.action = "edit";
      this.disableBtnEdit = true;
      this.disableBtnNewCat = true;
      this.disableBtnNewAc = true;
      this.disableBtnDelete = true;
      this.disableBtnSave = false;
      this.disableBtnSaveOth = true;
      this.disableBtnCancel = false;
      if (this.mode == "cat") {
        this.disableCatFlds = false;
      }

      if (this.mode == "ac") {
        this.disableAcFlds = false;
      }
    },
    cancel() {
      console.log("cancel has been called");
      this.action = "";
      this.disableBtnNewCat = false;
      this.disableBtnNewAc = false;
      this.disableBtnEdit = true;
      this.disableBtnDelete = true;
      this.disableBtnSave = true;
      this.disableBtnSaveOth = true;
      this.disableBtnCancel = true;
      this.disableBtnSel = true;
      this.formIsValid = true;
      if (this.mode == "cat") {
        this.frmCatType.val = this.frmCatType.initVal;
        this.frmCatName.val = this.frmCatName.initVal;
        this.frmCatName.isValLen = true;
        this.frmCatName.isUnique = true;
        this.frmCatName.isNotEmp = true;
        this.frmCatNotes.val = this.frmCatNotes.initVal;
        this.frmCatNotes.isValLen = true;

        this.disableCatFlds = true;
      }

      if (this.mode == "ac") {
        this.frmAcCat.val = this.frmAcCat.initVal;
        this.frmAcName.val = this.frmAcName.initVal;
        this.frmAcName.isValLen = true;
        this.frmAcName.isUnique = true;
        this.frmAcName.isNotEmp = true;
        this.frmAcNo.val = this.frmAcNo.initVal;
        this.frmAcNo.isValLen = true;
        this.frmAcNo.isUnique = true;
        this.frmAcNo.isNotEmp = true;
        this.frmAcNotes.val = this.frmAcNotes.initVal;
        this.frmAcNotes.isValLen = true;
        this.disableAcFlds = true;
      }
      this.maintMsg = "selCatAc";
      this.mode = "";
      this.selectedCat = null;
      this.selectedAc = null;
      this.catIsDeletable = false;
      this.showCatDelMsg = false;
      this.showAcDelMsg = false;
    },
    catMove(dir) {
      this.isProcessing = true;
      // catMove() {
      // console.log("catSort, catIdx", this.catSort, this.catIdx);
      // console.log("cat length", this.curTypeCatLen);
      // console.log("typIdx", this.typIdx, "catIdx", this.catIdx);
      const typCat = this.types[this.typIdx].categories;
      // console.log("types.categories", typCat);
      const moveData = {
        typIdx: this.typIdx,
        catIdx: this.catIdx,
        fromId: null,
        dir: dir,
        fromSort: null,
        toId: null,
        toSort: null,
      };

      for (let i = 0; i < typCat.length; i++) {
        if (typCat[i].catId == this.selectedCat) {
          moveData.fromId = this.selectedCat;
          if (dir == "up") {
            moveData.fromSort = typCat[i].catSort - 1;
            moveData.toId = typCat[i - 1].catId;
            moveData.toSort = typCat[i - 1].catSort + 1;
            this.catIdx -= 1;
            this.catSort = this.catSort - 1;
          }
          if (dir == "down") {
            moveData.fromSort = typCat[i].catSort + 1;
            moveData.toId = typCat[i + 1].catId;
            moveData.toSort = typCat[i + 1].catSort - 1;
            this.catIdx += 1;
            this.catSort = this.catSort + 1;
          }
        }
      }
      console.log("moveData af move:", moveData);
      this.$store
        .dispatch("catMove", moveData)
        .then(() => {
          this.isProcessing = false;
          this.testCatPosn();
        })
        .catch((error) => {
          this.isProcessing = false;
          this.error = error;
        });

      // console.log("idx catSort cats", this.catIdx, this.catSort, cats);
    },
    save() {
      this.saveAnother = false;
      this.saveProc();
      this.disableBtnNewCat = false;
      this.disableBtnNewAc = false;
      this.disableEditTypSel = true;
    },
    save_oth() {
      this.saveAnother = true;
      console.log("save_oth mode", this.mode);
      // this.selectedAc = null;
      this.saveProc();
      // this.selectedAc = null;

      // this.frmAcName.val = "";
      // this.frmAcName.initVal = "";
      // if (this.mode == "cat") this.catAcNew("cat");
      if (this.mode == "ac") {
        this.catAcNew("ac");
      }
      if (this.mode == "cat") {
        this.catAcNew("cat");
      }

      // this.saveAnother = false;
    },
    saveProc() {
      this.isProcessing = true;
      this.validateForm();

      if (!this.formIsValid) {
        this.isProcessing = false;

        return;
      }

      this.disableBtnEdit = false;
      this.disableBtnDelete = false;
      this.disableBtnSave = true;
      this.disableBtnSaveOth = true;
      this.disableBtnCancel = true;

      if (this.mode == "cat") {
        this.disableCatFlds = true;
        this.frmCatType.initVal = this.frmCatType.val;

        for (let t = 0; t < this.types.length; t++) {
          // console.log("types posn", this.types);
          if (this.types[t].typeId == this.frmCatType.val) {
            if (this.types[t].categories) {
              this.curTypCatLen = this.types[t].categories.length;
              this.catIdx = this.types[t].categories.length - 1;
            } else {
              this.curTypCatLen = 0;
              this.catIdx = 0;
            }
          }
          // catSelRow(catType, catId, catNm, catNote, catSrt, tidx, cidx, catNoAcs, typCatLen)
          // this.catSelRow(1, 144, "goodbye", null, 1, 0, 1, 0, 2);
        }

        const catData = { catId: this.selectedCat, initCatType: this.frmCatType.initVal, catType: this.frmCatType.val, catName: this.frmCatName.val.trim(), catNotes: this.frmCatNotes.val, catSort: this.curTypCatLen || 0 };
        console.log("catData", catData);

        if (this.action == "edit") {
          this.$store
            .dispatch("editCategory", catData)
            .then(() => {
              this.isProcessing = false;
              // this.$store.commit("setIsInsertingLedger", false);
              // this.tryClose();
            })
            .catch((error) => {
              console.log("error caught" + error);
              this.isProcessing = false;
              this.error = error;
              console.log("data: error: ", this.error);
            });
        }

        if (this.action == "new") {
          this.$store
            .dispatch("insertCategory", catData)
            .then(() => {
              this.isProcessing = false;
              console.log("saveNew hlight new cat row");
              console.log("catType, selectedCat", this.catType, this.selectedCat);
              console.log("new cat id", this.newCatId);

              if (this.saveAnother == false) {
                this.catSelRow(catData.catType, this.newCatId, catData.catName, catData.catNotes, catData.catSort, this.typIdx, this.catIdx, this.catNoAcs, this.catSort);
              }
            })
            .catch((error) => {
              console.log("error caught" + error);
              this.isProcessing = false;
              this.error = error;
              console.log("data: error: ", this.error);
            });
        }
        this.testCatPosn();
        // this.maintMsg
      }

      if (this.mode == "ac") {
        this.disableAcFlds = true;
        // this.frmAcCat.initVal = this.frmAcCat.val;
        // console.log("tstTyp: ", this.catType());
        const cats = this.$store.getters.getCategories;
        console.log("frmAcCat", this.frmAcCat);
        for (let c = 0; c < cats.length; c++) {
          if (cats[c].catId == this.frmAcCat.val) {
            this.frmAcCat.typ = cats[c].typeId;
            break;
          }
        }

        const acData = { typIdx: this.typIdx, catIdx: this.catIdx, acIdx: this.acIdx, acId: this.selectedAc, initAcCatId: this.frmAcCat.initVal, acCat: this.frmAcCat.val, acNo: this.frmAcNo.val, acName: this.frmAcName.val, acNotes: this.frmAcNotes.val, acBal: this.acBal, acNoDrLegs: this.acNoDrLegs, acNoCrLegs: this.acNoCrLegs, acExclBal: this.acExclBal, acExclNoDrLegs: this.acExclNoDrLegs, acExclNoCrLegs: this.acExclNoCrLegs, oldTypeId: this.oldTypeId, newTypeId: this.frmAcCat.typ };
        console.log("edit acData", acData);
        console.log("isProcessing?", this.isProcessing);
        if (this.action == "edit") {
          this.$store
            .dispatch("editAccount", acData)
            .then(() => {
              this.isProcessing = false;
              // this.$store.commit("setIsInsertingLedger", false);
              // this.tryClose();
            })
            .catch((error) => {
              console.log("error caught" + error);
              this.isProcessing = false;
              this.error = error;
              console.log("data: error: ", this.error);
            });
        }
        if (this.action == "new") {
          this.$store
            .dispatch("insertAccount", acData)
            .then(() => {
              this.isProcessing = false;
              if (this.saveAnother == false) {
                this.acSelRow(acData.typIdx, acData.catIdx, acData.acIdx, this.newAcId, acData.acCat, acData.acNo, acData.acName, acData.acNotes);
              }
            })
            .catch((error) => {
              console.log("error caught" + error);
              this.isProcessing = false;
              this.error = error;
              console.log("data: error: ", this.error);
            });
        }
      }
    },

    deleteAcCat() {
      this.isProcessing = true;
      if (this.mode == "ac") {
        const delAcData = {
          catId: this.acCat,
          acId: this.selectedAc,
        };
        this.acTotNoLegs == 0 ? (this.acIsDeletable = true) : (this.acIsDeletable = false);

        // console.log('del account id:', delAcData);
        // If the ac is not 'empty', it can't be deleted:
        // console.log('acs:', this.acs);
        // console.log('acInfo:', this.acBal, this.acNoDrLegs, this.acNoCrLegs);
        if (this.acIsDeletable) {
          console.log("This account can be deleted.", delAcData);
          this.$store
            .dispatch("deleteAccount", delAcData)
            .then(() => {
              this.isProcessing = false;
              // this.$store.commit("setIsInsertingLedger", false);
              // this.tryClose();
              this.acIsDeletable = false;
              this.maintMsg = "selCatAc";
              this.mode = "";
            })
            .catch((error) => {
              console.log("error caught" + error);
              this.isProcessing = false;
              this.acIsDeletable = false;

              this.error = error;
              console.log("data: error: ", this.error);
            });
          this.disableBtnEdit = true;
          this.disableBtnDelete = true;
          // this.acCat = null;
          // this.acNo.val = "";
          // this.acName.val = "";
        }
        if (!this.acIsDeletable) {
          this.isProcessing = false;
          this.showAcDelMsg = true;
        }
      }
      // this.cancel();
      if (this.mode == "cat") {
        console.log("catNoAcs", this.catNoAcs);
        this.catNoAcs == 0 ? (this.catIsDeletable = true) : (this.catIsDeletable = false);
        if (this.catIsDeletable) {
          console.log("cat id", this.selectedCat);
          this.$store
            .dispatch("deleteCategory", this.selectedCat)
            .then(() => {
              this.isProcessing = false;
              this.catIsDeletable = false;
              this.maintMsg = "selCatAc";
              this.mode = "";
              // this.$store.commit("setIsInsertingLedger", false);
              // this.tryClose();
            })
            .catch((error) => {
              console.log("error caught" + error);
              this.isProcessing = false;

              this.catIsDeletable = false;

              this.error = error;
              console.log("data: error: ", this.error);
            });
          this.disableBtnEdit = true;
          this.disableBtnDelete = true;
          // this.acCat = null;
          // this.acNo.val = "";
          // this.acName.val = "";
        }
        if (!this.catIsDeletable) {
          this.isProcessing = false;
          this.showCatDelMsg = true;
        }
      }
      // this.mode = "";
    },
    validateForm() {
      this.formIsValid = true;
      // this.catName.isValid = true;
      // Validation
      if (this.mode == "cat") {
        this.frmCatName.isNotEmp = true;
        if (this.frmCatName.val.trim() === "") {
          this.frmCatName.isNotEmp = false;
          this.formIsValid = false;
        }
        this.frmCatName.isValLen = true;
        if (this.frmCatName.val.trim().length > 50) {
          this.frmCatName.isValLen = false;
          this.formIsValid = false;
          console.log("Category name must have length from 1 to 50 chars.");
        }
        this.frmCatName.isUnique = true;
        if (!this.checkCatNameUnique(this.frmCatName.val.trim())) {
          this.frmCatName.isUnique = false;
          this.formIsValid = false;
          console.log("Cat name is not unique.");
        }
        this.frmCatNotes.isValLen = true;
        if (this.frmCatNotes.val.trim().length > 2000) {
          this.frmCatNotes.isValLen = false;
          this.formIsValid = false;
          console.log("Category notes must have length less than 2000 chars.");
        }
      }
      if (this.mode == "ac") {
        this.frmAcName.isNotEmp = true;
        if (this.frmAcName.val.trim() === "") {
          this.frmAcName.isNotEmp = false;
          this.formIsValid = false;
        }
        this.frmAcName.isValLen = true;
        if (this.frmAcName.val.trim().length > 50) {
          this.frmAcName.isValLen = false;
          this.formIsValid = false;
          console.log("Ac name must have length from 1 to 50 chars.");
        }
        this.frmAcName.isUnique = true;
        if (!this.checkAcNameUnique(this.frmAcName.val.trim())) {
          this.frmAcName.isUnique = false;
          this.formIsValid = false;
          console.log("Ac name is not unique.");
        }
        this.frmAcNo.isNotEmp = true;
        if (this.frmAcNo.val.trim() === "") {
          this.frmAcNo.isNotEmp = false;
          this.formIsValid = false;
        }
        this.frmAcNo.isValLen = true;
        if (this.frmAcNo.val.trim().length > 50) {
          this.frmAcNo.isValLen = false;
          this.formIsValid = false;
          console.log("Ac name must have length from 1 to 50 chars.");
        }
        this.frmAcNo.isUnique = true;
        if (!this.checkAcNoUnique(this.frmAcNo.val.trim())) {
          this.frmAcNo.isUnique = false;
          this.formIsValid = false;
          console.log("Ac name is not unique.");
        }
        this.frmAcNotes.isValLen = true;
        if (this.frmAcNotes.val) {
          if (this.frmAcNotes.val.trim().length > 2000) {
            this.frmAcNotes.isValLen = false;
            this.formIsValid = false;
            console.log("Ac notes must have length less than 2000 chars.");
          }
        }
      }
    },
    checkCatNameUnique(catName) {
      // Category name must be unique within the list of category names:
      if (this.frmCatName.initVal == catName) {
        return true;
      } else {
        for (let cat of this.categories) {
          if (cat.catName == catName) {
            console.log("cat name exists", cat.catName);
            return false;
          }
        }
        return true;
      }
    },
    checkAcNameUnique(acName) {
      // Account name must be unique within the list of category names:
      console.log("starting checkAcNameUnique", acName);
      console.log("categories", this.categories);
      if (this.frmAcName.initVal == acName) {
        return true;
      } else {
        for (let cat of this.categories) {
          if (cat.accounts) {
            for (let ac of cat.accounts) {
              if (acName == ac.accName) {
                console.log("ac name exists", cat.catName, ac.accName);
                return false;
              }
            }
          }
        }
        return true;
      }
    },
    checkAcNoUnique(acNo) {
      // Account number must be unique within the list of category names:
      if (this.frmAcNo.initVal == acNo) {
        return true;
      } else {
        for (let cat of this.categories) {
          if (cat.accounts) {
            for (let ac of cat.accounts) {
              if (acNo == ac.accNumber) {
                console.log("ac number exists", cat.catName, ac.accNumber);
                return false;
              }
            }
          }
        }
        return true;
      }
    },

    clearValidity(input) {
      this[input].isValid = true;
    },
    handleError() {
      this.error = null;
    },
    // getSel(event) {
    //   console.log(event.target.options[event.target.options.selectedIndex].getAttribute("test"));
    //   console.log(event.target.options[event.target.options.selectedIndex]);
    // },
  },
};
</script>

<style scoped>
.highlight {
  background-color: lightyellow;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 10;
}

dialog {
  position: fixed;
  top: 10vh;
  left: 20vh;
  margin: 0 auto;
  width: 65%;
  height: 90%;
  z-index: 90;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  /* overflow: auto; */
  background-color: white;
  font-family: "Roboto Serif", serif;
}

header {
  background-color: #3a0061;
  color: white;
  width: 100%;
  padding: 1rem;
  position: sticky;
  top: 0;
}

header h2 {
  margin: 0;
}

.listTypes {
  margin: 1rem 1rem;
}

.list {
  display: inline-block;
  width: 50%;
  height: 90%;
  overflow: scroll;
  border: 1px solid blue;
}
table {
  border-collapse: collapse;
}

th {
  /* border: 1px solid #999; */
  padding: 0.5ren;
  text-align: center;
  padding: 4px;
  font-family: "Roboto Serif", serif;
  font-size: 1.4rem;
  position: sticky;
  top: 0;
}
td {
  /* border: 1px solid #999; */
  padding: 0.5ren;
  text-align: left;
  padding: 4px;
  font-family: "Courier New", Courier, monospace;
  /* font-size: 12px; */
}
.row-type {
  color: purple;
  font-weight: bold;
  font-size: 2rem;
}

.cell-type {
  padding-top: 1.2rem;
}

.row-cat {
  color: green;
  font-weight: bold;
  font-size: 1.7rem;
}

.row-ac {
  font-weight: bold;
  font-size: 1.5rem;
}

.fields {
  display: inline-block;
  vertical-align: top;
}
.form-control {
  margin: 0.2rem 0;
  display: flex;
  align-items: flex-start;
}

.fldErr {
  color: red;
  font-size: 1.5rem;
  margin-left: 15rem;
  margin-bottom: 2rem;
}
.frmErr {
  color: red;
  font-size: 2rem;
  margin-top: 2rem;
  margin-left: 5rem;
  margin-bottom: 2rem;
  display: flex;
}

.catDel,
.acDel {
  color: red;
  font-size: 2rem;
  margin-top: 2rem;
  margin-left: 5rem;
  margin-bottom: 2rem;
  display: inline;
}
label {
  font-weight: bold;
  font-size: 2rem;
  display: inline-block;
  text-align: right;
  margin-bottom: 1rem;
  width: 14rem;
}
input,
textarea {
  display: inline;
  font-size: 2rem;
  width: 40rem;
  /* width: 100%; */
  margin-left: 1rem;
  border: 1px solid #ccc;
  /* font: inherit; */
}
.maintMsg {
  margin-left: 1rem;
  font-size: 2rem;
  font-weight: 500;
  color: darkgray;
  margin-bottom: 2rem;
}

.maintMsg .wrdCat {
  color: green;
  font-weight: 900;
}

.maintMsg .wrdAc {
  color: black;
  font-weight: 900;
}
/* .fldErr {
  font-size: 1rem;
  color: red;
} */

input:focus,
textarea:focus {
  background-color: #f0e6fd;
  outline: none;
  border-color: #3d008d;
}

select {
  margin-left: 1rem;
  font-size: 2rem;
  width: 40rem;
  height: 3rem;
}

menu {
  padding: 1rem;
  display: flex;
  /* justify-content: flex-end; */
  margin: 1;
  font-size: 1.5rem;
}
.lwrBtns {
  /* display: inline-block; */
  position: absolute;
  bottom: 0;
}
</style>
