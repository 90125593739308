<template>
  <base-error :show="!!error" title="An error occurred!" @close="handleError">
    <p>{{ error }}</p>
  </base-error>
  <ledger-form v-if="isEditing" :show="isEditing" :mode="frmMode" :title="frmTitle" :ledData="propData"> </ledger-form>
  <ledger-form v-if="isInserting" :show="isInserting" :mode="frmMode" :title="frmTitle"> </ledger-form>
  <ledger-notes v-if="showNotes" :show="showNotes" @close="showLedNotes"></ledger-notes>
  <ledger-delete v-if="showLedDel" :show="showLedDel" @close="showLedDelete"></ledger-delete>
  <section>
    <div class="mngLed">
      <div class="selCntr">
        <label class="selLbl" for="ledgers">Select Ledger: </label>
        <select id="ledgers" class="selDrp" :value="selectedLedger || 0" @input="selectedLedgerOutput($event)">
          <option value="0">&lt;------------&gt;</option>
          <option class="selOpt" v-for="ledger in ledgerList" :key="ledger.ledId" :value="ledger.ledId" :descr1="ledger.description">{{ ledger.ledName }}</option>
        </select>
        <!-- <base-button btnStyle="flat" @click="pdfgen">PDF</base-button> -->
        <base-button btnStyle="flat" @click="this.pdfgen" :disabled="!ledgerId">PDF</base-button>
      </div>
      <div class="btnCntr">
        <base-button btnStyle="flat" @click="showLedDelete" :disabled="!ledgerId">Delete</base-button>
        <base-button btnStyle="flat" @click="editLedger" :disabled="!ledgerId">Edit</base-button>
        <base-button btnStyle="flat" @click="showLedNotes" :disabled="!ledgerId">Notes</base-button>
        <base-button id="btn_new_led" btnStyle="flat" @click="newLedger">New</base-button>
        <!-- <base-button btnStyle="flat" @click="genExcel">Excel</base-button> -->
        <!-- <button class="btn" @click="toEdit">Edit</button> -->
        <!-- <button class="btn" @click="toDel">Delete</button> -->
        <!-- <button class="btn" @click="toDel">Notes</button> -->
        <!-- <button id="btnNew" class="btn" @click="toNew">New</button> -->
        <!-- <button @click="toAcsMaint">Maint Acs</button> -->
        <!-- <button @click="test_1">Test</button> -->
      </div>
    </div>
    <div class="ledInfo">
      <div v-if="isProcessing" class="spinner">
        <base-spinner></base-spinner>
      </div>
      <div v-else-if="!isProcessing" class="ledDescr">
        <input type="text" id="ledDescr" name="ledDescr" maxlength="250" :value="ledgerDescr" @blur="updLedDescr($event)" />
      </div>
      <div class="ledData">
        <div class="dataCntr" :class="{ isVis: !ledgerId }">
          <label for="dataCurr">Currency:</label>
          <input class="dataFld" type="text" name="dataCurr" :value="baseCurr" readonly />
        </div>
        <div class="dataCntr" :class="{ isVis: !ledgerId }">
          <label for="dataTA">Total Assets:</label>
          <input class="dataFld" type="text" name="dataTA" :value="totalAssets" readonly />
        </div>
        <div class="dataCntr" :class="{ isVis: !ledgerId }">
          <label for="dataTL">Total Liabilities:</label>
          <input class="dataFld" type="text" name="dataTL" :value="totalLiab" readonly />
        </div>
        <div class="dataCntr" :class="{ isVis: !ledgerId }">
          <label for="dataNtA">Net Current Assets:</label>
          <input class="dataFld" type="text" name="dataNtA" :value="netCurrAss" readonly />
        </div>
        <div class="dataCntr" :class="{ isVis: !ledgerId }">
          <label for="dataNtCA">Net <span v-if="isNetProfit">Profit</span> <span v-if="!isNetProfit">Loss</span></label>
          <input class="dataFld" :class="{ bckGreen: isNetProfit, bckRed: !isNetProfit }" type="text" name="dataNtCA" :value="netProfit_1" readonly />
        </div>
      </div>
    </div>
    <!-- <div>{{ selectedLedgerOptions.descr1 }}</div> -->
    <!-- <div class="ldescr">{{ ledgerDescr }}</div> -->
  </section>
</template>

<script>
import mixin from "@/mixins/mixin.js";
import pdfReport from "@/mixins/pdfReport.js";
import LedgerForm from "./LedgerForm.vue";
import LedgerNotes from "./LedgerNotes.vue";
import LedgerDelete from "./LedgerDelete.vue";
// const Pdfmake = require("pdfmake");

export default {
  mixins: [mixin, pdfReport],
  components: { LedgerForm, LedgerNotes, LedgerDelete },
  data() {
    return {
      // isEditing: false,
      // isInserting: false,
      frmMode: "",
      frmTitle: "",
      frmLedDescr: "",
      showNotes: false,
      showLedDel: false,
      isNetProfit: true,
      isProcessing: false,
      error: null,
      // ledData: {
      //   name: "",
      //   description: "",
      //   base_curr: "",
      //   base_curr_desc: "",
      //   base_curr_no_dec: "",
      //   notes: "",
      // },
    };
  },
  computed: {
    selectedLedger() {
      return this.$store.getters.getCurrLedgerId || this.$store.getters.getAuthCurrLedId;
      // return this.$store.getters.getAuthCurrLedId;
    },

    ledgerList() {
      console.log("ledgerList", this.$store.getters.getLedgers);
      return this.$store.getters.getLedgers;
    },
    ledgerId() {
      return this.$store.getters.getCurrLedgerId;
    },
    userId() {
      return this.$store.getters.getAuthUserId;
    },
    ledgerName() {
      return this.$store.getters.getCurrLedgerName;
    },
    ledgerDescr() {
      return this.$store.getters.getCurrLedgerDescr;
    },
    baseCurr() {
      return this.$store.getters.getCurrLedgerBaseCurr;
    },
    isEditing() {
      return this.$store.getters.getIsEditingLedger;
    },
    ledSummaries() {
      return this.$store.getters.getSummaries;
    },
    totalAssets() {
      return this.convAmt(this.ledSummaries.NCA + this.ledSummaries.CAS);
    },
    totalLiab() {
      return this.convAmt(this.ledSummaries.CLB + this.ledSummaries.NCL);
    },
    netCurrAss() {
      return this.convAmt(this.ledSummaries.CAS + this.ledSummaries.CLB);
    },
    netProfit_1() {
      let amt = this.ledSummaries.REV + this.ledSummaries.CSL + this.ledSummaries.OIN + this.ledSummaries.EXP;
      console.log("amt", amt);
      console.log("ledSummaries", this.ledSummaries);
      if (amt > 0) {
        // Change to a loss
        this.changePL(false);
      }

      if (amt <= 0) {
        this.changePL(true);
        amt = amt * -1;
      }

      return this.convAmt(amt);
    },
    isInserting() {
      return this.$store.getters.getIsInsertingLedger;
    },

    propData() {
      console.log("getCurrLedgerDetails", this.$store.getters.getCurrLedgerDetails);
      const currLedgerDet = this.$store.getters.getCurrLedgerDetails;
      console.log("currLedgerA", currLedgerDet);

      const ledData = {
        ledName: { val: currLedgerDet.ledName, isValid: true, invalReason: null },
        ledDescr: { val: currLedgerDet.ledDescr, isValid: true },
        baseCurr: { val: currLedgerDet.baseCurr, isValid: true },
        baseCurrDesc: { val: currLedgerDet.baseCurrDesc, isValid: true },
        baseCurrNoDec: { val: currLedgerDet.baseCurrNoDec, isValid: true },
        frmtAmts: { val: currLedgerDet.frmtAmts, isValid: true },
        frmtShrtDts: { val: currLedgerDet.frmtShrtDts, isValid: true },
        cpyLedId: { val: 0, isValid: true },
        cpyLedMode: { val: 0, isValid: true },
        ledNotes: { val: currLedgerDet.ledNotes, isValid: true },
        tbTitle: { val: currLedgerDet.tbTitle, isValid: true },
        bsTitle: { val: currLedgerDet.bsTitle, isValid: true },
        plTitle: { val: currLedgerDet.plTitle, isValid: true },
      };
      console.log("ledData after load:", ledData);
      // const ledData1 = JSON.parse(JSON.stringify(ledData));
      // console.log("ledData1", ledData1);
      return ledData;
    },
    // jrnls() {
    //   return this.$store.getters.getJournals;
    // },
    // acs() {
    //   return this.$store.getters.getAccounts;
    // },
    // bsTitle() {
    //   return this.$store.getters.getLedBsTitle;
    // },
    // plTitle() {
    //   return this.$store.getters.getLedPlTitle;
    // },
  },
  methods: {
    selectedLedgerOutput(event) {
      let selIdx = event.target.options[event.target.options.selectedIndex];
      // this.$store.commit("setAuthCurrLedId", event.target.options[event.target.options.selectedIndex].value);
      console.log("selIdx", selIdx);
      this.$store.commit("setAuthCurrLedId", selIdx.value);
      // Clear Hlights:
      this.$store.commit("clearHlights");

      if (selIdx.value == 0) {
        console.log("deleting the ledger");
        this.$store.commit("delLedger");
      }

      if (selIdx.value != 0) {
        this.loadUlData(); // from mixin
        console.log("ulData", this.$store.getters.getUlData);
      }
    },
    changePL(val) {
      this.isNetProfit = val;
    },
    editLedger() {
      // this.ledData.name = "hello";
      this.frmMode = "edit";
      // this.isInserting = false;

      // this.isEditing = true;
      this.$store.commit("setIsEditingLedger", true);
      console.log("mode", this.mode);

      this.frmTitle = "Edit Ledger";
      console.log("edit ledger clicked", this.propData);
    },
    newLedger() {
      this.frmMode = "new";
      // this.isediting = false;
      this.$store.commit("setIsInsertingLedger", true);
      // this.isInserting = true;

      this.frmTitle = "New Ledger";
    },
    showLedNotes() {
      this.showNotes = !this.showNotes;
    },
    showLedDelete() {
      this.showLedDel = !this.showLedDel;
    },
    updLedDescr(e) {
      // console.log("updating ledger descr", this.ledgerDescr);
      // console.log(e.target.value.trim());
      if (e.target.value.length == 0) {
        console.log("description must not be blank");
        e.target.value = this.ledgerDescr;
      }
      if (e.target.value.length > 0) {
        this.updLed("description", e.target.value.trim());
        // this.$store.dispatch("updateLedDescr", e.target.value.trim());
      }
    },

    updLed(fld, val) {
      this.isProcessing = true;
      const frmData = {
        ledId: this.ledgerId,
        fld: fld,
        val: val,
      };

      this.$store
        .dispatch("postShrtEditLed", frmData)
        .then(() => {
          this.isProcessing = false;
        })

        .catch((error) => {
          console.log("error caught" + error);
          this.isProcessing = false;
          this.error = error;
          console.log("data: error: ", this.error);
        });
    },

    genExcel() {
      // this.$store.dispatch("genExcel", { userId: this.userId, ledId: this.ledgerId });
      this.$store.dispatch("genPdf", { userId: this.userId, ledId: this.ledgerId });
    },
    handleError() {
      this.error = null;
    },
    test_1() {
      console.log("getCurrLedgerId", this.ledgerId);
    },
  },
};
</script>

<style scoped>
section {
  height: 6rem;
  background-color: lightsteelblue;
  font-family: "Roboto Serif", serif;
  font-size: 1.6rem;
}

.mngLed {
  /* height: 6rem; */
  padding-left: 1.5rem;
  display: inline-block;
  width: 45rem;
}

.ledInfo {
  display: inline-block;
  width: auto;
}

.ledData {
  display: inline-block;
  width: auto;
  font-size: 1.2rem;
}

.dataCntr {
  display: inline-block;
}

.dataFld {
  width: 6rem;
  background-color: aqua;
  border: 0;
  margin-left: 0.5rem;
  margin-right: 2rem;
}
.bckGreen {
  background-color: lightgreen;
}

.bckRed {
  background-color: lightpink;
}
.ledDescr > input {
  font-weight: 600;
  font-size: 2rem;
  width: 80rem;
  background-color: inherit;
  border: none;
}

.selCntr {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-weight: 500;
  font-size: 1.6rem;
}

.selLbl {
  width: 13rem;
  /* height: 1.6rem; */
  padding-top: 3px;
}
.selDrp {
  font-size: 1.4rem;
  width: 25rem;
  height: 1.6rem;
}

.selOpt {
  font-weight: 600;
  font-size: 1rem;
}

.btnCntr {
  margin-left: 1rem;
}
/* .btn {
  border-radius: 5px;
  font-size: 1.3rem;
  font-weight: 600;
  margin-right: 0.7rem;
} */

.btn#btnNew {
  margin-left: 3rem;
}

.isVis {
  visibility: hidden;
}
</style>
