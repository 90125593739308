<template>
  <teleport to="body">
    <base-error :show="!!error" title="An error occurred!" @close="handleError">
      <p>{{ error }}</p>
    </base-error>
    <div @click="tryClose" class="backdrop"></div>
    <dialog open v-if="show" class="dialog">
      <h1>Sign Up</h1>
      <div v-if="isProcessing" class="spinner">
        <base-spinner></base-spinner>
      </div>
      <form @submit.prevent="submitForm">
        <div class="form-control">
          <label for="username">User Name</label>
          <input type="username" v-model.trim="username" id="username" />
        </div>
        <div class="form-control">
          <label for="email">E-Mail</label>
          <input type="email" v-model.trim="email" id="email" />
        </div>
        <div class="form-control">
          <label for="password">Password</label>
          <input type="password" v-model.trim="password" id="password" />
        </div>
        <div>
          <p v-if="!formIsValid">Please enter a valid username, email and password (at least 6 chars long)</p>
        </div>
        <button>Sign Up</button>
        <button @click="tryClose">Cancel</button>
      </form>
    </dialog>
  </teleport>
</template>
<script>
export default {
  emits: ["close", "showEmailSent"],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      username: "",
      email: "",
      password: "",
      formIsValid: true,
      showDialog: false,
      isProcessing: false,
      error: null,
    };
  },
  methods: {
    tryClose() {
      this.$emit("close");
    },
    showMailSentDialog() {
      this.$emit("showEmailSent");
    },
    submitForm() {
      this.formIsValid = true;
      this.isProcessing = true;
      if (this.username === "" || this.email === "" || !this.email.includes("@") || this.password.length < 6) {
        this.formIsValid = false;
        return;
      }
      this.$store
        .dispatch("signup", {
          username: this.username,
          email: this.email,
          password: this.password,
        })
        .then(() => {
          this.isProcessing = false;
          this.showMailSentDialog();
          this.tryClose();
        })
        .catch((error) => {
          console.log("Insert user error.");
          this.isProcessing = false;
          this.error = error;
        });
    },

    handleError() {
      this.error = null;
    },
  },
};
</script>
<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 10;
}

dialog {
  position: fixed;
  top: 10vh;
  left: 20vh;
  margin: 0 auto;
  width: 60%;
  height: 80%;
  z-index: 90;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  /* overflow: auto; */
  background-color: white;
  font-family: "Roboto Serif", serif;
}

h1 {
  margin-top: 2rem;
  margin-left: 2rem;
  padding: 1rem;
}
form {
  margin-top: 2rem;
  margin-left: 2rem;
  padding: 1rem;
}

.form-control {
  margin: 0.5rem 0;
}

label {
  /* font-weight: bold; */
  margin-bottom: 0.5rem;
  display: block;
  font-size: 2rem;
}

input,
textarea {
  display: block;
  width: 50rem;
  font: inherit;
  border: 1px solid #ccc;
  padding: 0.15rem;
  margin-bottom: 2rem;
  font-size: 2rem;
}

input:focus,
textarea:focus {
  border-color: #3d008d;
  background-color: #faf6ff;
  outline: none;
}

button {
  font-size: 2rem;
  margin-right: 2rem;
}
</style>
