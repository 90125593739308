<template>
  <base-error :show="!!error" title="An error occurred!" @close="handleError">
    <p>{{ error }}</p>
  </base-error>
  <teleport to="body">
    <div @click="tryClose" class="backdrop"></div>
    <dialog open v-if="show" class="dialog">
      <header>
        <h2>{{ ledName }} - {{ maintJrnlMsg }}</h2>
      </header>
      <div v-if="isPosting" class="spinner">
        <base-spinner></base-spinner>
      </div>
      <div class="container" v-else-if="!isPosting">
        <div class="legs">
          <form @submit.prevent>
            <br />
            <table>
              <thead>
                <tr>
                  <th>Leg</th>
                  <th>Account</th>
                  <th>Note</th>
                  <th class="amt">Dr.</th>
                  <th class="amt">Cr.</th>
                  <th class="colAction">Action</th>
                </tr>
              </thead>
              <tbody id="jnlsBody">
                <form-leg v-for="(leg, index) in legs" :key="index" :accId="leg.accId" :note="leg.legNote" :drAmt="legAmt('drAmt', leg.legDrCr, leg.legAmount)" :crAmt="legAmt('crAmt', leg.legDrCr, leg.legAmount)" :id="leg.legNo" :index="index" :ref="'hello_' + leg.legNo" @remove-leg="removeLeg" @update-leg="updateLeg"></form-leg>
                <tr>
                  <td colspan="6" class="addLeg">
                    <button id="jrnl_add_leg" @click="addLeg">Add Leg</button>
                  </td>
                </tr>
                <!-- <form-leg></form-leg> -->
              </tbody>
              <br />
              <!-- <button @click="addLegRow">Add new leg</button> -->
            </table>
          </form>
        </div>
        <div class="legsTot">
          <table class="legTots">
            <tbody>
              <tr>
                <td colspan="3">Total Amount:</td>
                <!-- <td type="number" id="totDrAmt" class="amt"><input type="number" v-model="subTotal"></td> -->
                <td>
                  <input class="amt" type="text" disabled v-model="totalDrsFrmt" />
                </td>
                <td>
                  <input class="amt" type="text" disabled v-model="totalCrsFrmt" />
                </td>
              </tr>
              <tr>
                <td colspan="3">Total Count:</td>
                <!-- <td type="number" id="totDrAmt" class="amt"><input type="number" v-model="subTotal"></td> -->
                <td>
                  <input class="amt" type="number" disabled v-model.number="totalDrsCount" />
                </td>
                <td>
                  <input class="amt" type="number" disabled v-model.number="totalCrsCount" />
                </td>
              </tr>
              <br />
              <tr>
                <td colspan="3">To Balance:</td>
                <td>
                  <input class="amt balAmt" type="text" disabled v-model="balDrFrmt" />
                </td>
                <td>
                  <input class="amt balAmt" type="text" disabled v-model="balCrFrmt" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="jrnl">
          <div class="jrnlFlds">
            <div class="form-control">
              <label for="jnlNo">Journal No:</label>
              <input type="text" id="jnlNo" name="jnlNo" :value="frmtJrnlNo(jnlNumber)" disabled />
            </div>
            <div class="form-control">
              <label for="date">Date:</label>
              <input type="date" id="frm_jrnl_date" name="date" v-model.lazy="jnlDate.val" />
              <p class="fldErr" v-if="!jnlDate.isValid">Invalid Date!</p>
            </div>
            <br />
            <div class="form-control">
              <label for="being">Being:</label><br />
              <textarea id="frm_jrnl_being" name="being" rows="3" v-model.trim="jnlBeing.val" />
            </div>
            <div class="form-control">
              <label for="notes">Notes:</label><br />
              <textarea type="text" id="frm_jrnl_notes" name="notes" rows="10" v-model.trim="jnlLongDesc.val"></textarea>
            </div>
          </div>
          <div class="lwrBtns">
            <menu class="mnuSvCncl">
              <base-button id="btn_pst_jrnl" @click="submitForm" v-bind:class="{ btnPostYes: formIsValid, btnPostNo: !formIsValid }" :disabled="!formIsValid">
                {{ postBtnName }}
              </base-button>
              <base-button>Reset</base-button>
              <base-button @click="tryClose">Close</base-button>
              <base-button @click="testA">TestA</base-button>
              <base-button @click="testB">TestB</base-button>
            </menu>
          </div>
        </div>
      </div>
      <maint-acs v-if="showMaintAcs" :show="showMaintAcs" @close="showMaintainAcs"></maint-acs>
    </dialog>
  </teleport>
</template>

<script>
import FormLeg from "./FormLeg.vue";
import MaintAcs from "../../3_Accounts/MaintAcs.vue";
import mixin from "@/mixins/mixin.js";
export default {
  components: {
    FormLeg,
    MaintAcs,
  },
  emits: ["close"],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [mixin],
  data() {
    return {
      showModal: false,
      showMaintAcs: false,
      isPosting: false,
      error: null,
      // jnlId: 1,
      jnlBeing: {
        val: this.$store.getters.getWorkJournal.jnlBeing,
        isValid: false,
      },
      jnlLongDesc: {
        val: this.$store.getters.getWorkJournal.jnlDescr,
        isValid: true,
      },
      jnlDate: {
        val: this.$store.getters.getWorkJournal.jnlDate,
        isValid: true,
      },

      legsAreValid: false,
      maintAcsFly: null,
    };
  },
  watch: {
    "jnlBeing.val": function (newVal) {
      console.log("newVal", newVal, newVal.length);
      if (newVal.length > 0) {
        this.jnlBeing.isValid = true;
      } else {
        this.jnlBeing.isValid = false;
      }
      console.log("jnlBeing.isValid", this.jnlBeing.isValid);
    },
    "jnlDate.val": function (newVal) {
      console.log("newVal", newVal, newVal.length);
      if (this.isValidDate(newVal)) {
        this.jnlDate.isValid = true;
      }

      if (!this.isValidDate(newVal)) {
        this.jnlDate.isValid = false;
      }
      console.log("jnlDate.isValid", this.jnlDate.isValid);
    },
  },
  computed: {
    action() {
      return this.$store.getters.getWrkJrnlMode;
    },
    selAcForJrnl() {
      return this.$store.getters.getSelAcForJrnl;
    },
    ledName() {
      return this.$store.getters.getCurrLedgerName;
    },
    isJrnlPosting() {
      return this.$store.getters.getIsJrnlPosting;
    },
    maintJrnlMsg() {
      if (this.action == "NEW") {
        return "New Journal";
      }
      return "Maintain Journal";
    },
    jnlNumber() {
      // if (this.action == "NEW") {
      // return this.$store.getters.getLastJournalNo + 1;
      // }
      // else {
      //   // if edit or del
      //   // return this.$store.getters.getWorkJournalNo;

      // }
      if (this.action == "NEW") {
        return this.$store.getters.getLastJournalNo + 1;
      }
      if (this.action == "EDIT") {
        // if edit or del
        return this.$store.getters.getWorkJournalNo;
      }
      return "XXX TBD";
    },
    postBtnName() {
      if (this.action == "EDIT") {
        return "Update Journal";
      }
      if (this.action == "NEW") {
        return "Post Journal";
      }
      return "XXX tbd";
    },
    legs() {
      return this.$store.getters.getWorkJournal.legs;
    },
    formIsValid() {
      if (this.legsAreValid == true && this.jnlBeing.isValid == true && this.jnlDate.isValid == true && this.jnlIsBalanced == true) {
        return true;
        // } else if (this.postValidEdit == true) {
        // return true;
      } else {
        return false;
      }
    },

    jnlIsBalanced() {
      if (this.totalDrs == this.totalCrs) {
        return true;
      } else {
        return false;
      }
    },
    types() {
      return this.$store.getters.getTypes;
    },
    ledgerId() {
      return this.$store.getters.getCurrLedgerId;
    },
    cats() {
      return this.$store.getters.getCategories;
    },

    acs() {
      return this.$store.getters.getAccounts;
    },
    // subTotal() {
    //   return this.legs.reduce((acc, ele) => {
    //   return acc + parseInt(ele.drAmt);
    // }, 0);
    // },
    totalDrsFrmt() {
      if (this.totalDrs != 0) {
        let num = this.totalDrs / 100;
        num = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return num;
      }
      return "0.00";
    },
    totalCrsFrmt() {
      if (this.totalCrs != 0) {
        let num = this.totalCrs / 100;
        num = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return num;
      }
      return "0.00";
    },
    totalDrs() {
      // return this.$store.getters.getNewJournal.totalDrs.toLocaleString();
      let totDrs = 0;
      this.legs.forEach(function (arrayItem) {
        let dr = 0;
        // console.log('sign, amt', arrayItem.legDrCr, arrayItem.legAmount);
        if (arrayItem.legDrCr == "D") dr = arrayItem.legAmount;
        dr = dr.toString();
        // let dr = arrayItem.drAmt;
        // console.log('dr', dr);
        if (dr != 0) {
          dr = dr.replace(/[^0-9]/g, "");
        }
        dr = parseInt(dr);
        // totDrs = totDrs + drs;
        // totDrs = totDrs + parseInt(arrayItem.drAmt);
        // console.log('arrayItem', arrayItem);
        if (dr != 0) {
          // totDrs = totDrs + this.stripChars(arrayItem.drAmt);
          // totDrs = totDrs + arrayItem.drAmt;
          totDrs = totDrs + dr;
        }
        // console.log(arrayItem.id);
      });
      return totDrs;
    },
    totalDrsCount() {
      let totNoDrs = 0;
      this.legs.forEach(function (arrayItem) {
        // console.log('totalDrsCount', arrayItem);
        if (arrayItem.legDrCr == "D") {
          totNoDrs = totNoDrs + 1;
        }
      });
      return totNoDrs;
    },
    totalCrs() {
      // return this.$store.getters.getNewJournal.totalDrs.toLocaleString();
      // let totCrs = 0;
      // this.legs.forEach(function(arrayItem) {
      //   totCrs = totCrs + parseInt(arrayItem.crAmt);
      //   //   // console.log(arrayItem.id);
      // });
      // return totCrs;

      let totCrs = 0;
      this.legs.forEach(function (arrayItem) {
        let cr = 0;
        if (arrayItem.legDrCr == "C") cr = arrayItem.legAmount;
        cr = cr.toString();
        if (cr != 0) {
          cr = cr.replace(/[^0-9]/g, "");
        }
        cr = parseInt(cr);
        // totDrs = totDrs + drs;
        // totDrs = totDrs + parseInt(arrayItem.drAmt);
        // console.log('arrayItem.crAmt', arrayItem.crAmt);
        if (cr != 0) {
          // totDrs = totDrs + this.stripChars(arrayItem.drAmt);
          // totDrs = totDrs + arrayItem.drAmt;
          totCrs = totCrs + cr;
        }
      });
      return totCrs;
    },
    totalCrsCount() {
      let totNoCrs = 0;
      this.legs.forEach(function (arrayItem) {
        // console.log(arrayItem.id);
        if (arrayItem.legDrCr == "C") {
          totNoCrs = totNoCrs + 1;
        }
      });
      return totNoCrs;
    },
    netAmt() {
      return this.totalDrs - this.totalCrs;
    },

    balDr() {
      if (this.netAmt < 0) {
        return this.netAmt * -1;
      }
      return 0;
    },
    balCr() {
      if (this.netAmt > 0) {
        return this.netAmt;
      }
      return 0;
    },
    balDrFrmt() {
      if (this.balDr != 0) {
        let num = this.balDr / 100;
        num = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return num;
      }
      return "0.00";
    },

    balCrFrmt() {
      if (this.balCr != 0) {
        let num = this.balCr / 100;
        num = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return num;
      }
      return "0.00";
    },
    newJnlId() {
      return this.$store.getters.getNewJnlId;
    },
  },
  methods: {
    tryClose() {
      this.$emit("close");
    },
    testA() {
      console.log("TestA");
      // console.log("journal - types", this.types);
      console.log("workjournal", this.$store.getters.getWorkJournal);
      console.log("legs", this.legs);
    },
    testB() {
      console.log("TestB");
      // console.log("formIsValid", this.legsAreValid, this.jnlBeing, this.jnlIsBalanced);

      // console.log("formIsValid", this.formIsValid);
      // console.log("legsAreValid", this.legsAreValid);
      // console.log("jnlBeing.isValid", this.jnlBeing.isValid);
      // console.log("jnlIsBalanced", this.jnlIsBalanced);
      console.log("selAcForJrnl", this.selAcForJrnl);
    },
    showMaintainAcs() {
      this.showMaintAcs = !this.showMaintAcs;
    },
    frmtJrnlNo(no) {
      return this.fillZero(no, 3);
    },
    legAmt(fld, sign, amt) {
      console.log("legAmt computed", fld, sign, amt);
      if (fld == "drAmt" && sign == "D") {
        // amt = amt.replace(/[^0-9]/g, '');
        return amt;
        // return this.convAmt(amt);
      }
      if (fld == "crAmt" && sign == "C") {
        // amt = amt.replace(/[^0-9]/g, '');
        return amt;
        // return this.convAmt(amt);
      }
      if (sign == "") return amt;

      return 0;
    },
    setToday() {
      // console.log(new Date().toDateString())
    },
    show1() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    todaysDate() {
      // console.log(new Date().toDateString());
      let d = new Date();
      let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
      let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
      // console.log(`${ye}-${mo}-${da}`);
      return `${ye}-${mo}-${da}`;
    },
    logLegs() {
      console.log(this.legs);
    },
    addLeg: function () {
      let lastInd = this.legs.length - 1;
      // console.log('this index', lastInd);
      // let id =
      //   Math.max.apply(
      //     Math,
      //     this.legs.map(function(o) {
      //       return o.id;
      //     })
      //   ) + 1;
      let id = lastInd + 2;
      this.legs.push({
        legNo: id,
        accId: null,
        legNote: "",
        legDrCr: "",
        legAmount: 0,
        legAmt: 0,
      });
      this.legsAreValid = false; // The new leg renders the legs invalid.
    },
    removeLeg: function (index) {
      this.legs.splice(index, 1);
      // After a leg has been removed, need to refresh leg ids:
      for (let i = 0; i < this.legs.length; i++) {
        this.legs[i].id = i + 1;
        // console.log('Legs', this.legs[i]);
      }
      this.validateLegs();
    },
    updateLeg: function (payload) {
      console.log("updateLeg payload", payload);
      // console.log('being', this.jnlBeing.val);
      // this.maintAcsFly = null;
      if (payload.value == "<---Maintain Accounts--->") {
        this.maintAcsFly = payload.index; // the leg index
        this.showMaintainAcs();
      }
      if (payload.prop == "drAmt") {
        this.legs[payload.index]["legAmount"] = payload.value;
        this.legs[payload.index]["legDrCr"] = "D";
      } else if (payload.prop == "crAmt") {
        this.legs[payload.index]["legAmount"] = payload.value;
        this.legs[payload.index]["legDrCr"] = "C";
      } else if (payload.prop == "accId") {
        // console.log("accId payload", payload);
        let acId = this.selAcForJrnl || parseInt(payload.value);
        this.legs[this.maintAcsFly || payload.index]["accId"] = acId;
        // console.log("acs", this.acs);
        for (let a = 0; a < this.acs.length; a++) {
          if (this.acs[a].accId == acId) {
            this.legs[this.maintAcsFly || payload.index]["typeId"] = this.acs[a].typeId;
            this.legs[this.maintAcsFly || payload.index]["catId"] = this.acs[a].catId;
          }
        }
      } else {
        this.legs[payload.index][payload.prop] = payload.value;
      }

      if (payload.prop == "accId") {
        console.log("update type and cat");
      }

      this.validateLegs();
    },

    // maintAcsFly(legIndex) {
    //   this.$store.commit("maintAcsFrmJrnl", true);
    //   this.$store.commit("setMaintAcLegIdx", legIndex);
    //   const tempFormVals = {
    //     jnlId: this.jnlNumber,
    //     jnlBeing: this.jnlBeing.val,
    //     jnlLongDesc: this.jnlLongDesc.val || "",
    //     jnlDate: this.jnlDate.val,
    //   };

    //   console.log("Maintain Accounts has been selected.");
    //   console.log("maintAcsFrmJrnl before commit", this.$store.getters.getMaintAcsFrmJrnl);
    //   console.log("All journals", this.$store.getters.getJournals);
    //   console.log("Work journal", this.$store.getters.getWorkJournal);
    //   console.log("the tempFormVals obj", tempFormVals);

    //   this.$store.commit("loadWorkJournalTemp", tempFormVals);
    //   console.log("Work journal after load", this.$store.getters.getWorkJournal);

    //   this.$router.replace({ name: "AcsMaint" });
    // },
    validateLegs() {
      // this.postValidEdit = false;
      for (let i = 0; i < this.legs.length; i++) {
        console.log("all legs", this.legs[i]);
        if (
          this.legs[i].accId == null ||
          this.legs[i].legAmount == 0

          // (this.legs[i].drAmt == 0 && this.legs[i].crAmt == 0)
        ) {
          this.legsAreValid = false;
        } else {
          this.legsAreValid = true;
        }
      }
    },
    toggle() {
      this.$nextTick(() => {
        console.log("toggle newJnlId", this.newJnlId);
        const id = "jnl_" + this.newJnlId;
        let el = document.getElementById(id);
        console.log("el", el);
        el.scrollIntoView();
      });
    },
    toggle_1() {
      console.log("toggle newJnlId", this.newJnlId);
      const id = "jnl_" + this.newJnlId;
      let el = document.getElementById(id);
      console.log("el", el);
      el.scrollIntoView();
    },
    convDate(dte) {
      let year = dte.substring(2, 4);
      let mth = dte.substring(5, 7);
      let day = dte.substring(8);
      return day + "-" + mth + "-" + year;
    },
    handleError() {
      this.error = null;
    },

    submitForm() {
      // Update the jnl no and retrieve it
      // this.tryClose();

      // this.$store.commit('updateJnlCount');

      // const jnlNo = this.$store.getters.getJournalCount;
      const ledgerId = this.ledgerId;
      const jnlId = null;
      const jnlNo = this.jnlNumber;
      const jnlExcl = 0;
      const jnlDate = this.jnlDate.val;
      const jnlBeing = this.stripText(this.jnlBeing.val);
      const jnlNote = this.stripText(this.jnlLongDesc.val);
      const totalDrAmt = this.totalDrs;
      const totalDrCount = this.totalDrsCount;
      const totalCrAmt = this.totalCrs;
      const totalCrCount = this.totalCrsCount;
      let formData = {
        jnlId: jnlId,
        jnlNo: jnlNo,
        jnlExcl: jnlExcl,
        ledgerId: ledgerId,
        jnlBeing: jnlBeing,
        jnlDrAmts: totalDrAmt,
        jnlDrCount: totalDrCount,
        jnlCrAmts: totalCrAmt,
        jnlCrCount: totalCrCount,
        jnlLongDesc: jnlNote,
        jnlDate: jnlDate,
        legs: [],
      };

      // let totNoDrLegs = 0;
      // let totNoCrLegs = 0;
      // var totDrAmt = 0;
      // let totCrAmt = 0;
      // console.log("data legs:", this.legs);
      // let self = this;
      // this.legs.forEach(function(arrayItem) {
      // currLedgers.forEach(ledger => {
      // totNoDrLegs += 1;
      // this.legs.forEach(arrayItem => {
      for (let arrayItem of this.legs) {
        // console.log("arrayItem:", arrayItem);

        let sign = "";
        let amt = 0;
        // let amt = null;
        // let numDr = arrayItem.drAmt;
        // let numDr;
        let numDr = arrayItem.legDrCr == "D" ? arrayItem.legAmount : 0;
        // let numCr = arrayItem.crAmt;
        let numCr = arrayItem.legDrCr == "C" ? arrayItem.legAmount : 0;
        // console.log('arrayItem', arrayItem);
        // console.log('numDr, numCr', numDr, numCr);
        if (numDr != 0) {
          sign = "D";
          amt = numDr.replace(/[^0-9]/g, "");
          // num = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

          // amt = numDr.replace(/[^0-9]/g, '');
        }
        if (numCr != 0) {
          sign = "C";
          amt = numCr.replace(/[^0-9]/g, "");

          // numCr = numCr.toString();
          // amt = numCr.replace(/[^0-9]/g, '');
        }
        // console.log('sign amt ', sign, amt);
        amt = parseInt(amt);
        // let amt = arrayItem.legAmt;
        // if (arrayItem.drAmt > 0) {
        // if (numDr > 0) {
        //   sign = 'D';
        //   console.log('arrayItem.drAmt', arrayItem.drAmt, numDr);
        //   // amt = arrayItem.drAmt * 1;
        //   amt = numDr * 1;
        //   // totDrAmt += amt;
        //   // totNoDrLegs += 1;
        //   // self.$store.commit('updateAcBal', {
        //   //   acc: parseInt(arrayItem.acc),
        //   //   amt: amt
        //   // });
        //   // self.$store.commit('updateAcBal', {
        //   //   acc: parseInt(arrayItem.acc),
        //   //   amt: amt
        //   // });
        //   // self.$store.commit('updateTotalDrs', amt);
        //   // self.$store.commit('updateAcBal', 5000);
        // } else {
        //   sign = 'C';
        //   // amt = arrayItem.crAmt * 1;
        //   amt = numCr * 1;
        //   // totCrAmt += amt;
        //   // totNoCrLegs += 1;
        //   // totNoCrLegs += 1;
        //   // totCrAmt += amt;
        //   // self.$store.commit('updateAcBal', {
        //   //   acc: parseInt(arrayItem.acc),
        //   //   amt: amt * -1
        //   // });
        //   // self.$store.commit('updateTotalCrs', amt);
        // }
        // // console.log('arr item cat', arrayItem.acc, arrayItem.cat);
        // amt = this.stripChars(amt);
        formData.legs.push({
          jnlNo: jnlNo,
          jnlId: null,
          legId: null,
          jnlDate: this.convDate(jnlDate),
          jnlFullDate: jnlDate,
          jnlBeing: jnlBeing,
          jnlExcl: 0,

          // legNo: arrayItem.id,
          legNo: arrayItem.legNo,
          accId: parseInt(arrayItem.accId),
          // accId: parseInt(arrayItem.accId),
          typeId: arrayItem.typeId,
          catId: arrayItem.catId,
          // accId: arrayItem.accId,
          legDrCr: sign,
          // legAmount: parseInt(amt.replace(/[,.]/g, '')),
          // legAmount: parseInt(amt),
          legAmount: amt,
          legNote: this.stripText(arrayItem.legNote) || "",
        });
        // console.log('amt', amt, parseInt(amt));
      }
      // console.log('data legs', this.legs);
      // console.log("formData", formData);
      // console.log('formData legs', formData.legs);
      // this.$store.commit('newJournal', formData);
      // console.log('journals after new:', this.$store.getters.getJournals);

      // Click on the newly created journal.
      // TODO: Is there a better way to do this? Needs to be tested on non-Chrome browsers.

      // let jnlCnt = this.$store.getters.getJournalCount;
      // this.$nextTick(function() {
      //   let elem = document.getElementById('jnl_'.concat(jnlCnt));
      //   let clickEvent = new MouseEvent('click', {
      //     view: window,
      //     bubbles: true,
      //     cancelable: false
      //   });
      //   elem.dispatchEvent(clickEvent);
      // console.log(elem);
      // });
      // this.$store.dispatch('postNewJournal', formData);
      // this.showModal = false;

      if (this.action === "NEW") {
        console.log("dispatch postNewJournal", formData);
        // this.$store.dispatch("postNewJournal", formData);
        this.isPosting = true;
        // try {
        this.$store
          .dispatch("postNewJournal", formData)
          .then(() => {
            this.isPosting = false;
            this.tryClose();
          })
          .then(() => {
            this.toggle_1();
            // this.$store.commit("clearLegHl");
          })
          // } catch (err) {
          .catch((error) => {
            console.log("error caught" + error);
            this.isPosting = false;
            this.error = error;
            console.log("data: error: ", this.error);
          });
        // }

        // console.log("the new jrnl Id", this.newJnlId);

        // } catch (error) {
        //   this.error = error.message || "Something went wrong";
        // }
        formData = {};
        // this.$store.commit("clearWorkJournal");
        // this.tryClose();
      }

      if (this.action === "EDIT") {
        console.log("Form data", formData);
        // this.$store.dispatch("postEditJournal", formData);
        this.isPosting = true;

        this.$store
          .dispatch("postEditJournal", formData)
          .then(() => {
            // console.log("the new jrnl Id", this.newJnlId);
            this.isPosting = false;
            this.tryClose();
          })
          .catch((error) => {
            console.log("edit jrnl error caught in component");
            this.isPosting = false;
            this.error = error;
          });
        // .then(() => {
        //   this.toggle_1();
        // });
        // .then(() => {
        // console.log("the new jrnl Id", this.newJnlId);
        // this.toggle_1();
        // });
        // this.$store.dispatch("deleteJournal").then(() => {
        //   console.log("jrnl Deleted");
        // });
        // this.$store.dispatch("postNewJournal", formData).then(() => {
        //   console.log("the new jrnl Id", this.newJnlId);
        //   this.toggle_1();
        // });

        formData = {};

        // this.$store.dispatch("postEditJournal", formData);
        // this.$store.commit("clearWorkJournal");
      }
      // this.tryClose();

      // this.$router.replace({ name: "Ledger" });
      // this.toggle();
    },
    // newJournal() {},
    stripChars(str) {
      if (str != 0) {
        str = str.replace(/[^0-9]/g, "");
      }
      return str;
    },
  },
  // beforeUnmount() {
  //   this.$el.parentNode.removeChild(this.$el);
  // },
  created() {
    if (this.action == "EDIT") {
      this.legsAreValid = true;
      this.jnlBeing.isValid = true;
      // this.jnlIsBalanced = true;
    }
  },
};
</script>

<style scoped>
/* * {
  border: 1px solid blue;
} */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 10;
}

dialog {
  position: fixed;
  top: 10%;
  left: 10%;
  margin: 0 auto;
  width: 80%;
  height: 80%;
  z-index: 90;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  /* overflow: auto; */
  background-color: white;
  font-family: "Roboto Serif", serif;
}

header {
  background-color: #3a0061;
  color: white;
  width: 100%;
  padding: 1rem;
  position: sticky;
  top: 0;
}

.container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 60% 40%;
  grid-template-columns: 60% 40%;
  grid-template-areas:
    "legs jrnl"
    "legsTot jrnl";
}

.legs {
  grid-area: legs;
  /* background-color: green; */
  /* grid-row-start: 1; */
  /* height: 100%; */
}

.legsTot {
  grid-area: legsTot;
  /* background-color: red; */
}
.jrnl {
  grid-area: jrnl;
  /* background-color: blue; */
  /* grid-row-start: 1; */

  /* height: 100%; */
}
.legs {
  border: 2px solid gray;
}
td,
th {
  border: 1px solid #999;
  padding: 0.5ren;
  text-align: left;
  font-size: 1.7rem;
}

.dtAmt {
  text-align: right;
}

.amt {
  text-align: right;
}

.colAction {
  width: 8rem;
}
.balAmt {
  color: red;
}

.addLeg {
  text-align: right;
}
table {
  border-collapse: collapse;
  /* table-layout: fixed; */
  width: 65rem;
  margin-left: 2rem;
}
.legTots {
  margin-left: 20rem;
}

/* tr {
  font-size: 1.5rem;
} */

tbody tr:nth-child(odd) {
  background: #eee;
}

/* textarea {
  width: 600px;
  height: 150px;
  border: 3px solid #cccccc;
  padding: 5px;
  font-family: Tahoma, sans-serif;
} */

.btnPostYes {
  background: lightgreen;
  font-weight: bold;
}

.btnPostNo {
  background: lightpink;
  font-weight: bold;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.jrnlFlds {
  margin-left: 2rem;
  margin-top: 2rem;
  font-size: 2rem;
}

.lwrBtns {
  position: absolute;
  bottom: 0;
}
.mnuSvCncl {
  font-size: 2rem;
}
.form-control {
  margin: 0.2rem 0;
  display: flex;
  align-items: flex-start;
}
label {
  font-weight: bold;
  font-size: 1.7rem;
  display: inline-block;
  text-align: right;
  margin-bottom: 1rem;
  width: 14rem;
}
input,
textarea {
  display: inline;
  font-size: 1.7rem;
  width: 40rem;
  /* width: 100%; */
  margin-left: 1rem;
  border: 1px solid #ccc;
  /* font: inherit; */
}

input:focus,
textarea:focus {
  background-color: #f0e6fd;
  outline: none;
  border-color: #3d008d;
}
.fldErr {
  margin-left: 2rem;
  font-size: 1.7rem;
  font-weight: bold;
  color: red;
}
</style>
