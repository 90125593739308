<template>
  <section>
    <h1>Verification Failed</h1>
    <p>Please contact support at support@TAccountZone.com</p>
  </section>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
section {
  margin-left: 5rem;
  margin-top: 5rem;
  font-size: 1.6rem;
}

button {
  margin-bottom: 3rem;
  font-size: 2rem;
}
</style>
