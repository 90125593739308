<template>
  <base-error :show="!!error" title="An error occurred!" @close="handleError">
    <p>{{ error }}</p>
  </base-error>
  <!-- <button @click="btnTest">Test</button> -->
  <table v-if="types && types.length">
    <thead>
      <tr>
        <!-- <div v-if="isProcessingBal" class="spinner">
          <base-spinner></base-spinner>
        </div> -->
        <th v-if="isProcessingBal" class="spinner" colspan="3">
          <base-spinner></base-spinner>
        </th>
        <th v-else-if="!isProcessingBal" colspan="3" class="stmtTitle"><input type="text" id="bsTitle" name="bsTitle" maxlength="250" :value="bsTitle" @blur="updTitle($event, 'bal')" /></th>
        <th></th>
        <th></th>
      </tr>
      <tr>
        <th></th>
        <th colspan="2" class="curr">{{ baseCurr }}</th>
      </tr>
      <tr>
        <th></th>
        <th colspan="2"><div class="dblline"></div></th>
      </tr>
    </thead>
    <tbody>
      <template v-for="typ in types" :key="typ.typeId">
        <tr class="typeRow" v-if="showTyp(typ.typeBal, typ.typeExclBal, typ.typeNoLegs, typ.typeExclNoLegs)">
          <td class="typ">{{ typ.typeName }}</td>
          <td></td>
          <td></td>
        </tr>
        <template v-for="cat in typ.categories" :key="cat.catId">
          <tr v-if="showCatAc(cat.catBal, cat.catExclBal, cat.catNoDrLegs, cat.catExclNoDrLegs, cat.catNoCrLegs, cat.catExclNoCrLegs)">
            <td colspan="3" class="cat">{{ cat.catName }}</td>
          </tr>
          <template v-for="(ac, acIdx) in cat.accounts" :key="ac.accId">
            <tr
              v-if="showCatAc(ac.accBal, ac.accExclBal, ac.accNoDrLegs, ac.accExclNoDrLegs, ac.accNoCrLegs, ac.accExclNoCrLegs) || displayEmpAcs"
              :id="'ac_' + ac.accId"
              @click="hlFromTBload(ac.accId)"
              :class="{
                hlighted: hlFromTB(ac.accId),
                active: hlFromJrnls(ac.accId),
                hlDrLeg: hlDrLeg(ac.accId),
                hlCrLeg: hlCrLeg(ac.accId),
              }"
            >
              <td class="ac">{{ ac.accNumber }} - {{ ac.accName }}</td>
              <td class="amt">{{ computeAmt(ac.accBal - ac.accExclBal, typ.typeNrmlSgn) }}</td>

              <td></td>
            </tr>
            <template v-if="acIdx == cat.accounts.length - 1 && showCatAc(cat.catBal, cat.catExclBal, cat.catNoDrLegs, cat.catExclNoDrLegs, cat.catNoCrLegs, cat.catExclNoCrLegs)">
              <tr>
                <td></td>
                <td><div class="uline"></div></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td class="amt cat">{{ computeAmt(cat.catBal - cat.catExclBal, typ.typeNrmlSgn) }}</td>
                <td></td>
              </tr>
            </template>
          </template>
        </template>
        <tr class="typeRow" v-if="showTyp(typ.typeBal, typ.typeExclBal, typ.typeNoLegs, typ.typeExclNoLegs)">
          <td></td>
          <td></td>
          <td class="amt typ">
            {{ computeAmt(typ.typeBal - typ.typeExclBal, typ.typeNrmlSgn) }}
          </td>
        </tr>
        <template v-if="typ.typeCode == 'NCA'">
          <tr>
            <td colspan="2"></td>
            <td><div class="uline"></div></td>
          </tr>
        </template>
        <template v-if="typ.typeCode == 'CLB'">
          <tr>
            <td colspan="2"></td>
            <td><div class="uline"></div></td>
          </tr>
          <tr>
            <td colspan="3"></td>
          </tr>
          <tr>
            <td colspan="2" class="calc">NET CURRENT <span v-if="netCurrAssets >= 0">ASSETS</span><span v-if="netCurrAssets < 0">LIABILITIES</span></td>
            <td class="amt">{{ computeAmt(netCurrAssets, "netCurrAssets") }}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td><div class="uline"></div></td>
          </tr>
          <tr>
            <td colspan="3"></td>
          </tr>
          <tr>
            <td colspan="3"></td>
          </tr>
          <tr>
            <td colspan="2" class="calc">TOTAL ASSETS LESS CURRENT LIABILITIES</td>
            <td class="amt">{{ computeAmt(totAssetsLessCurrLiabs) }}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td><div class="uline"></div></td>
          </tr>
          <tr>
            <td colspan="3"></td>
          </tr>
        </template>
        <template v-if="typ.typeCode == 'NCL'">
          <tr>
            <td colspan="3"></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td><div class="uline"></div></td>
          </tr>
          <tr>
            <td colspan="2" class="calc">NET <span v-if="totAssetsLiabs >= 0">ASSETS</span><span v-if="totAssetsLiabs < 0">LIABILITIES</span></td>
            <td class="amt">{{ computeAmt(totAssetsLiabs) }}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td><div class="uline"></div></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td><div class="uline"></div></td>
          </tr>
          <tr>
            <td colspan="3"></td>
          </tr>
        </template>
        <template v-if="typ.typeCode == 'RES'">
          <tr>
            <td class="ac"><span v-if="netProfit <= 0">Profit </span><span v-if="netProfit > 0">Loss </span>This Period</td>
            <td></td>
            <td class="amt">{{ computeAmt(netProfit, "C") }}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td><div class="uline"></div></td>
          </tr>
          <tr>
            <td colspan="3"></td>
          </tr>

          <tr>
            <td colspan="2" class="calc">SHAREHOLDERS <span v-if="shareHoldSurpl <= 0">SURPLUS</span><span v-if="shareHoldSurpl > 0">DEFICIT</span></td>
            <td class="amt">{{ computeAmt(shareHoldSurpl, "C") }}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td><div class="uline"></div></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td><div class="uline"></div></td>
          </tr>
          <tr>
            <td colspan="3"></td>
          </tr>

          <tr class="blnkRw">
            <td colspan="3"></td>
          </tr>

          <tr>
            <!-- <div v-if="isProcessingPl" class="spinner">
              <base-spinner></base-spinner>
            </div> -->
            <th v-if="isProcessingPl" class="spinner" colspan="3">
              <base-spinner></base-spinner>
            </th>
            <th v-else-if="!isProcessingPl" colspan="3" class="stmtTitle"><input type="text" id="plTitle" name="plTitle" maxlength="250" :value="plTitle" @blur="updTitle($event, 'pl')" /></th>
            <th></th>
            <th></th>
          </tr>

          <tr>
            <th></th>
            <th colspan="2" class="curr">{{ baseCurr }}</th>
          </tr>
          <tr>
            <th></th>
            <th colspan="2"><div class="dblline"></div></th>
          </tr>
        </template>

        <template v-if="typ.typeCode == 'CSL'">
          <tr>
            <td colspan="3"></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td><div class="uline"></div></td>
          </tr>
          <tr>
            <td colspan="2" class="calc">GROSS <span v-if="grossProfit <= 0">PROFIT</span><span v-if="grossProfit > 0">LOSS</span></td>
            <td class="amt">{{ computeAmt(grossProfit, "C") }}</td>
          </tr>
          <tr>
            <td colspan="3"></td>
          </tr>
        </template>
        <template v-if="typ.typeCode == 'OIN'">
          <tr>
            <td></td>
            <td></td>
            <td><div class="uline"></div></td>
          </tr>
          <tr>
            <td colspan="2" class="calc">NET INCOME</td>
            <td class="amt">{{ computeAmt(netIncome, "C") }}</td>
          </tr>
          <tr>
            <td colspan="3"></td>
          </tr>
        </template>
        <template v-if="typ.typeCode == 'EXP'">
          <tr>
            <td></td>
            <td></td>
            <td><div class="uline"></div></td>
          </tr>
          <tr>
            <td colspan="2" class="calc">NET <span v-if="netProfit <= 0">PROFIT</span><span v-if="netProfit > 0">LOSS</span></td>
            <td class="amt">{{ computeAmt(netProfit, "C") }}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td><div class="uline"></div></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td><div class="uline"></div></td>
          </tr>
          <tr>
            <td colspan="3"></td>
          </tr>
        </template>
      </template>
    </tbody>
  </table>
  <div v-else>Loading...</div>
</template>

<script>
import mixin from "../../mixins/mixin.js";
export default {
  mixins: [mixin],
  data() {
    return {
      // displayEmptyAcs: false,
      //   displayCatName: true,
      isHlighted: false,
      result: [],
      isProcessingBal: false,
      isProcessingPl: false,
      error: null,
    };
  },
  computed: {
    types() {
      //   return this.$store.getters.getTrialBal;
      return this.$store.getters.getTypes;
    },
    ledSummaries() {
      return this.$store.getters.getSummaries;
    },
    baseCurr() {
      return this.$store.getters.getCurrLedgerBaseCurr;
    },

    ledgerId() {
      return this.$store.getters.getCurrLedgerId;
    },
    bsTitle() {
      return this.$store.getters.getLedBsTitle;
    },
    plTitle() {
      return this.$store.getters.getLedPlTitle;
    },
    netCurrAssets() {
      return this.$store.getters.getNetCA;
    },
    totAssetsLessCurrLiabs() {
      return this.$store.getters.getTotAssetsLessCurrLiabs;
    },
    totAssetsLiabs() {
      return this.$store.getters.getTotAL;
    },
    netProfit() {
      return this.$store.getters.getNetProfit;
    },
    shareHoldSurpl() {
      return this.$store.getters.getShareHoldSurpl;
    },
    grossProfit() {
      return this.$store.getters.getGrossProfit;
    },
    netIncome() {
      return this.$store.getters.getNetIncome;
    },
    legHl() {
      return this.$store.getters.getLegHl;
    },
    displayEmpAcs() {
      return this.$store.getters.getDisplCtrlAcs_empAcs;
    },
  },
  methods: {
    // computeAmt(amt, mode) {
    //   // return amt.toLocaleString();
    //   if (mode == "C") {
    //     if (amt <= 0) {
    //       let amt_1 = amt * -1;
    //       return this.convAmt(amt_1);
    //     }
    //     if (amt > 0) {
    //       let amt_1 = "(" + this.convAmt(amt) + ")";
    //       return amt_1;
    //     }
    //   }
    //   if (mode == "D") {
    //     if (amt < 0) {
    //       let amt_1 = amt * -1;
    //       amt_1 = "(" + this.convAmt(amt_1) + ")";
    //       return amt_1;
    //     }
    //     if (amt >= 0) {
    //       return this.convAmt(amt);
    //     }
    //   }

    //   if (amt < 0) {
    //     let amt_1 = amt * -1;
    //     amt_1 = "(" + this.convAmt(amt_1) + ")";
    //     return amt_1;
    //   }
    //   return this.convAmt(amt);
    // },
    btnTest() {
      console.log("typSubTots", this.typSubTots);
    },
    hlDrLeg(accId) {
      if (this.legHl.sign == "D" && this.legHl.accId == accId) {
        // document.getElementById("tb-list").scrollTop -= 10;
        // document.getElementById("tb-cntr").scrollTop -= 10;
        document.getElementsByClassName("tb-list acs-list").scrollTop -= 10;
        const id = "ac_" + accId;
        let el = document.getElementById(id);
        console.log("el", el);
        el.scrollIntoView({ behavior: "smooth", block: "center" });
        return true;
      }
    },
    hlCrLeg(accId) {
      if (this.legHl.sign == "C" && this.legHl.accId == accId) {
        document.getElementById("tb-list").scrollTop -= 10;

        const id = "ac_" + accId;
        let el = document.getElementById(id);
        console.log("el", el);
        el.scrollIntoView({ behavior: "smooth", block: "nearest" });
        return true;
      }
    },
    hlFromJrnls(accId) {
      const hlAcs = this.$store.getters.getJnlAcIds;
      return hlAcs.includes(accId);
    },
    hlFromTB(accId) {
      const hlAcTB = this.$store.getters.getHlTBac;
      if (hlAcTB == accId) {
        return true;
      } else {
        return false;
      }
    },
    hlFromTBload(accId) {
      const hlAcTB = this.$store.getters.getHlTBac;
      if (hlAcTB == accId) {
        this.$store.commit("updateHlAcTB", null);
      } else {
        this.$store.commit("removeJnlNo");
        this.$store.commit("removeJnlAcIds");
        this.$store.commit("updateHlAcTB", accId);
      }
    },
    showTyp(bal, exclBal, noLegs, noExclLegs) {
      console.log("showTyp", bal, exclBal, noLegs, noExclLegs);
      if (bal != 0 || exclBal != 0 || noLegs != 0 || noExclLegs != 0) {
        return true;
      }
      // if (bal - exclBal != 0) {
      //   return true;
      // }
      return false;
    },
    showCatAc(bal, exclBal, noDrLegs, exclNoDrLegs, noCrLegs, exclNoCrLegs) {
      console.log(bal, exclBal, noDrLegs, exclNoDrLegs, noCrLegs, exclNoCrLegs);
      if (bal != 0 || exclBal != 0 || noDrLegs != 0 || exclNoDrLegs != 0 || noCrLegs != 0 || exclNoCrLegs != 0) {
        return true;
      }
      return false;
    },
    updTitle(e, stmt) {
      if (e.target.value.length == 0) {
        console.log("description must not be blank");
        if (stmt == "bal") {
          e.target.value = this.bsTitle;
        }
        if (stmt == "pl") {
          e.target.value = this.plTitle;
        }
      }
      if (e.target.value.length > 0) {
        if (stmt == "bal") {
          this.updLed("bs_title", e.target.value.trim());
        }
        if (stmt == "pl") {
          this.updLed("pl_title", e.target.value.trim());
        }
      }
    },
    updLed(fld, val) {
      if (fld == "bs_title") {
        this.isProcessingBal = true;
      }
      if (fld == "pl_title") {
        this.isProcessingPl = true;
      }
      const frmData = {
        ledId: this.ledgerId,
        fld: fld,
        val: val,
      };
      console.log("title update data", frmData);
      this.$store
        .dispatch("postShrtEditLed", frmData)
        .then(() => {
          if (fld == "bs_title") {
            this.isProcessingBal = false;
          }
          if (fld == "pl_title") {
            this.isProcessingPl = false;
          }
        })

        .catch((error) => {
          console.log("error caught" + error);
          this.isProcessingBal = false;
          this.error = error;
          console.log("data: error: ", this.error);
        });
    },
    handleError() {
      this.error = null;
    },
  },
};
</script>

<style scoped>
.hlighted {
  background-color: rgb(201, 238, 201);
}
.active {
  background-color: lightyellow;
}
.stmt {
  color: green;
  font-weight: 900;
  font-size: 1.5rem;
}

.typeRow {
  height: 2rem;
  vertical-align: top;
  border-collapse: collapse;
}
.typ {
  color: purple;
  font-weight: 900;
  padding-left: 5px;
}
.blnkRw {
  height: 4rem;
  background-color: rgb(235, 241, 247);
  padding: 0;
}
.calc {
  color: blueviolet;
  font-weight: 900;
  font-size: 1rem;
}
.cat {
  padding-left: 15px;
  color: green;
  font-weight: 900;
}

.ac {
  padding-left: 30px;
  color: black;
  font-weight: 700;
}
table {
  border-collapse: collapse;
  background-color: white;
  margin-top: 2rem;
  margin-left: 2rem;
  /* table-layout: fixed; */
  width: 60rem;
}
.uline {
  float: right;
  border-top: 1px solid black;
  height: 1px;
  width: 10rem;
}
.dblline {
  border-top: 1px solid black;
  height: 1px;
}
.curr {
  text-align: center;
}
td,
th {
  /* border: 1px solid #999; */
  padding: 0.5rem;
  text-align: left;
  padding: 4px;
  font-family: "Courier New", Courier, monospace;
  font-size: 12px;
}
.amt {
  text-align: right;
}
.stmtTitle > input {
  font-weight: 600;
  font-size: 1.8rem;
  border-collapse: collapse;
  background-color: white;
  margin-top: 1rem;
  margin-left: 2rem;
  /* table-layout: fixed; */
  width: 62rem;

  border: none;
}
</style>
