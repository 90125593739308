<template>
  <div class="acs-list" id="acs-list">
    <div v-if="isUlDataLoading" class="spinner">
      <base-spinner></base-spinner>
    </div>
    <div v-else-if="taccountList && taccountList.length">
      <div v-for="typ in taccountList" :key="typ.typeId" :class="{ conts: !displayCatName }">
        <div v-for="cat in typ.categories" :key="cat.catId" class="Tcat" :class="{ conts: !displayCatName }">
          <template v-if="(displayEmpAcs == false && displayCatName && (cat.catNoDrLegs != 0 || cat.catNoCrLegs != 0)) || (displayEmpAcs && displayCatName)">
            <h3>
              {{ cat.catName }}
            </h3>
          </template>
          <!-- <template> -->
          <!-- <div> -->
          <!-- <template v-if="hlJnlOnly == false"> -->
          <ac-card
            v-for="ac in cat.accounts"
            :key="ac.accId"
            class="AcCntr"
            :class="{
              hlighted: hlFromTBac(ac.accId),
              inline: !displayCatName,
              nodisp: hlAcsOnly(ac.accId),
            }"
            :dispEmpAcs="displayEmpAcs"
            :dispCatName="displayCatName"
            :accId="ac.accId"
            :accNumber="ac.accNumber"
            :accName="ac.accName"
            :acNoDrLegs="ac.accNoDrLegs"
            :acNoCrLegs="ac.accNoCrLegs"
            :debits="ac.debits"
            :credits="ac.credits"
          >
          </ac-card>
          <!-- </template> -->
          <!-- </div> -->
          <!-- </template> -->
        </div>
      </div>
    </div>
    <!-- <div v-else>Loading...</div> -->
  </div>
</template>

<script>
import AcCard from "./AcCard.vue";
export default {
  components: {
    AcCard,
  },
  data() {
    return {
      //   displCntrlAcs: {
      //     displayEmpAcs: false,
      //     displayCatName: true,
      //     hlJnlOnly: false,
      //   },
      result: [],
      relCats: [],
    };
  },
  computed: {
    isUlDataLoading() {
      return this.$store.getters.getUlDataLoading;
    },
    taccountList() {
      // console.log('taccountlist_old', this.$store.getters.getTaccounts);
      //   return this.$store.getters.getTaccounts;

      // console.log('taccountlist', this.$store.getters.getUlDataTypes);
      //   return this.$store.getters.getUlDataTypes;
      return this.$store.getters.getTypes;
    },
    hasTaccounts() {
      return this.$store.getters.hasTaccounts;
    },
    hLacs() {
      return this.$store.getters.getJnlAcIds;
    },
    tbac() {
      return this.$store.getters.getHlTBac;
    },
    hlJnlOnly() {
      return this.$store.getters.getDisplCtrlAcs_hlJnlOnly;
    },
    displayEmpAcs() {
      return this.$store.getters.getDisplCtrlAcs_empAcs;
    },
    displayCatName() {
      return this.$store.getters.getDisplCtrlAcs_catName;
    },
  },
  methods: {
    hlAcsOnly(acId) {
      // console.log("hLights", this.hlJnlOnly, acId);

      if (this.hlJnlOnly) {
        // console.log("hljrnlOnly test passed.");

        if (this.hLacs.includes(acId) || this.tbac == acId) {
          return false;
        }

        return true;
      }

      if (!this.hlJnlOnly) {
        return false;
      }
    },
    hlAcsOnly_bck(acId) {
      // console.log("hLights", this.hlJnlOnly, acId);

      if (this.hlJnlOnly) {
        // console.log("hljrnlOnly test passed.");

        if (this.hLacs.includes(acId)) {
          return false;
        }

        return true;
      }

      if (!this.hlJnlOnly) {
        return false;
      }
    },

    hlFromTBac(accId) {
      const hlAcTB = this.$store.getters.getHlTBac;
      if (hlAcTB == accId) {
        return true;
      } else {
        return false;
      }
    },

    testB() {
      console.log("hello");
    },
  },
  // created() {
  // this.getTAcs();
  // }
};
</script>

<style scoped>
.TacsCntr {
  overflow: auto;
  /* vertical-align: column; */
  /* display: block; */
  /* flex: auto; */
  display: inline;
  /* height: calc(100vh - 220px); */
  height: auto;
}

.acs-list {
  background-color: rgb(235, 241, 247);
  /* overflow: scroll; */
  /* height: auto; */
  width: auto;
  /* margin-left: 2rem; */
  margin-top: 8rem;
  padding-top: 2rem;
  padding-bottom: 10rem;
  height: calc(100vh - 22rem);
  overflow: scroll;
  /* overflow-x: hidden; */
}

/* .AcCntr {
  display: inline-block;
} */
.active {
  background-color: lightyellow;
}

.invisible {
  display: none;
}
.Tcat {
  display: flex;
  /* display: contents; */
  /* display: inline; */
  flex-wrap: wrap;
  margin-left: 2rem;
  margin-top: 2rem;
}
.conts {
  display: contents;
}
.inline {
  display: inline-block;
}
.Tcat h3 {
  flex-basis: 100%;
  font-size: 1.7rem;
  font-weight: bold;
  color: green;
}
.nodisp {
  /* background-color: pink; */
  display: none;
}

/* .nodispTb {
  display: none;
} */
</style>
