<template>
  <div class="btnCntr">
    <h2>Accounts</h2>

    <button v-if="devFeat == 'true'" @click="testB">Test</button>
    <!-- </template> -->
    <button id="btn_maint" @click="showMaintainAcs" :disabled="!ledgerId">Maintain</button>
    <br />
    <input :disabled="!ledgerId" type="checkbox" id="empAcs" name="empAcs" v-model="displayEmpAcs" />
    <label for="empAcs">Show Empty Acs</label>
    <input :disabled="!ledgerId" type="checkbox" id="catNames" name="catNames" v-model="displayCatName" selected />
    <label for="catNames">Show Categories</label>
    <input :disabled="!ledgerId" type="checkbox" id="hlJnlOnly" name="hlJnlOnly" v-model="hlJnlOnly" />
    <label for="hlJnlOnly">Show Selected Only</label>
    <input :disabled="!ledgerId" type="checkbox" id="shwExclLegs" name="shwExclLegs" v-model="shwExclLegs" />
    <label for="shwExclLegs">Show Excl. Legs</label>
  </div>
  <maint-acs v-if="showMaintAcs" :show="showMaintAcs" @close="showMaintainAcs"></maint-acs>
</template>

<script>
import MaintAcs from "./MaintAcs.vue";
export default {
  components: { MaintAcs },
  data() {
    return {
      showMaintAcs: false,
      displayEmpAcs: false,
      displayCatName: false,
      hlJnlOnly: false,
      shwExclLegs: false,
      devFeat: process.env.VUE_APP_DEV_FEATURES,
    };
  },
  computed: {
    ledgerId() {
      return this.$store.getters.getCurrLedgerId;
    },
  },
  watch: {
    displayEmpAcs(newVal) {
      this.$store.commit("setDisplCtrlAcs_empAcs", newVal);
    },
    displayCatName(newVal) {
      this.$store.commit("setDisplCtrlAcs_catName", newVal);
    },
    hlJnlOnly(newVal) {
      this.$store.commit("setDisplCtrlAcs_hlJnlOnly", newVal);
    },
    shwExclLegs(newVal) {
      this.$store.commit("setDisplCtrlAcs_shwExclLegs", newVal);
    },
  },
  methods: {
    showMaintainAcs() {
      this.showMaintAcs = !this.showMaintAcs;
    },
    testB() {
      console.log("state", this.$store.getters.getState);
      console.log("uldata", this.$store.getters.getUlData);
      console.log("types", this.$store.getters.getTypes);
      // console.log("frmtShrtDte", this.$store.getters.getCurrLedgerFrmtShrtDts);
      // console.log("Hlighted jnl accounts", this.$store.getters.getJnlAcIds);
      // console.log("env", process.env.VUE_APP_DEV_FEATURES);
      // console.log("env data", this.devFeat);
      console.log("auth data", this.$store.getters.getAuthData);
    },
  },
};
</script>

<style scoped>
* {
  font-family: "Plus Jakarta Sans", sans-serif;
}
div {
  background-color: rgb(203, 225, 240);
  height: 10rem;
  width: inherit;
  font-size: 1.7rem;
  margin-bottom: 1rem;
  position: fixed;
  z-index: 1;
}
button {
  font-size: 1.5rem;
  margin-left: 1rem;
  border-radius: 10px;
}

h2 {
  margin-left: 1rem;
}

input {
  margin-left: 1rem;
}
</style>
